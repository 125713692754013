import React from 'react';
import {setConfiguration} from 'react-grid-system'
import qs from 'querystring'
import './App.scss';
import {Provider} from 'react-redux'
import {ScreenClassProvider} from 'react-grid-system'
import store from './redux/store'
import Body from './components/body'
import {isAZPage, isIframe} from './utilities/functions'
import tealiumView from "./utilities/utag";
import {sessionStorageKey} from "./utilities/constants";


let breakpoints = [576, 768, 1024, 1240]
let containerWidths = [540, 750, 992, 1184]
let fullWidth = false

if (window.location.search) {
    const params = qs.parse(window.location.search.replace('?', ''))
    if (undefined !== params.fullwidth && params.fullwidth.replace('/', '') === '1') {
        fullWidth = true
        containerWidths = ['100%']
    }
}



if (isAZPage()) {
    breakpoints = [576, 768, 1024, 1240]
    containerWidths = [688, 750, 992, 1184]
}

setConfiguration({
    breakpoints,
    containerWidths,
    gutterWidth: 32,
    defaultScreenClass: "sm"
})

function App() {

    // Adding Tealium Tags
   
    if (document !== undefined) {
        const hostEnv = window.location.hostname;
        const scriptElement = document.createElement('script');
        let sourceUrl

        if (isAZPage()) {
            sourceUrl = '//tags.tiqcdn.com/utag/autozone/b2c-responsive-us/dev/utag.js';
            if (hostEnv === "shop-referral.autozonepro.com") { sourceUrl = '//tags.tiqcdn.com/utag/autozone/b2c-responsive-us/prod/utag.js'; }
            if (hostEnv === "st-referral.autozonepro.com") { sourceUrl = '//tags.tiqcdn.com/utag/autozone/b2c-responsive-us/qa/utag.js'; }
        } else {
            sourceUrl = '//tags.tiqcdn.com/utag/autozone/azprous/dev/utag.js';
            if (hostEnv === "autozonepro.com") { sourceUrl = '//tags.tiqcdn.com/utag/autozone/azprous/prod/utag.js'; }
        }

        scriptElement.src = sourceUrl;
        scriptElement.type = 'text/javascript';
        scriptElement.sync = true;
        scriptElement.id = 'TealiumScriptId';
        window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
        window.utag_cfg_ovrd = { noview: true };
        document.head.appendChild(scriptElement);
        
        
        
        scriptElement.onload = function () {
            if (window.location) {
                const section = 'Shop Referral: Home'
                tealiumView(section, section)
            }     
        }
    }

    if (!isAZPage()) {
        // Sending Tealium Events
        window.addEventListener('hashchange', evt => {
            const page = evt.newURL.split('/').slice(-1)[0]
            const path = evt.newURL.split('/').slice(-2)[0]
            const origin = (path && path === 'az') ? 'az' : 'azpro'
            const section = `${origin}: Shop referral`

            if (!sessionStorage[sessionStorageKey.SKIP]) {
                tealiumView(section, section)
            } else {
                sessionStorage.removeItem(sessionStorageKey.SKIP)
            }

            if (isIframe()) {
                try {
                    window.parentIFrame.sendMessage({page, message: 'hashchange'}, '*')
                
                }
                catch (e) {
                    console.error(e)
                }
            }
        })
    }
   

    return (
            <Provider store={store}>
                <ScreenClassProvider>
                    <div className="App">
                        <Body fullWidth={fullWidth}/>
                    </div>
                </ScreenClassProvider>
            </Provider>
    )
}

export default App;

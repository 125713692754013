import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import * as serviceWorker from './serviceWorker'
import {addCookie, readCookie} from "./redux/actions/cookie"


// To test copy jsessionID from azpro
//document.cookie = "JSESSIONID=VXDSnICBdOcY8kmtEGAYid1B31JsFXRKuXm-h-Q_.xl62-b2b2; domain=.autozone.com; path=/";

let jsessionID = readCookie('JSESSIONID')
if (jsessionID) {addCookie('JSESSIONID')}

// Adding google maps SDK
try {

   
    const googlScript = document.createElement('script')
    const sdkUrl = `https://maps.googleapis.com/maps/api/js?key=${window.env.GOOGLE_API_KEY}&libraries=places`
    googlScript.src = sdkUrl
    googlScript.type = 'text/javascript'
    googlScript.sync = true
    googlScript.id = 'GooglScript'
    document.head.appendChild(googlScript)
    googlScript.onload = function () {
        ReactDOM.render(<App />, document.getElementById('root'));
    }
} catch (e) {
    ReactDOM.render(<App />, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import phoneIconSrc from '../../../assets/phone-receiver.svg'
import envelopeIconSrc from '../../../assets/mail-black-envelope-symbol.svg'
import bgImage from '../../../assets/orange-gif-bg.png'
import Localization from "../../../utilities/localization";
import {getFormattedDate} from "../../../utilities/functions";
import './coupon.scss'

class Coupon extends React.Component {
    state = {
        wrapperHeight: null
    }

    componentDidMount () {
        setTimeout(this.setSize, 200)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.size !== this.props.size) this.setSize()
    }

    setSize = () => {
        const {height: wrapperHeight} = this.domEl.getBoundingClientRect()
        this.setState({wrapperHeight})
    }

    render () {
        const {props, state: {wrapperHeight}} = this
        const fontSize = props.title.length >= 8
                ? (props.title.length >= 11 ? 36 : 46)
                : 56
        const heightProp = props.size === 'lg' ? 'minHeight' : 'height'
        const localizedStartDate = getFormattedDate(props.startDate, props.global.locale)
        const localizedEndDate = getFormattedDate(props.endDate, props.global.locale)

        return (
                <div className={`coupon-wrapper ${props.size}`} style={{[heightProp]: wrapperHeight}}>
                    <div className={`CouponComponent ${props.size} ${props.global.locale}`} ref={el => this.domEl = el}>
                        <div className="leftPanel">
                            <div className="titleBox">
                                <h2 className={`title ${!props.isFree ? 'small' : ''}`} style={{fontSize}}>
                                    {props.title}
                                </h2>
                            </div>
                            <h3 className="salesText">{props.salesText}</h3>
                            <p className="disclaimerText">{props.disclaimerText}</p>
                            <ul className="contactInfo">
                                <li>
                                    <img src={phoneIconSrc} alt="Phone Icon"/>
                                    {props.shopPhoneNumber}
                                </li>
                                <li>
                                    <img src={envelopeIconSrc} alt="Envelope Icon"/>
                                    {props.shopContactEmail}
                                </li>
                            </ul>
                        </div>
                        <div className="rightPanel">
                            <img src={bgImage} className="bgImage" />
                            <h4 className="shopName">{props.shopName}</h4>

                            <h5 className="shopAddress">{props.shopAddress}</h5>
                            <div className="savingsTitleBox">
                                <h4 className="savingsTitle">
                                    {props.global.locale === 'es'
                                        ? <>CUPONES DEL AHORRO</>
                                        : <>SAVINGS <strong>COUPON</strong></>}
                                </h4>
                            </div>
                            <h5 className="validityText">{Localization.print("OFFER VALID")}</h5>
                            <p className="expiration">
                                {props.global.locale === 'es' ? (
                                        <React.Fragment>Del {localizedStartDate} al {localizedEndDate}</React.Fragment>
                                ) : (
                                        <React.Fragment>{localizedStartDate} - {localizedEndDate}</React.Fragment>
                                )}
                            </p>
                        </div>
                    </div>
                </div>
        )
    }
}

Coupon.defaultProps = {
    disclaimerText: 'Valid only with full service installation. Valid for used or new tires. Not refundable used and new tires and no warranty for used tires.',
    endDate: '06/30/2019',
    isFree: true,
    salesText: 'Buy 3 Tires Get 1 Free',
    shopAddress: '4818 outer loop, louisville, KY 40219-3302',
    shopContactEmail: 'sample@gmail.com',
    shopName: 'Firestone complete auto care',
    shopPhoneNumber: '505-966-3032',
    size: 'lg',
    startDate: '06/07/2019',
    title: 'FREE'
}

Coupon.propTypes = {
    disclaimerText: PropTypes.string,
    endDate: PropTypes.string,
    isFree: PropTypes.bool,
    salesText: PropTypes.string,
    shopAddress: PropTypes.string,
    shopContactEmail: PropTypes.string,
    shopName: PropTypes.string,
    shopPhoneNumber: PropTypes.string,
    size: PropTypes.oneOf(['mobile', 'mobile-sm', 'lg', 'md', 'sm', 'xs']),
    startDate: PropTypes.string,
    title: PropTypes.string
}


export default connect(({global}) => ({global}))(Coupon)

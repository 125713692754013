import React from 'react'
import {connect} from 'react-redux'
import {Row, Col, Visible, ScreenClassRender} from 'react-grid-system';
import BodyHeader from '../body-header'
import ButtonAutoZone from "../../suite/button-autozone";
import Box from '../../suite/box'
import colors from "../../../utilities/colors";
import '../AddCoupon/addcoupon.scss'
import {
    clearCouponError,
    saveCoupon,
    setCurrentCoupon,
    setCurrentProperty,
    validateCoupon
} from "../../../redux/actions/coupons";
import Coupon from "../../suite/coupon";
import {couponTypes} from "../../../utilities/constants";
import {maskPhone} from "../../../utilities/functions";
import CouponForm from "../CouponForm";
import Localization from "../../../utilities/localization";

class EditCoupon extends React.Component {

    state = {
        previewCoupon: true
    }

    componentDidMount() {
        const {clearCouponError, location: {params: {coupon}}, setCurrentCoupon} = this.props
        if (coupon) setCurrentCoupon(coupon)
        clearCouponError()
    }

    _getCouponSize (screenClass) {
        switch (screenClass) {
            case "sm":
            case "xs":
                return "mobile"
            default:
                return "lg"
        }
    }

    _getCouponTitle (type = null) {
        const {steps: {stepFour: {currentCoupon}}} = this.props
        switch (type) {
            case couponTypes.free:
                return Localization.print("FREE")
            case couponTypes.special:
                return Localization.print("SPECIAL")
            case couponTypes.percent:
                return `${currentCoupon.percentOff}% ${Localization.print("OFF")}`
            case couponTypes.dollar:
                return `$${currentCoupon.dollarOff} ${Localization.print("OFF")}`
            default:
                return ''
        }
    }

    _getCTASize = screenClass => {
        return ["md", "sm", "xs"].includes(screenClass)
                ? {height: 28, width: 136}
                : {height: 38, width: 184}
    }

    _getPreviewBtnSize = screenClass => {
        return ["md", "sm", "xs"].includes(screenClass)
                ? {height: 28, width: 254}
                : {height: 28, width: 167}
    }

    _handleAddCoupon = () => {
        const {saveCoupon, validateCoupon} = this.props
        if (validateCoupon()) {
            saveCoupon()
            this.props.history.goBack()
        }
    }

    _handleCancel = () => this.props.history.goBack()

    _handleInputChange = (fieldName, value) => {
        const {setCurrentProperty} = this.props
        let _value = value
        if (value !== null && typeof value.getDate === 'function') {
            _value = value.toLocaleDateString()
        }
        setCurrentProperty(fieldName, _value)
    }

    _togglePreview = () => {
        const {previewCoupon} = this.state
        this.setState({previewCoupon: !previewCoupon})
    }

    render() {
        const {previewCoupon} = this.state
        const {
            steps: {
                stepOne: {
                    profileInfo
                },
                stepFour: {
                    couponTypes: {items: couponTypeItems},
                    currentCoupon,
                }
            }
        } = this.props

        return (
                <ScreenClassRender render={screenClass => (
                        <React.Fragment>
                            <Visible xl lg>
                                <div className="body-header">
                                    <BodyHeader/>
                                </div>
                            </Visible>
                            <div className="active-coupons">
                                <div className="addcoupon">
                                    <Row>
                                        <Col sm={12} md={12} className="no-padding">
                                            <Box width='100%'
                                                 backgroundColor='#6c6f70'
                                                 color='#ffffff'
                                                 title={Localization.print("Edit COUPONS")}
                                                 hint={["xl","lg"].includes(screenClass) ? `* ${Localization.print("Required fields")}` : ''}>
                                                <div style={{height: 8}} />
                                                <CouponForm onInputChange={this._handleInputChange} />
                                            </Box>
                                            <div className="info-container">
                                                <div className="informationButtonContainer">
                                                    {previewCoupon ? (
                                                            <p>{Localization.print("To Edit this coupon, use the form above and click ‘Preview Coupon’ to view changes.")}</p>
                                                    ) : (<p />)}

                                                    <ButtonAutoZone
                                                            id={'preview-coupons'}
                                                            text={Localization.print("PREVIEW COUPON")}
                                                            width={this._getPreviewBtnSize(screenClass).width}
                                                            height={this._getPreviewBtnSize(screenClass).height}
                                                            borderColor={colors.autozoneOrangeColor}
                                                            backgroundColor='white'
                                                            color={colors.autozoneOrangeColor}
                                                            handler={this._togglePreview}
                                                    />
                                                </div>
                                                {previewCoupon && (
                                                        <React.Fragment>
                                                            <div style={{height: 8}} />
                                                            <Coupon
                                                                    disclaimerText={currentCoupon.disclaimerText}
                                                                    endDate={currentCoupon.endDate}
                                                                    isFree={currentCoupon.type === couponTypes.free}
                                                                    salesText={currentCoupon.salesText}
                                                                    shopAddress={[
                                                                        profileInfo.shopAddress + ',',
                                                                        profileInfo.city + ',',
                                                                        profileInfo.state,
                                                                        profileInfo.zipCode
                                                                    ].join(' ')}
                                                                    shopContactEmail={profileInfo.shopContactEmail}
                                                                    shopName={profileInfo.shopName}
                                                                    shopPhoneNumber={maskPhone(profileInfo.shopPhoneNumber)}
                                                                    size={this._getCouponSize(screenClass)}
                                                                    startDate={currentCoupon.startDate}
                                                                    title={this._getCouponTitle(currentCoupon.type)}
                                                            />
                                                        </React.Fragment>
                                                )}
                                            </div>
                                            <div className="modalfooter">
                                                <ButtonAutoZone
                                                        id={'cancel-modal'}
                                                        text={Localization.print("CANCEL")}
                                                        width={this._getCTASize(screenClass).width}
                                                        height={this._getCTASize(screenClass).height}
                                                        borderColor={colors.autozoneBlackColor}
                                                        backgroundColor='white'
                                                        color={colors.autozoneBlackColor}
                                                        handler={this._handleCancel}
                                                />
                                                <ButtonAutoZone
                                                        id={'update-modal'}
                                                        text={Localization.print("UPDATE COUPON")}
                                                        width={this._getCTASize(screenClass).width}
                                                        height={this._getCTASize(screenClass).height}
                                                        borderColor={colors.autozoneOrangeColor}
                                                        backgroundColor={colors.autozoneOrangeColor}
                                                        color='white'
                                                        handler={this._handleAddCoupon}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </React.Fragment>
                )}/>
        )
    }
}
export default connect(
        ({steps}) => ({steps}),
        {clearCouponError, saveCoupon, setCurrentCoupon, setCurrentProperty, validateCoupon}
)(EditCoupon)

import React from 'react'
import {Visible} from 'react-grid-system'
import './checkBox.scss'
import checkmark  from '../../../assets/checkmark/tick_mark.svg'

export default class Checkbox extends React.PureComponent{
// eslint-disable-next-line
    constructor(props){
        super(props)

    }

    render(){
        const {item,handler, isBig} = this.props

        return  <div className={`checkBox ${(isBig ? 'big' : '')}`}>
                    <div className={item.checked?'check check-active':'check check-inactive'} onClick={ () => handler(item) }>
                        {item.checked && <img src={checkmark} alt='checkmark'/>}
                    </div>

                    <span className="text" >
                        <Visible xl lg md>
                            {item.text}
                        </Visible>
                        <Visible sm xs>
                            {item.textSmall || item.text}
                        </Visible>
                    </span>
                </div>
    }
}
import React from 'react'
import { getOauthLoginURL } from "../../../utilities/auth";

export default class LoginPage extends React.Component {
    constructor (props) {
        super(props)
        if (!sessionStorage.code) {
            const url = getOauthLoginURL('code')
            window.location.replace(url)
        }
    }
    render () { return null }
}
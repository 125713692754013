import React from 'react'
import PropTypes from 'prop-types'
import ModalHeader from "../../../../suite/modalHeader";
import './imageModal.scss'
import Localization from "../../../../../utilities/localization";

const ImageModal = ({onClose, source}) => (
        <div className="ImageModal">
            <ModalHeader handler={onClose} title={Localization.print("SHOP PICTURE")} />
            <div className="image-container">
                <img alt={'Shop'} src={source}/>
            </div>
        </div>
)

ImageModal.propTypes = {
    onClose: PropTypes.func,
    source: PropTypes.string.isRequired
}

export default ImageModal

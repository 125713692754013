import React from 'react'
import {connect} from 'react-redux'
import {Row, Col, Hidden, Visible} from 'react-grid-system';
import {getShortTimeZone, maskPhone, milesToKm} from "../../../../utilities/functions";
import {setShop, setShopAction} from "../../../../redux/actions/shops";
import DaysHoursTable from "../../../suite/daysHoursTable";
import BadgeImage from '../../../../assets/badge@3x.png';
import BadgeImageMx from '../../../../assets/badge_es@2x.png'
import {sessionStorageKey} from "../../../../utilities/constants";
import Localization from "../../../../utilities/localization";

const SearchResult = ({profile, ...props}) => {
    const {global: {locale}} = props
    const imageSrc = locale === 'es' ? BadgeImageMx : BadgeImage

    function handleClick() {
        const {history, setShop, setShopAction} = props
        setShop(profile)
        setShopAction(null)
        const tealiumData = {
            shop_name: profile.shopName,
            shop_id: profile.id
        }
        sessionStorage.setItem('MILES', props.distance)
        sessionStorage.setItem(sessionStorageKey.SHOP, JSON.stringify(tealiumData))
        history.push('/az/shopDetails')
    }

    return (
            <div className="srcresults">
                <Row>
                    <Col sm={6} md={6} lg={4}>
                        <div className="itemResult_info">
                            <div className="itemResult_marker">
                                <span className="icon-marker">{props.res}</span>
                            </div>
                            <div className="itemResult_content">
                                <div id="address">
                                    <span id={`itemResult_shop${props.res}`} className="title" onClick={handleClick}><strong>{profile.shopName}</strong></span>
                                    <span className="address-card">
                                        <span className="card-info">
                                            <span className="address">{profile.shopAddressObject.postalAddressLine1Text}, </span>
                                            <span className="address">{profile.shopAddressObject.cityName}, {profile.shopAddressObject.stateCode} {profile.shopAddressObject.zipCode} &nbsp;</span>
                                            <a href={`tel:${profile.shopContactObject.mainPhoneNumber}`} className="phnumber">
                                                {maskPhone(profile.shopContactObject.mainPhoneNumber, true)}
                                            </a>
                                        </span>
                                    </span>
                                </div>
                                <p className="tools" id="tools">
                                    <button type="submit" className="printButton" onClick={() => props.onPrint(profile, 'print')}/>
                                </p>
                                {profile.hasCoupons && (
                                        <span className="badge" onClick={handleClick} title={Localization.print("View available coupons")}>
                                            <img src={imageSrc} alt={Localization.print("Discounts available")} />
                                        </span>
                                )}
                            </div>
                        </div>
                        <Visible md sm xs>
                            <>
                                <div style={{height: 20}}/>
                                <Row>
                                    <Col md={6} sm={6} xs={6} >
                                        {profile.distance && (
                                                <span className="icon-smallmarker">
                                                    <strong>
                                                        {(locale === 'es' ? milesToKm(profile.distance) : profile.distance).toFixed(1)} {Localization.print("miles")}
                                                    </strong>
                                                </span>
                                        )}
                                    </Col>
                                    <Col md={6} sm={6} xs={6} >
                                        <span className="directions"><a herf="javascript:void();"
                                                                        onClick={() => props.onDirections(profile, 'directions')}>{Localization.print("Get Directions")}</a></span>
                                    </Col>
                                </Row>
                            </>
                        </Visible>
                    </Col>
                    <Col sm={6} md={6} lg={4}>
                        <div id="storehrs">
                            <span className="title">
                                <strong>{Localization.print("Store Hours")}</strong>&nbsp;
                                {locale !== 'es' && (
                                        <React.Fragment>
                                            ({profile.shopHoursObject.timeZoneCode && getShortTimeZone(profile.shopHoursObject.timeZoneCode)})
                                        </React.Fragment>
                                )}
                            </span>
                            <span className="table">
                                <DaysHoursTable days={profile.shopHoursObject.shopHoursArray} />
                            </span>
                        </div>
                    </Col>
                    <Hidden md sm xs>
                        <React.Fragment>
                            <Col sm={3} md={3} lg={2}>
                                {profile.distance && (
                                        <span className="icon-smallmarker">
                                            <strong>{(locale === 'es' ? milesToKm(profile.distance) : profile.distance).toFixed(1)} {Localization.print("miles")}</strong>
                                        </span>
                                )}
                            </Col>
                            <Col sm={2} md={2} lg={2} className="hideme">
                                <span className="directions"><a herf="javascript:void();" onClick={() => props.onDirections(profile, 'directions')}>{Localization.print("Get Directions")}</a></span>
                            </Col>
                        </React.Fragment>
                    </Hidden>
                </Row>
            </div>
    )
}
export default connect(({global}) => ({global}), {setShop, setShopAction})(SearchResult)

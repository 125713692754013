import * as tokenActions from '../actions/token'

export default function (state = {}, action) {
    switch (action.type) {
        case tokenActions.ADD_TOKEN:
            return {
                ...state,
                ...action.payload
            }

        case tokenActions.GET_TOKEN_INFO_REQUEST:
            return {
                ...state,
                tokenInfoLoaded: false
            }

        case tokenActions.SHOW_TOKEN:
        case tokenActions.REQUEST_TOKEN_ERROR:
            return {
                ...state,
                tokenRequestLoaded: true
            }

        case tokenActions.GET_TOKEN_INFO_SUCCESS:
        case tokenActions.GET_TOKEN_INFO_ERROR:
            return {
                ...state,
                tokenInfoLoaded: true
            }

        case tokenActions.REMOVE_TOKEN:
            return null

        default:
            return state
    }
}
import React from 'react';
import './DropDown.scss'
import arrow from '../../../assets/sort/Sort 2.png'
import {isMobile} from "../../../utilities/functions";

const Label = (props) => <label className={props.floating ? "floating" : "resting"}>
    <div className={'span'}> * </div>
    {props.labelText}
    &nbsp;
</label>

export default class DropDown extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            _isMobile: isMobile(window.navigator.userAgent)
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false)
        document.addEventListener('keydown', this.handleKeyDown, false)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false)
        document.removeEventListener('keydown', this.handleKeyDown)
    }

    handleClick = (e) => {
        try {
            if (this.node !== null && this.node !== undefined && this.node.contains(e.target))
                return;
            this.close()
        } catch (e) {

        }

    }

    handleKeyDown = e => {
        const {open} = this.state
        if (!open) return

        if (e.key.toLowerCase() === 'enter' && open) {
            this.close()
        }
    }

    handleNativeSelectorChange = e => {
        const {handler, items, multiple} = this.props
        const {target} = e.nativeEvent
        const trueValue = (data) => {
            return isNaN(data) ? data : parseInt(data)
        }
        let value = multiple
                ? Array.from(target.options)
                        .filter(option => option.selected)
                        .map(option => option.value)
                        .map(id => items.find(item => item.id === trueValue(id)))
                : trueValue(target.value)
        handler && handler(value)
    }

    toggle() {
        this.setState({open: !this.state.open})
    }

    close() {
        this.setState({open: false})
    }

    render() {

        const {_isMobile, open} = this.state
        const {error, selected, labelText, width, items, multiple} = this.props
        const inputWidth = (typeof width === 'string') ? width + 'px' : width
        const labelValue = (typeof selected === 'string') ? selected
                : Array.isArray(selected)
                        ? selected.map(el => el.text).join(', ')
                        : selected.text
        const selectValue = _isMobile
                ? (
                        multiple
                                ? selected ? selected.map(el => (el.id || el)) : []
                                : selected.id || selected
                )
                : ""

        return (
                <div className={`dropdown ${error ? 'error' : ''}`} style={{width: inputWidth}} ref={(node) => {
                    this.node = node
                }}>
                    <Label labelText={labelText} floating={labelValue !== ""}/>
                    <div className={`head ${open ? 'opened' : ''}`} onClick={() => {if (!_isMobile) this.toggle()}}>
                        <input value={labelValue} onFocus={() => this.toggle()} autoComplete={'off'} />
                        <img src={arrow} alt='arrow'/>
                    </div>

                    {!_isMobile && open && (
                            <div className="dropDownBody" ref="dropDownList" style={{width: width + 'px'}}>
                                {this.props.children}
                            </div>
                    )}

                    {_isMobile && (
                            <select multiple={multiple} onChange={this.handleNativeSelectorChange} value={selectValue}>
                                {!multiple && !selected && <option value="0">Select an option</option>}
                                {items.map((item, i) => (
                                        <option key={i} value={item.id}>{item.text}</option>
                                ))}
                            </select>
                    )}

                    {!!error && <span className="error-message">{error}</span>}
                </div>
        )
    }
}

DropDown.defaultProps = {
    items: [],
    multiple: false
}

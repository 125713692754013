import React from 'react'
import './disclaimerText.scss'

export function DisclaimerText({locale}) {
    return (
            <div className="DisclaimerText">
                {locale === 'es' ? (
                        <React.Fragment>
                            <strong className="disclaimerTitle">¡INFORMACIÓN IMPORTANTE!</strong>

                            <strong>Códigos de Fallas</strong>
                            <p>Si ha obtenido en AutoZone la lectura de los códigos para diagnosticar la falla de su
                                vehículo, un taller mecánico puede reconfirmar esos códigos. La mayoría de los talleres
                                mecánicos tienen equipos de diagnóstico de grado superior capaces de identificar con
                                mayor precisión el problema de su vehículo.</p>

                            <strong>Compra de Autopartes</strong>
                            <p>Mientras que algunos talleres permiten a los clientes que traigan sus propias autopartes,
                                muchos no lo hacen. Una vez que un mecánico diagnostica el problema de su vehículo,
                                puede determinar que necesita piezas diferentes o adicionales. Si usted desea comprar
                                sus propias piezas, llame al taller antes de llegar y pregunte acerca de su política con
                                respecto a este asunto.</p>

                            <strong>Exención de responsabilidad para el uso de Programa de Talleres Referidos</strong>
                            <p>AutoZone es una empresa independiente y no tiene ningún interés de propiedad u otro
                                control sobre cualquiera de los proveedores de servicios automovilísticos que figuran en
                                este formulario. La información proporcionada acerca de los talleres en esta lista,
                                incluyendo la identificación de los tipos de trabajo realizado por el taller y los tipos
                                de vehículos en los que se trabaja, se basa en la información que se suministra a
                                AutoZone por un representante del taller. AutoZone no ha verificado y no garantiza la
                                exactitud de dicha información ni la capacidad de la tienda para realizar los servicios
                                indicados, o la calidad del trabajo o competencia del taller. Debido a que los
                                proveedores de servicios operan de manera totalmente independiente de AutoZone, no
                                hacemos ninguna garantía acerca de cualquier trabajo realizado por cualquier proveedor y
                                no asumimos ninguna responsabilidad de ningún tipo en relación con cualquier servicio,
                                producto o piezas proporcionadas por dichos talleres más allá de la garantía
                                proporcionada por AutoZone específicamente con respecto a los componentes comprados
                                directamente por usted en una tienda AutoZone. Talleres en esta lista son talleres
                                preferidos de AutoZone basados en el volumen de negocio de las piezas de automóviles que
                                el proveedor hace con AutoZone. La designación como Taller Referido AutoZone no pretende
                                representar o transmitir cualquier aprobación por parte de AutoZone de la obra o
                                servicios prestados por ese taller.</p>
                        </React.Fragment>
                ) : (
                        <React.Fragment>
                            <strong className="disclaimerTitle">IMPORTANT INFORMATION!</strong>

                            <strong>Trouble Codes</strong>
                            <p>If you've had AutoZone read your trouble code to help you determine your car's problem, a
                                private garage may have to re-read your codes. Most shops own higher grade diagnostic
                                equipment that may be able to more accurately identify your car's problem.</p>

                            <strong>Purchasing Parts</strong>
                            <p>While some shops allow customers to bring their own parts, many do not. Once a mechanic
                                diagnoses your car's problem, they may determine that you need different or additional
                                parts. If you wish to purchase your own parts, call the shop before arriving to ask
                                about their policy regarding this matter.</p>

                            <strong>Disclaimer for Use of Find a Repair Shop</strong>
                            <p>AutoZone is an independent company and has no ownership interest or other control over
                                any of the automotive service providers listed on this form. The information provided
                                about each shop on this list, including the identification of the types of work
                                performed by the shop and the types of cars on which it works, is based upon information
                                which was supplied to AutoZone by a representative of the shop. AutoZone has not
                                verified and does not warrant the accuracy of this information or the capability of the
                                shop to perform the stated services, or the quality of work or competency of the shop.
                                Because the service providers operate entirely independently of AutoZone, we do not make
                                any warranty about any work done by any such provider and assume no liability of any
                                sort with regard to any services, products or parts provided by such shops beyond the
                                warranty provided by AutoZone specifically with respect to parts bought directly by you
                                from an AutoZone store. Shops on this list are AutoZone Preferred Shops based upon the
                                volume of auto parts business that the provider does with AutoZone. Designation as an
                                AutoZone Preferred Shop is not intended to represent or convey any endorsement by
                                AutoZone of the work or services performed by that shop.</p>
                        </React.Fragment>
                )}
            </div>
    )
}

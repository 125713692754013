import React from 'react'
import { Row, Col } from 'react-grid-system';
import './homePage.scss'
import ButtonAutoZone from '../../suite/button-autozone'
import {getLoginURL} from "../../../utilities/auth";
import BodyHeader from "../body-header";
import { isIframe } from '../../../utilities/functions';

export default class LandingPage extends React.Component{
    // eslint-disable-next-line
    constructor(props){
        super(props)
    }

    componentDidMount() {
        window.scrollTo(0,0)
    }

    Login(){
        // const url = window.env.MAIN_PAGE_URL;
        //window.open(url, "_self");
        const url = getLoginURL()
        if (isIframe()) {
            window.parent.location.replace(url)
        } else {
            window.location.replace(url)
        }
    }

    requestaccess(){
        const url =  window.env.MAIN_PAGE_URL + 'myzone/requestAccess/existingCustomerRequest.jsp';
        if (isIframe()) {
            window.open(url, "_parent")
        } else {
            window.open(url, "_self")
        }
    }

    render(){
        return  (
            <React.Fragment>
                <div className="body-header" >
                    <BodyHeader hideControls/>
                </div>
                <div style={{padding: '0 5px'}}>
                    <Row>
                        <Col sm={12} md={12}>
                            <h1 className="big-title"> GROW YOUR BUSINESS WITH AUTOZONE’S SHOP REFERRAL PROGRAM!</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} md={6}>
                            <div className="description" >
                                <ul>
                                    <li>Our retail customers have varying levels of repair expertise, and many need your help. </li>
                                    <li>Over 5 million customers visit our stores each week.</li>
                                    <li>Over 5 million customers visit <a href="https://www.autozone.com/" target="_parent">AutoZone.com</a> each week.</li>
                                </ul>
                                <h2>We drive the traffic, you have the expertise, let us send our walk-in and online customers to you.</h2>

                                <h2 className="started-today">Get Started Today</h2>

                                <ButtonAutoZone handler={this.Login.bind(this)} text={'Login'} width={'111px'} height={'38px'} borderColor={'#f37f00'} backgroundColor={'#f37f00'} color='#ffffff'/> <span style={{display: 'inline-block', margin: '0 6px'}}>or</span> <ButtonAutoZone handler={this.requestaccess.bind(this)} text={'Request Access'} width={'164px'} height={'38px'} borderColor={'#f37f00'} backgroundColor={'#f37f00'} color='#ffffff'/> <span>to activate your listings*</span>

                                <p>Once logged in go to Account and Select My Account to activate your profile</p>

                                <p><strong>*</strong>Participating shops must meet qualification requirements, including weekly sales level thresholds. See Qualification Details link on Manage Referral Profile site for more info.</p>
                            </div>
                        </Col>

                        <Col sm={6} md={6} style={{paddingLeft: 0}}>
                            <img className="imagebox" alt=""/>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        )
    }
} 
import {combineReducers}  from 'redux'

import cookie from './cookieReducer'
import global from './global'
import profile from './profileReducer'
import shops from './shops'
import steps from './stepReducer'
import token from './tokenReducer'

export default combineReducers({
    cookie,
    global,
    profile,
    shops,
    steps,
    token
})
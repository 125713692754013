import {readCookie} from "../redux/actions/cookie";
import {sessionStorageKey, TEALIUM} from "./constants";


export function tealiumLink (details = {}) {
    if (typeof window.utag !== "undefined") {
        window.utag.link(details)
    }
}

export default function tealiumView (site_section = 'azpro: Shop referral', page_content_type = 'azpro: Shop referral') {
    if (typeof window.utag !== "undefined") {

        // AZ.com - find a repair shop
        const pathname = window.location.pathname;
        if (pathname.indexOf('findShop') > -1) {
            return;
        }

        let page_url = window.location.href.replace(/\?[^?]+$/, "");
        page_url = page_url.replace(/\#[^#]+$/, "");
        const path = window.location.hash.replace('#/', '')
        let content_type = ''
        if(site_section === 'Shop Referral: Home' ){
            content_type = 'Shop Referral: Home'
        }
        else if(site_section ==='Shop referral : Edit Profile' || site_section ==='Shop referral : Account' ) {
            content_type = 'Shop Referral: Profile'
        }
        else{
            content_type = 'Shop Referral: Registration'
        }
        const data = {
            page_content_type: content_type,
            page_name: !!path ? `${site_section}: ${path}` : site_section,
            page_url,
            site_section:'Shop Referral',
            url: window.location.href
        }
        const cookie = readCookie('JSESSIONID')

        //Shop Referral Signup Start
        if(site_section === 'Shop referral : Registration: Shop information and Hours' )
        {
            let value = sessionStorage.getItem(TEALIUM.singup_status)
            if (value === 'Signup Start') {
              data[TEALIUM.shopForm] = sessionStorage.getItem(TEALIUM.singup_status)
              sessionStorage.setItem(TEALIUM.singup_status, 'Signup Done')
            }
        }

        //Shop Referral 
        let valueUpdate = sessionStorage.getItem(TEALIUM.singup_status)
        
        if (valueUpdate === 'Update Profile') {
            data[TEALIUM.shopForm] = sessionStorage.getItem(TEALIUM.singup_status)
            sessionStorage.setItem(TEALIUM.singup_status, 'Update Profile Done')
        }
        if (valueUpdate === 'Signup Complete') {
            data[TEALIUM.shopForm] = sessionStorage.getItem(TEALIUM.singup_status)
            sessionStorage.setItem(TEALIUM.singup_status, 'Signup Complete Done')
        }

         // Shop Referral Image Status
        if (sessionStorage.image_status) {
            data[TEALIUM.profilePicture] = sessionStorage.getItem(TEALIUM.image_status)
        }
        
        if (sessionStorage.user) {
            const user = JSON.parse(sessionStorage.getItem('user'))
            data.user_pin = user.currentpin
        }

        if (cookie) {
            data.jsession_id = cookie
        }

        // AZPRO
        // Shop Referral Eligibility Status
        if (sessionStorage.eligible) {
            data.shop_referral_status = sessionStorage.getItem(TEALIUM.eligible)
        }

        // Shop Coupon Count
        if (sessionStorage.coupon_count) {
            data.shop_coupon_count = sessionStorage.getItem(TEALIUM.coupon_count)
            data[TEALIUM.shopCouponCount] = sessionStorage.getItem(TEALIUM.coupon_count)
        }

        // Shop Referral Status
        if (sessionStorage.shop_status) {
            data[TEALIUM.shopReferralStatus] = sessionStorage.getItem(TEALIUM.shop_status)
        }

        // AZ.COM
        if (path === 'az/shopDetails') {
            if (sessionStorage[sessionStorageKey.SHOP]) {
                const shopData = JSON.parse(sessionStorage.getItem(sessionStorageKey.SHOP))
                if (shopData.shop_name && shopData.shop_id) {
                    data[TEALIUM.shopDetails] = shopData.shop_name
                    data.shop_name = shopData.shop_name
                    data.shop_id = shopData.shop_id
                }
            }
        }

        window.utag.view({...data});
    }
}

export function dataLayerAZ(pageName, pageData = '') {
    let pageURL = window.location.href.replace(/\?[^?]+$/, "");
        pageURL = pageURL.replace(/\#[^#]+$/, "");
    let data = {};

    switch (pageName) {
        case 'findShop':
            data = {
                pageType: 'az:shop referral',
                pageName: 'az:Find A Repair Shop:Home',
            }
            
            if (pageData !== '') {
                data.findRepairShopPins = pageData.pins;
                if (pageData.shopClick !== 'initialView') {
                    data.findRepairShopClicks = pageData.shopClick;
                }    
            }
            break;
        case 'shopDetails':
            data = {
                pageName: `az:Find A Repair Shop:${pageData.shopName}`,
                pageType: 'az:shop referral:Shop details',
                findRepairShopPins: pageData.pin,
            }
            break;
        case 'nearbyShop':
            data = {
                findRepairShopClicks: 'Nearby Stores',
                pageType: 'az:shop referral:Shop details',
                pageName: `az:Find A Repair Shop:${pageData.shopName}`,
                findRepairShopPins: pageData.pin,
            }
            break;     
        default:       
    }

    const viewData = { 
        ...data,
        pageURL,
        siteSection: 'Shop Referral',
    };
    window.utag.view(viewData);
} 

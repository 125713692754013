import React from 'react'
import {maskPhone, milesToKm} from "../../../../utilities/functions";
import Localization from "../../../../utilities/localization";

const NearbyStores=({onClick, ...props})=>  (
        <React.Fragment>
            <div className="nearby-store">
                <span className="store-title" onClick={() => onClick(props)}><strong>{props.shopName}</strong></span>
                <span className="store-address">
                    {props.shopAddressObject.postalAddressLine1Text} <br />
                    {props.shopAddressObject.cityName}, {props.shopAddressObject.stateCode} {props.shopAddressObject.zipCode}
                </span>
                <a className="store-number" href={`tel:${props.shopContactObject.mainPhoneNumber}`}>
                    {maskPhone(props.shopContactObject.mainPhoneNumber, true)}
                </a>
                <span className="miles">
                    {(props.locale === 'es' ? milesToKm(props.distance) : props.distance).toFixed(1)} {Localization.print("miles")}
                </span>
            </div>
        </React.Fragment>
)

export default NearbyStores

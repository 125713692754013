import React from 'react'
import {Row, Col} from 'react-grid-system';
import './stepWrapper.scss'
import {connect} from 'react-redux'
import {resetStepsData, setCurrentStep, validateStepOne} from '../../../redux/actions'
import ButtonAutoZone from '../../suite/button-autozone'
import {withRouter} from 'react-router-dom'
import Stepper from "../../suite/stepper";
import {saveProfile, updateProfile} from "../../../redux/actions/profile";
import Portal from '../../portal'
import SaveAlert from './alertBox'
import {saveCoupon, validateCoupon} from "../../../redux/actions/coupons";
import {profileStep} from "../../../utilities/constants";
import SaveProfileAlert from "../StepFinal/saveProfileAlert";
import {getStepPath} from "../../../utilities/functions";
import Localization from "../../../utilities/localization";

class StepWrapper extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showSaveAlert: false,
            showSaveProfileAlert: false
        }

        window.addEventListener('beforeunload', this.handlePageClose)
    }

    componentDidMount() {
        const {location: {pathname}, setCurrentStep} = this.props
        let currentStep
        switch (pathname.replace('/Step', '').toLowerCase()) {
            default:
            case 'one': currentStep = 1; break;
            case 'two': currentStep = 2; break;
            case 'three': currentStep = 3; break;
            case 'four': currentStep = 4; break;
            case 'final': currentStep = 5; break;
        }
        setCurrentStep(currentStep)
    }

    //next = () => {
    //    const {profile, history} = this.props
    //    const fn = (profile && profile.id) ? 'updateProfile' : 'saveProfile'
    //    const nextStep = parseInt(this.props.currentStep) + 1
    //    const currentStep = profileStep[this.props.currentStep]
    //    const save = () => this.props[fn](currentStep, false)

    //    switch (nextStep) {
    //        case 2:
                // here goes the validation
    //            if (this.props.validateStepOne()) {
    //                save()
    //               history.push('/StepTwo');
    //            }
    //            else return;
    //            break;
    //        case 3:
    //            save()
    //            history.push('/StepThree');
    //            break;
    //        case 4:
    //            save()
    //            history.push('/StepFour');
    //            break;
    //        case 5:
    //            if (!this.props.validateCoupon(false)) {
    //                save()
    //                history.push('/StepFinal');
    //            } else {
    //                this.props.saveCoupon()
    //                        .then(() => {
    //                            save()
    //                            history.push('/StepFinal')
    //                        })
    //                        .catch(error => {
    //                            console.log(error)
    //                        })
    //            }

    //            break;
    //        default:
    //            break;
    //    }

    //    this.props.setCurrentStep(nextStep)
    // }
    


    next = () => {
        const {profile, history} = this.props
        const nextStep = parseInt(this.props.currentStep) + 1
        const currentStep = profileStep[this.props.currentStep]
        let fn;
        //const fn = (profile && profile.id) ? 'updateProfile' : 'saveProfile'
        if((currentStep==='INFHRS') && !(profile && profile.id))
        {
            fn='saveProfile';
        }
        if((currentStep==='INFHRS') && (profile && profile.id))
        {
            fn='updateProfile';
        }
        if(currentStep!=='INFHRS') 
        {
            fn='updateProfile';
        }
        console.log('>>>>>>>>>>Function Called'+ fn + 'currentStep' + currentStep);
        const save = () => {
        this.props[fn](currentStep, false)
        }
        switch (nextStep) {  
            case 2:
                if (this.props.validateStepOne()) {
                    // const validation = (profile && profile.id)?true:false
                    // if(validation){
                       
                        save()
                        history.push('/StepTwo');
                    //} 
                }
                else return;
                break;
            case 3:
               
                save()
                history.push('/StepThree');
                break;
            case 4:
               
                save()
                history.push('/StepFour');
                break;
            case 5:
               
                if (!this.props.validateCoupon(false)) {
                    
                    save()
                    history.push('/StepFinal');
                } else {
                    this.props.saveCoupon()
                            .then(() => {
                                save()
                                history.push('/StepFinal')
                            })
                            .catch(error => {
                                console.log(error)
                            })
                }

                break;
            default:
                break;
        }
       
        this.props.setCurrentStep(nextStep)
       
    }

    //finish = () => {
    //    const {profile} = this.props
    //    const currentStep = profileStep[5]
    //    const fn = (profile && profile.id) ? 'updateProfile' : 'saveProfile'
    //    this.props[fn](currentStep)
    //            .then(() => {
    //                this.props.history.push('/Account')
    //           })
    //            .catch(e => {
    //                this.setState({showSaveProfileAlert: true})
    //            })
    //}

    finish = () => {
        const {profile} = this.props
        const currentStep = profileStep[5]
        //const fn = (profile && profile.id) ? 'updateProfile' : 'saveProfile'
        let fn;
        if(currentStep!=='INFHRS') 
        {
            fn='updateProfile';
        }
        console.log('>>>>>>>>>>Function finish'+ fn + 'currentStep' + currentStep);
        this.props[fn](currentStep)
                .then(() => {
                   
                    this.props.history.push('/Account')
                })
                .catch(e => {
                    this.setState({showSaveProfileAlert: true})
                })
    }

    getButtonSize = () => {
        const {screenClass} = this.props
        let buttonSize

        if (["xl"].includes(screenClass)) {
            buttonSize = 184
        } else if (["lg", "md"].includes(screenClass)) {
            buttonSize = 152
        } else if (["xs", "sm"].includes(screenClass)) {            
            buttonSize = 288
        } else {
            buttonSize = null
        }

        return buttonSize
    }

    /**
     * Just close the alert
     */
    handleCancelQuit = () => this.setState({showSaveAlert: false})

    /**
     * Redirects the user to HomePage and after the user is no longer
     * on StepOne page we call a function to reset the data to its
     * original state.
     */
    handleContinueQuit = () => {
        this.props.history.replace('/')
        setTimeout(() => this.props.resetData(), 100)
    }

    handleItemClicked = (step) => {
        const {currentStep, history} = this.props
       if (currentStep > step) {
           history.replace(getStepPath(step))
       }
    }

    handlePageClose = () => {
        const {currentStep, profile} = this.props
        const stepValue = profileStep[(currentStep === 5 ? 4 : currentStep)]
        const save = () => {
            const fn = profile && profile.id ? 'updateProfile' : 'saveProfile'
            // try to send before close
            this.props[fn](stepValue)
        }

        if (currentStep === 1) {
            if (this.props.validateStepOne()) save()
        } else if (currentStep === 4) {
            if (!this.props.validateCoupon(false)) {
                save()
            } else {
                this.props.saveCoupon().finally(save)
            }
        } else {
            save()
        }

        window.removeEventListener('beforeunload', this.handlePageClose)
    }

    previous = () => {

        let previousStep = parseInt(this.props.currentStep) - 1  
                   

        if (previousStep === 0) {
            this.setState( previous=>({
                showSaveAlert:!previous.showSaveAlert
            }))   
        } 

        if (previousStep >= 1) {        
            this.props.setCurrentStep(previousStep);  
            this.props.history.replace(getStepPath(previousStep))
        }
        
    }

    render() {
        const buttonSize = this.getButtonSize()
        const {showSaveAlert, showSaveProfileAlert} = this.state
        const {canComplete, locale} = this.props
        return (
            <React.Fragment>
                <div className="stepWrapper">
                    <Row>
                        <Col sm={12} md={12}>
                            <Stepper
                                    fullCover={this.props.fullCover}
                                    currentStep={this.props.currentStep}
                                    steps={[
                                        {
                                            line1: locale === 'es' ? 'Información del' : 'Shop Information',
                                            line2: locale === 'es' ? 'Taller y horarios' : 'And Hours',
                                            onClick: this.handleItemClicked.bind(this, 1)
                                        },
                                        {
                                            line1: locale === 'es' ? 'Certificaciones y/o' : 'Shop Certification',
                                            line2: locale === 'es' ? 'Afiliaciones del taller' : 'And Affiliation',
                                            onClick: this.handleItemClicked.bind(this, 2)
                                        },
                                        {
                                            line1: locale === 'es' ? 'Tipos de servicio y' : 'Services Performed',
                                            line2: locale === 'es' ? 'Vehículos que se atienden' : 'And Vehicles Serviced',
                                            onClick: this.handleItemClicked.bind(this, 3)
                                        },
                                        {line1: locale === 'es' ? 'Cupones' : 'Coupons', onClick: this.handleItemClicked.bind(this, 4)}
                                    ]}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12}>
                            {this.props.children}
                        </Col>
                    </Row>
                    <Row>
                        <Col> &nbsp; </Col>
                        <Col sm={12} md="content">
                            <div className="footer-bar">                                                    
                            {this.props.currentStep < 5 &&
                                <ButtonAutoZone text={Localization.print("PREVIOUS")} width={buttonSize} height='38px' borderColor='#3a3c3d'
                                                backgroundColor='white' color='#3a3c3d'
                                                handler={this.previous}/>}
                                {this.props.currentStep < 4 &&
                                <ButtonAutoZone text={Localization.print("NEXT")} width={buttonSize} height='38px' borderColor='#f37f00'
                                                backgroundColor='#f37f00' color='white'
                                                handler={this.next}/>}
                                {this.props.currentStep === 4 &&
                                <ButtonAutoZone text={Localization.print("FINISH")} width={buttonSize} height='38px' borderColor='#f37f00'
                                                backgroundColor='#f37f00' color='white'
                                                handler={this.next}/>}
                                {this.props.currentStep === 5 &&
                                <ButtonAutoZone text={Localization.print("CONFIRM")} width={buttonSize} height='38px' borderColor='#f37f00'
                                                backgroundColor='#f37f00' color='white'
                                                opacity={canComplete ? 1 : 0.5}
                                                disabled={!canComplete}
                                                handler={this.finish}/>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>

                {showSaveAlert && <Portal>
                    <SaveAlert onCancel={this.handleCancelQuit} onContinue={this.handleContinueQuit} locale={this.props.locale} />
                </Portal>}

                {showSaveProfileAlert && (
                        <Portal>
                            <SaveProfileAlert onOk={() => this.setState({showSaveProfileAlert: false})}/>
                        </Portal>
                )}

            </React.Fragment>
        )

    }
}

const mapStateToProps = state => ({
    canComplete: state.steps.canComplete,
    currentStep: state.steps.currentStep,
    fullCover: state.steps.fullCover,
    locale: state.global.locale,
    profile: state.profile.data
})

const mapDispatchToProps = dispatch => {
    return {
        resetData: () => dispatch(resetStepsData()),
        saveCoupon: () => dispatch(saveCoupon()),
        saveProfile: (currentStep) => dispatch(saveProfile(currentStep)),
        setCurrentStep: stepNumber => dispatch(setCurrentStep(stepNumber)),
        updateProfile: (currentStep, getInfo) => dispatch(updateProfile(currentStep, getInfo)),
        validateCoupon: (showErrors) => dispatch(validateCoupon(showErrors)),
        validateStepOne: () => dispatch(validateStepOne())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StepWrapper))

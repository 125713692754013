import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Hidden, Visible } from 'react-grid-system'
import { Helmet } from 'react-helmet'
import './findshop.scss'
import Map from '../homePage/map'
import SearchResult from './searchResult'
import {
    removeAllFilters,
    removeFilter,
    searchShops,
    setLocation, setOptions, setPlace,
    setShop,
    setShopAction
} from "../../../redux/actions/shops";
import Pagination from "./pagination";
import PredictiveSearch from "../../suite/googlePredictiveSearch";
import Select from 'react-select';

import BlurPortal from "../../blurPortal";
import FiltersModal from "./filtersModal";
import EmailModal from './emailModal';
import {setModalState} from "../../../redux/actions/global";
import FiltersList from "./filtersList";
import {
    getGoogleDirectionsLink,
    isIframe,
    kmToMiles,
    milesToKm,
    scrollIframeToViewPort
} from "../../../utilities/functions";
import {DisclaimerText} from "./disclaimerText";
import NoResultsFound from "./noResultsFound"
import Printlogo from '../../../assets/logo-print.JPG';


import {dataLayerAZ} from "../../../utilities/utag";
import {sessionStorageKey, firestoneURL, fixFinderURL} from "../../../utilities/constants";
import Localization from "../../../utilities/localization";
import clickTrack from "../../../utilities/azClicks";

const customStyles = {    
    control: (_, { selectProps: { width, height}}) => ({
        width: width,
        height: height,   
        borderRadius: 2,           
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#f2f2f2' : '#fff',
        color: '#484848',
        paddingTop: 5,
        paddingBottom: 5,
        marginTop: 0,
        marginBottom: 1
    }),
    menu: styles => ({ 
      ...styles, 
      zIndex: 999,
      marginTop: '-.05em',
      fontSize: '14px',  
      fontFamily: 'Helvetica',  
      borderRadius: 2,  
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,      
      boxShadow: '0px 2px 5px 2px transparent',
      border: '1px solid #a9aaa8',
      borderTop: 'none'
    }),
    menuList: styles => ({
        ...styles,
        height: 254 
    }),
    dropdownIndicator:(provided, state) => ({
        ...provided,
        padding: 4,
        color: '#484848'
    })    
};
const km = miles => Math.round(milesToKm(miles)).toString()
const miles = km => Math.round(kmToMiles(km)).toString()
//const locale = sessionStorage.getItem(sessionStorageKey.LOCALE) || 'us'
class FindShop extends React.Component{

    
    constructor (props) {
        super(props)
        let locale = sessionStorage.getItem(sessionStorageKey.LOCALE) || 'us'
        
        this.state = {
            distance: 25,
            lat: locale === 'es' ? 25.6358608 : 35.1495343,
            lng: locale === 'es' ? -100.3156963 : -90.0489801,
            place: null,
            profiles: (props.shops.profiles || []),
            showFiltersModal: false,
            showEmailModal: false,
            selectedOption: { label: '25', value: locale === 'es' ? miles(25) : '25' },
            selectOptionLabel: 25
        }
        this.pageClick = '';
    }

    // pageClick(e)
    // {
    //     if(e.keyCode===13)
    //     {
    //         console.log("key")
    //         this._handleSearch(true);
    //     }
    // }

    componentDidMount() {
        const {shops, setShop, setShopAction} = this.props
        if (shops.currentShop) setShop(null)
        if (shops.action) setShopAction(null)
        if( sessionStorage && sessionStorage.getItem('shopDetailsSearch')) {
            this.pageClick = sessionStorage.getItem('shopDetailsSearch');
            sessionStorage.removeItem('shopDetailsSearch');
        } else {
            this.pageClick = 'initialView';
        }

        this._getUserLocation(false, false)
        let locale = sessionStorage.getItem(sessionStorageKey.LOCALE) || 'us'

        let sessionMiles = sessionStorage.getItem('MILES') 
        console.log('>>>>>>componentDidMount' + sessionMiles);
        if(sessionMiles)
        {
            let value ={ label: sessionMiles, value: locale === 'es' ? miles(sessionMiles) : sessionMiles }
            this.setState({ selectOptionLabel: sessionMiles});
            this.setState({ distance: parseInt(value.value)});
            this.setState({selectedOption: value});
            sessionStorage.setItem('MILES', 25)
        }
        else{
            this.setState({selectedOption: { label: '25', value: locale === 'es' ? miles(25) : '25' }});
        }
        

        //document.addEventListener('keydown', this.pageClick, false);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.shops.rows !== this.props.shops.rows) {
            this.getDocumentHeight();
        }
        if (
            prevProps.shops.loading 
            && !this.props.shops.loading 
            && this.pageClick !== ''
        ) {
            if (this.props.shops.profiles.length) {
                const pins = this.props.shops.profiles.map(shop => shop.id).join('|');

                if (this.props.shops.filters.length) {
                    const selectedFilters = this.props.shops.filters.map(filter => filter.text).join('|');
                    clickTrack.findRepairShopClicks('applyFilter', {
                        'pin': pins,
                        'selectedFilters': selectedFilters
                    });
                } else {
                    dataLayerAZ('findShop', {
                        pins,
                        shopClick: this.pageClick, 
                    });
                }
            } 
            else if (this.pageClick === 'initialView') { 
                //only if there are no shop profiles on page load
                dataLayerAZ('findShop');
            }         
            this.pageClick = '';
        }
    }

    getDocumentHeight = () => {
        const documentHeight = document.body.offsetHeight;
        console.log("documentHeight",documentHeight)
        window.parent.postMessage(JSON.stringify( {'documentHeight': documentHeight} ), '*' )
    }

    // componentWillUnmount()
    // {
    //     document.removeEventListener('keydown', this.pageClick, false);
    // }

    _getPaginationData = (requestData, rows) => {
        let start, end

        if (requestData && rows) {
            const {requestedPageNumber: page, resultsLinesPerPage: max} = requestData
            end = (max * page)
            start = end - max + 1
            if (end > rows) end = rows
        }
        if(rows === 0)
        {
            end = 0
            start = 0
        }

        return {start, end}
    }

    _getUserLocation = (pan = false, track = true) => {
        const {shops: {location: userLocation}} = this.props
        if (userLocation) {
            if (pan && this.refs.predictiveSearch) {
                this.refs.predictiveSearch.clear()
            }
            this.setState({
                userLocation,
                pan: pan ? {lat: userLocation.latitude, lng: userLocation.longitude} : null
            }, () => this._handleSearch(track))
        } else {
            window.navigator.geolocation.getCurrentPosition(({coords}) => {
                this.setState({lat: coords.latitude, lng: coords.longitude, userLocation: coords, place: null}, () => {
                    this.props.setLocation(coords)
                    this._handleSearch(track)
                })
            }, () => {
                this._handleSearch(track)
            })
        }
    }

    _handleLocationButton = () => {
        this.props.setPlace(null)
        this._getUserLocation(true)
        this.pageClick = 'Search';
    }

    _handleFilterRemove = (item) => {
        this.props.removeFilter(item)
        setTimeout(this._handleSearch, 300)
    }

    _handleItemActions = (item, action) => {
        const {history, setShop, setShopAction} = this.props
        if (action === 'print') {
            setShop(item)
            setShopAction(action)
            sessionStorage.setItem(sessionStorageKey.SKIP, "1")
            clickTrack.findRepairShopClicks('print', {
                'pin': item.id,
            });
            history.push('/az/shopDetails')
        } else if (action === 'directions') {
            const {lat, lon} = item.shopAddressObject.geoLocation
            window.open(getGoogleDirectionsLink(lat, lon), '_blank')
            clickTrack.findRepairShopClicks('getDirections', {
                'pin': item.id,
            });
        }
    }

    _handlePagination = page => {
        this.props.setOptions({requestedPageNumber: page})
        this.pageClick = 'Pagination';
        setTimeout(this._handleSearch, 100)
        if (isIframe()) {
            scrollIframeToViewPort('#map-container')
        }
        console.log('Scroll Top on page change');
        window.scrollTo(0, 0)
    }

    _handlePlaceSelected = place => {
        
        this.setState({place}, () => {
            this.props.setPlace(place)
            this.props.setOptions(null)
        })
        this._handleSearch(true);
        this.pageClick = 'Search';
    }

    _handlePrintPage = () => window.print()

    _handleSearch = (track = true) => {
        const {shops: {filters, location: userLocation, options, place}} = this.props
        const {distance} = this.state
        const {lat, lng} = this.state
        const searchFields = {}
        let center = {lat, lon: lng}

        if (userLocation) {
            center = {lat: userLocation.latitude, lon: userLocation.longitude}
            this.setState({lat: center.lat, lng: center.lon})
        }

        if (place) {
            if(place.address_components !== undefined)
            {
                const {geometry: {location: {lat, lng}}} = place
                center = {lat: lat(), lon: lng()}
                this.setState({lat: center.lat, lng: center.lon})
                // if (place.formatted_address) {
                //     tealiumLink({
                //         [TEALIUM.shopSearch] : place.formatted_address
                //     })
                // }
            }
        }

        if (center.lat && center.lon) {
            searchFields["shopAddressObject.geoLocation"] = {
                "_geoDistance": {
                    "center": {"lat": center.lat, "lon": center.lon},
                    "distance": distance,
                    "unit": "mi"
                }
            }
        }
        

        if (filters && filters.length > 0) {
            this.pageClick = 'applyFilters';
            filters.forEach(filter => {
                switch (filter.section) {
                    case "certifications":
                        if (!searchFields["shopCertificationsArray"]) searchFields["shopCertificationsArray"] = {"_any": []}
                        searchFields["shopCertificationsArray"]["_any"].push(filter.value)
                        break
                    case "servicesPerformed":
                        if (!searchFields["servicesPerformedArray"]) searchFields["servicesPerformedArray"] = {"_any": []}
                        searchFields["servicesPerformedArray"]["_any"].push(filter.value)
                        break
                    case "shopType":
                        if (!searchFields["regulatoryServicesArray"]) searchFields["regulatoryServicesArray"] = {"_any": []}
                        searchFields["regulatoryServicesArray"]["_any"].push(filter.value)
                        break
                    case "vehicleServiced":
                        if (!searchFields["vehiclesServicesArray"]) searchFields["vehiclesServicesArray"] = {"_any": []}
                        searchFields["vehiclesServicesArray"]["_any"].push(filter.value)
                        break
                    case "language":
                        if (!searchFields["shopLanguagesArray"]) searchFields["shopLanguagesArray"] = {"_any": []}
                        searchFields["shopLanguagesArray"]["_any"].push(filter.value)
                        break
                    case "nationwide":
                        searchFields["isProvantageCustomerFlag"] = "true"
                        break
                    default:
                        break
                }
            })
        }
       
        this.props.searchShops(options, searchFields, track)
    }

    _toggleModal = (applyFilters = false) => {
        const {showFiltersModal} = this.state
        const newValue = !showFiltersModal
        this.setState({showFiltersModal: newValue})
        this.props.setModalState(newValue)
        if (!applyFilters && !newValue) this.props.removeAllFilters()
        else if (!newValue) this._handleSearch()
    }

    _renderResults(){
        const {shops: {originalRequest, profiles, rows}} = this.props
        const {start, end} = this._getPaginationData(originalRequest, rows)
        return(
            <>
                <Row id="results-container">
                    <Col sm={12} md={12}>
                        <div className="srcheader">
                            <div>Sl.N</div>
                            <div>{Localization.print("Store Name & Contact Information")} </div>
                            <div>{Localization.print("Store Hours")}</div>
                            <div>{Localization.print("Miles Away")}</div>
                        </div>
                        {profiles && profiles.map((profile, i) => (
                                <SearchResult
                                        history={this.props.history}
                                        key={i}
                                        profile={profile}
                                        res={(i + start)}
                                        onDirections={this._handleItemActions}
                                        onPrint={this._handleItemActions}
                                        distance={this.state.selectOptionLabel}
                                />
                        )) }
                    </Col>
                </Row>
                {originalRequest && (
                    <Row>
                        <Col sm={12} md={12}>
                            <Pagination
                                    max={originalRequest.resultsLinesPerPage}
                                    onPageChange={this._handlePagination}
                                    page={originalRequest.requestedPageNumber}
                                    rows={rows}
                            />
                        </Col>
                    </Row>
                )}
            </>
        )
    }

    _handleRadiusChange = selectedOption => {  
        this.props.setOptions({requestedPageNumber: 1})   
        this.setState({ selectedOption, distance: parseInt(selectedOption.value), selectOptionLabel:parseInt(selectedOption.label)}, () => this._handleSearch())
        this.pageClick = `Showing Miles: ${parseInt(selectedOption.value)}`
    };

    render() {
        const {global:{locale}, shops: {filters, loading, originalRequest, place, profiles, rows}} = this.props
        const {pan, showFiltersModal, showEmailModal, selectedOption} = this.state
        const {start, end} = this._getPaginationData(originalRequest, rows)
        const currentSearch = (place && place.formatted_address) ? place.formatted_address : null
        const options = [
            { label: '1', value: locale === 'es' ? miles(1) : '1' },
            { label: '3', value: locale === 'es' ? miles(3) : '3' },
            { label: '5', value: locale === 'es' ? miles(5) : '5' },
            { label: '10', value: locale === 'es' ? miles(10) : '10' },
            { label: '15', value: locale === 'es' ? miles(15) : '15' },
            { label: '25', value: locale === 'es' ? miles(25) : '25' },
            { label: '30', value: locale === 'es' ? miles(30) : '30' },
            { label: '40', value: locale === 'es' ? miles(40) : '40' },
            { label: '50', value: locale === 'es' ? miles(50) : '50' }
        ] 
        const homeURL = (locale === 'us') ? 'https://www.autozone.com' : 'https://www.autozone.com.mx';  

        return  (
            <div className="findshop">
                <Helmet>
                    <title>{Localization.print("Find a repair shop near you")}</title>
                    <link rel="canonical" href="https://shop-referral.autozonepro.com/az/findShop/" /> 
                </Helmet>
                <Row>
                    <Col sm={12} md={12}>
                        <p className="breadcrumb">
                            <a href={homeURL} target={'_parent'}>{Localization.print("Home")}</a> <span>/</span> <a href="javascript:;" className="active">{Localization.print("Find a repair shop near you")}</a>
                        </p>
                    </Col>
                </Row>               
                <Row>
                    <Col sm={12} md={12}>
                        <h1 className="hdr-title">
                            <span className={`title ${locale}`}>{Localization.print("FIND A REPAIR SHOP NEAR YOU")}</span>
                            <span className="print-logo"><img src={Printlogo} /></span>
                        </h1>
                        {(place && place.formatted_address) && (<span className="print-address" >
                            {Localization.print("Address")}: {place.formatted_address}
                        </span>)}
                        <p className="para">
                            {locale === 'us'
                                    ? "We know there are some jobs you may want a professional mechanic to perform. AutoZone can help you with that, too. We'll gladly refer you to a qualified professional you can trust, through our Shop Referral Program. Because parts are just part of what we do."
                                    : "Sabemos que hay algunos trabajos que prefieres encargar a un taller mecánico. Con mucho gusto te referiremos a un profesional calificado, a través de nuestro Programa de Talleres Referidos. Porque las autopartes son sólo una parte de lo que hacemos."
                            }
                        </p>
                    </Col>
                </Row>
                <div className="wrap">
                    <div className="search">
                        <PredictiveSearch
                                onPlaceSelected={this._handlePlaceSelected}
                                placeholder={Localization.print("Enter Address or City and State or Zipcode")}
                                ref={'predictiveSearch'}
                                value={currentSearch}
                                
                        />
                    </div>
                    <div style={{flex: 1}} />
                    <div className="miles-radius">
                       <div className="select-box">
                            <span>{Localization.print("Showing")}</span>
                            <Select
                                width='54px'
                                height='30px'                                                               
                                placeholder={null}                               
                                className="mileage-radius"
                                classNamePrefix="radiusDropDown"
                                onChange={this._handleRadiusChange}                                                              
                                options={options}
                                value={selectedOption}                               
                                isSearchable={false}
                                components={{ IndicatorSeparator: () => null }}
                                theme={(theme) => ({
                                  ...theme,
                                
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#f2f2f2",
                                        primary: "#f2f2f2",
                                    }                         
                                })} 
                                styles={customStyles}
                            />
                            <span>{Localization.print("Miles")}</span>
                       </div> 
                       <div className="tools">
                            <button type="submit" className="printButton" onClick={this._handlePrintPage} />
                        </div>
                    </div>
                    
                </div>
                <Row id="map-container">
                    <Col sm={12} md={12}>                        
                        <Map lat={this.state.lat} lng={this.state.lng} markers={profiles.map((profile, i) => ({
                            label: (i + start).toString(),
                            lat: parseFloat(profile.shopAddressObject.geoLocation.lat),
                            lng: parseFloat(profile.shopAddressObject.geoLocation.lon),
                            name: profile.shopName
                        }))} pan={pan} />
                        <div class="banner">
                          <div class={locale === "us" ? "firestone-pencil" : "fix-finder-pencil"}>
                            <a
                              href={locale === "us" ? firestoneURL : fixFinderURL}
                              target="_blank"
                              rel="noreferrer"
                            />
                          </div>
                        </div>
                        <div className="src-header">
                            <div className="src-results">
                                {originalRequest ? (
                                        <div><span>{start}</span>-<span>{end}</span> {Localization.print("of")} <span>{rows}</span> {Localization.print("Results")}</div>
                                ) : <div><span>0</span> {Localization.print("Results")}</div>}
                                <Hidden xs>
                                    {filters.length > 0 && (
                                            <div className="filters-wrapper">
                                                <FiltersList filters={filters} onItemRemove={this._handleFilterRemove} />
                                            </div>
                                    )}
                                </Hidden>
                            </div>
                            <p className="src-tools">
                                <button className="filterButton" onClick={this._toggleModal.bind(this, false)} />
                                <button className="filterLink" onClick={this._toggleModal.bind(this, false)}>
                                    {Localization.print("Filter")}{(filters.length > 0) && ` (${filters.length})`}
                                </button>
                            </p>
                            <Visible xs>
                                {filters.length > 0 && (
                                        <div className="filters-wrapper">
                                            <FiltersList filters={filters} onItemRemove={this._handleFilterRemove} />
                                        </div>
                                )}
                            </Visible>
                        </div>
                    </Col>
                </Row>

                {loading && (<div style={{height: 400}} />)}

                {(profiles.length > 0 || loading) ? this._renderResults() : <NoResultsFound />}

                <DisclaimerText locale={locale}/>

                {showFiltersModal && (
                        <BlurPortal>
                            <FiltersModal onClose={this._toggleModal.bind(this, false)} onConfirm={this._toggleModal.bind(this, true)} />
                        </BlurPortal>
                )}

                {showEmailModal && (
                        <BlurPortal>
                            <EmailModal onClose={this._toggleModal.bind(this, false)} onConfirm={this._toggleModal.bind(this, true)} />
                        </BlurPortal>
                )}
            </div>                
        )
    }
}

export default connect(
        ({global, shops}) => ({global, shops}),
        {removeAllFilters, removeFilter, setModalState, searchShops, setLocation, setOptions, setPlace, setShop, setShopAction}
)(FindShop)

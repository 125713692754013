import React from 'react'
import {Row, Col, ScreenClassRender} from 'react-grid-system';
import StepWrapper from '../../body/StepWrapper'
import BodyHeader from '../body-header'
import './stepOne.scss'
import InfoProfile from './InfoProfile'
import {connect} from 'react-redux'
import {
    setOneLanguageUpdateValue,
    setOneLanguageeSwitchAll,
    setOneChangeTimeZone,
    setProfileData, setOneError, setOneClearError, setProfileProperty
} from '../../../redux/actions'
import ButtonAutoZone from '../../suite/button-autozone'
import DropDown from '../../suite/dropDown'
import CheckboxGroup from '../../suite/checkBoxGroup'
import {errorTypes, HOUR_SECTION, systemErrors} from '../../../utilities/constants'
import Week from './week'
import Box from '../../suite/box'
import EditProfile from "./EditProfile";
import colors from "../../../utilities/colors";
import {isIframe, isValidProperty, scrollIframeToViewPort} from "../../../utilities/functions";
import {getProfileInfo} from "../../../redux/actions/profile";
import {setComponent} from "../../../redux/actions/global";
import ErrorMessage from "../../suite/error-message";
import Localization from "../../../utilities/localization";


class StepOne extends React.Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props)
        this.state = {
            languageSelected: "",
            timeZoneSelected: "",
            editingProfile: true,
            tempProfileData: props.stepData.profileInfo
        }

        this.timeZoneDropDown = React.createRef();
    }
    

    componentDidMount() {
        const {stepData} = this.props
        if (isIframe()) {
            scrollIframeToViewPort('.Stepper')
        } else {
            window.scrollTo(0,0)
        }

        this.setLanguageLocally()

        let initialTimeZone = stepData[HOUR_SECTION.timeZone].find(t => t.selected === true)
        if (initialTimeZone !== undefined)
            this.setState({timeZoneSelected: initialTimeZone})

        this.getProfileInfo()
        let name = 'Registration: Shop information and Hours'
        this.props.setComponent(name)
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.stepData[HOUR_SECTION.language]) !== JSON.stringify(prevProps.stepData[HOUR_SECTION.language]))
            this.setLanguageLocally()


        if (JSON.stringify(this.props.stepData[HOUR_SECTION.timeZone]) !== JSON.stringify(prevProps.stepData[HOUR_SECTION.timeZone])) {
            let timeZoneSelected = this.props.stepData[HOUR_SECTION.timeZone].find(t => t.selected === true)
            this.setState({timeZoneSelected})
        }

    }

    /**
     * Restores the original values that were saved before entering
     * editing mode and clears all existent errors
     */
    cancelEdition = () => {
        const {setOneClearError, setProfileData} = this.props
        const {tempProfileData} = this.state
        if (!tempProfileData.filled) {
            this.props.history.goBack()
            return
        }
        this.setState({editingProfile: false, tempProfileData: null}, () => {
            setOneClearError(true)
            setProfileData(tempProfileData)
        })
    }

    /**
     * Opens editing mode and saves the previous values
     * for profile info to restore it in case the user
     * cancels the operation
     */
    editProfile = () => {
        const {stepData: {profileInfo}} = this.props
        this.setState({editingProfile: true, tempProfileData: profileInfo})
    }

    isEnabled = () => {
        const {stepData: {profileInfo,errors}} = this.props
       
        if(profileInfo.shopAddress.trim() !== ""  && profileInfo.shopContactEmail.trim() !== "" && profileInfo.shopName.trim() !== "" && profileInfo.city.trim() !== "" && profileInfo.state !== "" && profileInfo.shopPhoneNumber.trim() !== "" && profileInfo.shopContactName.trim() !== ""
        && profileInfo.zipCode.trim() !== "" && Object.keys(errors).length <= 0) 
          return false;
        else
          return true;
    }

    /**
     * Return Language dropdown width according to device size
     * @param screenClass
     * @returns {string|number}
     */
    getLanguageDropDownWidth (screenClass) {
        switch (screenClass) {
            case "xl": return 368;
            case "lg": return 304;
            default: return null
        }
    }

    getProfileInfo () {
        const {profile: {loading}} = this.props
        const user = JSON.parse(sessionStorage.getItem('user'))
        if (user && user.hasOwnProperty('currentpin') && !loading) this.props.getProfileInfo(user.currentpin)
    }

    /**
     * Returns Profile button width according to device size
     * @param screenClass
     * @returns {null|number}
     */
    getProfileButtonWidth (screenClass) {
        switch (screenClass) {
            case "xl": return 168
            case "lg":
            case "md": return 136
            default: return null
        }
    }

    getProfileCancelButtonWidth (screenClass) {
        switch (screenClass) {
            case "xl":
            case "lg": return 84;
            default: return null
        }
    }

    getProfileSaveButtonWidth (screenClass) {
        switch (screenClass) {
            case "xl": return 168
            case "lg": return 136
            default: return null
        }
    }

    handleLanguageSwitchAll = (data) => {
        this.props.setOneLanguageeSwitchAll(data)
    }

    /**
     * Switches the editing mode. All the values should be already
     * updated in redux.
     */
    handleSaveProfile = () => {
        if (!this.validate()) return
        this.setState({editingProfile: false, tempProfileData: null}, () => this.props.setProfileProperty('filled', true))
    }

    handleSetLanguageValue = (data) => {
        this.props.setOneLanguageUpdateValue(data)
    }

    handleTimeZone = (id) => {
        this.props.setOneChangeTimeZone(id)
        this.timeZoneDropDown.current.close();
    }

    setLanguageLocally() {
        const {locale} = this.props
        let languageSelected = this.props.stepData[HOUR_SECTION.language].items.filter(el => {
            if (locale === 'es') return !!el.es && el.checked
            return el.checked === true
        }).map(el => {
            if (locale === 'es') el.text = el.es
            return el
        })
        this.setState({languageSelected})
    }

    /**
     * Iterates over all the requiredFields array defined in redux
     * and validates that fields are not empty and set the corresponding
     * error for the ones that are empty.
     * @returns {boolean}
     */
    validate (info = null) {
        const {stepData: {profileInfo, requiredFields, errors}, setOneClearError, setOneError} = this.props
        let isValid = true
        if (!info) info = profileInfo
       
        requiredFields.forEach(key => {
            if (!isValidProperty(info[key])) {
                setOneError(key, errorTypes.required)
                isValid = false
            } else if (errors[key] && errors[key] === errorTypes.required) {
                setOneClearError(false, key)
            }
        })

        if (Object.keys(errors).length > 0) {
            isValid = false
        }

        return isValid
    }

    render() {
        const {languageSelected, timeZoneSelected, editingProfile} = this.state
        const {systemError} = this.props
        const error = systemErrors.find(item => item.key === systemError)

        return (
                <ScreenClassRender render={screenClass => (
                    <React.Fragment>
                        <div className="body-header" >
                            <BodyHeader/>
                        </div>
                        {!!systemError && (<ErrorMessage messageText={error.message} />)}
                        {!systemError && (
                                <StepWrapper screenClass={screenClass}>
                                    <Row>
                                        <Col>
                                            <Box width='100%' backgroundColor='#6c6f70' color='#ffffff'
                                                 title={Localization.print("shop information and hours")}
                                                 hint={`* ${Localization.print("Required fields")}`}>
                                                {!editingProfile && (
                                                        <>
                                                            <InfoProfile />
                                                            <div style={{height: 16}} />
                                                        </>
                                                )}
                                                {editingProfile && <EditProfile />}
                                            </Box>
                                        </Col>
                                    </Row>

                                    <div className="informationButtonContainer">
                                        <Row>
                                            <Col lg={4} offset={{ lg: 8 }} >
                                                {!editingProfile && (
                                                        <ButtonAutoZone
                                                                id={'edit-profile'}
                                                                text={Localization.print("EDIT SHOP PROFILE")}
                                                                width={this.getProfileButtonWidth(screenClass)}
                                                                height='28px'
                                                                borderColor={colors.autozoneOrangeColor}
                                                                backgroundColor='white'
                                                                color={colors.autozoneOrangeColor}
                                                                handler={this.editProfile}
                                                        />
                                                )}
                                                {editingProfile && (
                                                        <React.Fragment>
                                                            <ButtonAutoZone
                                                                    id={'profile-cancel'}
                                                                    handler={this.cancelEdition}
                                                                    text={Localization.print("CANCEL")}
                                                                    backgroundColor={'white'}
                                                                    borderColor={colors.autozoneBlackColor}
                                                                    color={colors.autozoneBlackColor}
                                                                    height={'28px'}
                                                                    width={this.getProfileCancelButtonWidth(screenClass)}
                                                                    
                                                            />
                                                            <ButtonAutoZone
                                                                    id={'profile-save'}
                                                                    handler={this.handleSaveProfile}
                                                                    text={Localization.print("SAVE SHOP PROFILE")}
                                                                    backgroundColor={'white'}
                                                                    borderColor={colors.autozoneOrangeColor}
                                                                    color={colors.autozoneOrangeColor}
                                                                    width={this.getProfileSaveButtonWidth(screenClass)}
                                                                    disabled={this.isEnabled()}
                                                                    opacity={this.isEnabled() ? 0.3 : 1}
                                                                   
                                                            />
                                                        </React.Fragment>
                                                )}
                                            </Col>

                                        </Row>
                                    </div>

                                    <div className="detailsBox">
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <fieldset>
                                                    <legend>{Localization.print("Please check all languages spoken at your shop")}  </legend>

                                                    <DropDown
                                                            labelText={Localization.print("Select")}
                                                            width={this.getLanguageDropDownWidth(screenClass)}
                                                            selected={languageSelected}
                                                            ref={dropDown => { this.languageDropDown = dropDown }}
                                                            multiple
                                                            items={this.props.stepData[HOUR_SECTION.language].items}
                                                            handler={this.handleSetLanguageValue}
                                                    >
                                                        <div style={{padding: '16px 12px 0 12px'}}>
                                                            <CheckboxGroup
                                                                    checkAllLegend={Localization.print("Select All")}
                                                                    section={HOUR_SECTION.language}
                                                                    data={this.props.stepData}
                                                                    handleSetValue={this.handleSetLanguageValue}
                                                                    handleSwitchAll={this.handleLanguageSwitchAll}
                                                                    locale={this.props.locale}
                                                            />
                                                        </div>
                                                    </DropDown>
                                                </fieldset>

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <fieldset>
                                                    <legend>{Localization.print("Please check the days and the hours that you are open. Unselected days will be marked as closed")}</legend>
                                                    <Week/>
                                                </fieldset>
                                            </Col>
                                        </Row>
                                    </div>

                                </StepWrapper>
                        )}
                    </React.Fragment>    
                )}/>
        )
    }
}


const mapStateToProps = state => ({
    locale: state.global.locale,
    profile: state.profile,
    stepData: state.steps.stepOne,
    systemError: state.steps.systemError
})

const mapDispatchToProps = dispatch => {
    return {
        getProfileInfo: profileID => dispatch(getProfileInfo(profileID)),
        setOneLanguageUpdateValue: data => dispatch(setOneLanguageUpdateValue(data)),
        setOneLanguageeSwitchAll: data => dispatch(setOneLanguageeSwitchAll(data)),
        setOneChangeTimeZone: data => dispatch(setOneChangeTimeZone(data)),
        setProfileData: data => dispatch(setProfileData(data)),
        setProfileProperty: (key, value) => dispatch(setProfileProperty(key, value)),
        setOneClearError: (all, key) => dispatch(setOneClearError(all, key)),
        setOneError: (key, type) => dispatch(setOneError(key, type)),
        setComponent: name =>dispatch(setComponent(name))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepOne)


import React from 'react'
import PropTypes from 'prop-types'
import {Visible} from 'react-grid-system'
import './stepper.scss'
import {isMobile} from "../../../utilities/functions";

export default class Stepper extends React.PureComponent {
    render () {
        const {currentStep, fullCover, steps} = this.props
        const _isMobile = isMobile(window.navigator.userAgent)

        return (
                <section className={`Stepper`}>
                    <div className="content">
                        {steps.map((item, i) => {
                            const itemClass = ['item']
                            const index = i+1
                            if (index < currentStep || fullCover) itemClass.push('completed')
                            else if (index === currentStep) itemClass.push('current')
                            return (
                                    <div className={itemClass.join(' ')} key={i} onClick={item.onClick}>
                                        <div className={'mark' + (_isMobile ? ' mobile' : '')} />
                                        <Visible xl lg md>
                                            <React.Fragment>
                                                {!!item.line1 && (<span className="title">{item.line1}</span>)}
                                                {!!item.line2 && (<span className="title">{item.line2}</span>)}
                                            </React.Fragment>
                                        </Visible>
                                    </div>
                            )
                        })}
                    </div>
                </section>
        )
    }
}

Stepper.defaultProps = {
    currentStep: 1,
    fullCover: false
}

Stepper.propTypes = {
    currentStep: PropTypes.number,
    fullCover: PropTypes.bool,
    steps: PropTypes.arrayOf(PropTypes.shape({
        line1: PropTypes.string,
        line2: PropTypes.string
    })).isRequired
}

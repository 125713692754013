import React, {useEffect} from 'react'
import {Route} from "react-router-dom";
import HomePage from "../../body/homePage";
import {readCookie} from "../../../redux/actions/cookie";
import LandingPage from "../../body/LandingPage";
import Spinner from "../../suite/spinner";
import qs from 'querystring'
import {addToken, getTokenInfo, requestToken} from "../../../redux/actions/token";
import {connect} from "react-redux";
import {getOauthLoginURL} from "../../../utilities/auth";
import {isIframe} from "../../../utilities/functions";
let timer = null

function getLocalToken () {
    const url = getOauthLoginURL('code')
    window.location.replace(url)
}

function getParentToken (props) {
    if (!window.parentIFrame) {
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => getParentToken(props), 300)
        return
    }

    window.addEventListener('message', (evt) => {
        let data
        if (evt.data.includes("[iFrameSizer]message:")) {
            data = JSON.parse(evt.data.replace("[iFrameSizer]message:",""))
            if (data.message && data.message === 'token' && data.token) {
                const token = {access_token: data.token}
                if (sessionStorage.token) {
                    if (JSON.parse(sessionStorage.getItem('token')).access_token !== token) {
                        sessionStorage.setItem('token', JSON.stringify({access_token: data.token}))
                    }
                } else {
                    sessionStorage.setItem('token', JSON.stringify({access_token: data.token}))
                }
                props.addToken(token)
                props.getTokenInfo()
            }
        }
    })

    window.parentIFrame.sendMessage({message: 'token'}, '*')

    // setTimeout(() => {
    //     if (!sessionStorage.token) getLocalToken()
    // }, 500)
}

const HomeRoute = (props) => {
    const urlParams =  qs.parse(window.location.search.replace('?', ''))
    const cookie = readCookie('JSESSIONID')

    useEffect(() => {
        if (urlParams && urlParams.code && !sessionStorage.requestingToken) {
            sessionStorage.setItem('code', urlParams.code)
            sessionStorage.setItem('requestingToken', 'true')
            props.requestToken(props.history)
        } else if (isIframe()) {
                getParentToken(props)
            } else if (!sessionStorage.token) {
                getLocalToken()

        }
    }, [])

    /*Added condition to avoid redirect - On Initial load when there is no token or cookie,  
    **LandingPage loads first and then screen redirects to HomePage for existing user.
    **Props - tokenRequestLoaded & tokenInfoLoaded, checks if both requests are completed
    **Cookie and token - case when home page loads on click of "Previous" button
    */
    if(props.tokenRequestLoaded || props.tokenInfoLoaded || cookie || sessionStorage.token) {
        return (
                <Route
                        {...props}
                        render={_props => {
                            if (cookie || sessionStorage.token)
                                return (<HomePage {..._props} />)
                            else
                                return (<LandingPage {..._props} />)
                        }}
                />
        );
    }

    return (
        <div className="spinnerContainer">
            <Spinner />
        </div>
    );
}

const mapStateToProps = state => ({
  tokenRequestLoaded: state.token.tokenRequestLoaded,
  tokenInfoLoaded: state.token.tokenInfoLoaded,
});

export default connect(mapStateToProps, {addToken, getTokenInfo, requestToken})(HomeRoute)

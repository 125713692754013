import React from 'react'
import {capitalize, getDayNumber, getFormattedHour, isClosedDay, todayStr} from "../../../utilities/functions";
import Localization from "../../../utilities/localization";

const DaysHoursTable = ({days}) => {
    let _days = days
            .sort((a, b) => {
                const dayA = getDayNumber(a.dayOfWeekName)
                const dayB = getDayNumber(b.dayOfWeekName)
                if (dayA < dayB) return -1
                if (dayA > dayB) return 1
                return 0
            })

    let _week = Array.from({length: 7}, (_, i) => i).reduce((arr, index) => {
        if (_days[index] && index === getDayNumber(_days[index].dayOfWeekName)) {
            arr.push(_days[index])
        } else {
            arr.push({
                dayOfWeekName: todayStr(index).toUpperCase(),
                openTimeValue: "0:0",
                closeTimeValue: "0:0"
            })
        }
        return arr
    }, [])

    return _week.map((day, i) => (
            <span key={i} className={[
                "table-row",
                (capitalize(day.dayOfWeekName) === todayStr() ? "table-head" : "")
            ].join(' ')}>
                        <span className="table-cell">{Localization.print(capitalize(day.dayOfWeekName))}</span>
                {isClosedDay(day.openTimeValue, day.closeTimeValue)
                        ? <span className="table-cell">{Localization.print("Closed")}</span>
                        : (<span className="table-cell">
                                    {getFormattedHour(day.openTimeValue)} - {getFormattedHour(day.closeTimeValue)}
                            </span>)}
                    </span>
    ))
}

export default DaysHoursTable

import {
    couponTypes,  
    validationTypes,
    states } from "./constants"
import store from "../redux/store";
import Localization from "./localization";
import qs from "querystring";

// eslint-disable-next-line no-control-regex,no-useless-escape
export const EMAIL_PATTERN = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
export const URL_PATTERN = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i
export const ALPHANUMERIC_PATTERN = /^[A-Za-z0-9\s.]+$/
export const INVALID_CHARS_PATTERN = /[~!@$^*+=(){}[\]<>"&%'#:|`´\\;]/g
export const INVALID_NUMBERS_PATTERN = /[-._~!@$^*+=(){}[\]<>"&%'#:|`´\\\/;a-z]/
export const ONLY_LETTERS_PATTERN = /^[a-zA-Z\s,.]+$/

export function capitalize (str) {
    return str.split(' ').map(word => word.split('').map((ltr, i) => (i === 0) ? ltr.toUpperCase() : ltr.toLowerCase()).join('')).join(' ')
}

export function getDayNumber (day) {
    switch (day.toLowerCase()) {
        default:
        case "mon":
        case "monday":
            return 0
        case "tue":
        case "tuesday":
            return 1
        case "wed":
        case "wednesday":
            return 2
        case "thu":
        case "thursday":
            return 3
        case "fri":
        case "friday":
            return 4
        case "sat":
        case "saturday":
            return 5
        case "sun":
        case "sunday":
            return 6
    }
}

export function getFormattedHour (str, militar = false) {
    const hourParts = str.split(':').map(str => parseInt(str))

    if (hourParts.length !== 2) return str

    let [hour, mins] = hourParts
    const sufix = militar ? '' : (hour >= 12 ? ' pm' : ' am')
    mins = zeroFill(mins)
    if (hour > 12 && !militar) hour -= 12

    return `${hour}:${mins}${sufix}`
}

export function getFormattedDate (dateStr, locale) {
   
    if (dateStr === null) {
        return dateStr;
    }
    const parts = dateStr.split(/-|\//)
    let formattedDate, date, month, year

    if (parts.length !== 3) return dateStr

    if (parts[0].length === 4) {
        [year, month, date] = parts
    } else if (parts[2].length === 4) {
        [month, date, year] = parts
    } else {
        return dateStr
    }

    formattedDate = locale === 'es' ? `${date}/${month}/${year}` : `${month}/${date}/${year}`

    return formattedDate
}

export function getCertificationTitle (value) {
    const state = require('../redux/store').default.getState()
    const certificate = state.steps.stepTwo.certifications.items.find(item => item.value === value)
    return certificate
            ? (state.global.locale === 'es' ? certificate.es : certificate.text)
            : value
}

export function getClosingHour (weekArray) {
    //const locale = sessionStorage.getItem(sessionStorageKey.LOCALE) || 'us'
    const now = new Date()
    const today = todayStr(now.getDay() - 1)
    const currentDay = weekArray.find(day => (day.dayOfWeekName.toLowerCase() === today.toLowerCase()))

    if (!currentDay) return ""
    const {openTimeValue, closeTimeValue} = currentDay
    const [openHour] = openTimeValue.split(':')
    const [closeHour] = closeTimeValue.split(':')
    if (now.getHours() < parseInt(openHour) || now.getHours() > parseInt(closeHour) || isClosedDay(openTimeValue, closeTimeValue))
        return Localization.print("Closed")
    if (parseInt(closeHour) > 12) {
        return `${Localization.print("OPEN until")} ${(parseInt(closeHour) - 12)} PM`
    } else {
        return `${Localization.print("OPEN until")} ${closeHour} AM`
    }
}

export function getCouponTitle (coupon) {
    switch (coupon.type) {
        case couponTypes.free:
            return Localization.print("FREE")
        case couponTypes.special:
            return Localization.print("SPECIAL")
        case couponTypes.percent:
            return `${coupon.percentOff}% ${Localization.print("OFF")}`
        case couponTypes.dollar:
            return `$${coupon.dollarOff} ${Localization.print("OFF")}`
        default:
            return ''
    }
}

export function getCouponTypeTitle (type) {
    const {steps: {stepFour: {couponTypes: {items: types}}}} = store.getState()
    const typeObj = types.find(item => item.value === type)
    return typeObj ? typeObj.text : 'Type'
}

export function getGoogleDirectionsLink (lat, lng) {
    return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}&travelmode=driving`
}

export function getLanguageTitle (value) {
    const state = require('../redux/store').default.getState()
    const language = state.steps.stepOne.language.items.find(item => item.value === value)
    return language
            ? (state.global.locale === 'es' ? language.es : language.text)
            : value
}
export function getLocale () {
    let locale = 'us'

    if (window.location.search) {
        const params = qs.parse(window.location.search.replace(/[\?|\/]/g, ''))
        if (undefined !== params.v && (params.v === 'mx' || params.v === 'es')) {
            locale = 'es'
        }
    }

    return locale
}
export function getStateAbbreviationForDisplay (stateId, locale) {
    let stateAbbreviation = '';
    const selectedState =  states(locale).filter(item => (item.id === stateId))

    if (selectedState.length === 1) 
        stateAbbreviation = selectedState[0].abbreviation;

    return stateAbbreviation;
    
}

export function getLocalizedRegulatoryServicesArray (arr) {
    const state = require('../redux/store').default.getState()
    const {global: {locale}, steps: {stepThree: {shopType: {items: regulatoryServices}}}} = state
    const property = locale === 'es' ? 'es' : 'text'
    return regulatoryServices.filter(item => {
        return !!item[property] && arr.includes(item.value)
    }).map(item => item.value)
}

export function getLocalizedServicesPerformedArray (arr) {
    const state = require('../redux/store').default.getState()
    const {global: {locale}, steps: {stepThree: {servicesPerformed: {items: servicesPerformed}}}} = state
    const property = locale === 'es' ? 'es' : 'text'
    return servicesPerformed.filter(item => {
        return !!item[property] && arr.includes(item.value)
    }).map(item => item.value)
}

export function getLocalizedShopCertificationsArray (arr) {
    const state = require('../redux/store').default.getState()
    const {global: {locale}, steps: {stepTwo: {certifications: {items: certifications}}}} = state
    const property = locale === 'es' ? 'es' : 'text'
    return certifications.filter(item => {
        return !!item[property] && arr.includes(item.value)
    }).map(item => item.value)
}

export function getLocalizedShopLanguagesArray (arr) {
    const state = require('../redux/store').default.getState()
    const {global: {locale}, steps: {stepOne: {language: {items: language}}}} = state
    const property = locale === 'es' ? 'es' : 'text'
    return language.filter(item => {
        return !!item[property] && arr.includes(item.value)
    }).map(item => item.value)
}
export function getLocalizedVehiclesServicesArray (arr) {
    const state = require('../redux/store').default.getState()
    const {global: {locale}, steps: {stepThree: {vehicleServiced: {items: vehicleServiced}}}} = state
    const property = locale === 'es' ? 'es' : 'text'
    return vehicleServiced.filter(item => {
        return !!item[property] && arr.includes(item.value)
    }).map(item => item.value)
}

export function getRegulatoryServiceTitle (value) {
    const state = require('../redux/store').default.getState()
    const service = state.steps.stepThree.shopType.items.find(item => item.value === value)
    return service
            ? (state.global.locale === 'es' ? service.es : service.text)
            : value
}

export function getServicePerformedTitle (value) {
    const state = require('../redux/store').default.getState()
    const service = state.steps.stepThree.servicesPerformed.items.find(item => item.value === value)
    return service
            ? (state.global.locale === 'es' ? service.es : service.text)
            : value
}

export function getVehicleServicedTitle (value) {
    const state = require('../redux/store').default.getState()
    const vehicle = state.steps.stepThree.vehicleServiced.items.find(item => item.value === value)
    return vehicle
            ? (state.global.locale === 'es' ? vehicle.es : vehicle.text)
            : value
}

export function zeroFill (number) {
    const n = (typeof number === "string") ? parseInt(number) : number
    return n >= 10 ? n.toString() : `0${n}`
}

export function getInvalidCharacters (data  ) {
    return data.match(INVALID_CHARS_PATTERN)
}

export function getComponentsFromStrHour (str) {
    const [timeStr, period] =  str.toLowerCase().split(' ')
    const [hourStr, minStr] = timeStr.split(':')
    return {
        hour: parseInt(hourStr) + (period === 'pm' ? 12 : 0),
        minute: parseInt(minStr)
    }
}

export function getShortTimeZone (longTimeZone) {
    return longTimeZone.split(' ').map(word => word.substr(0, 1)).join('')
}

export function getStepPath (number) {
    switch (number) {
        default:
        case 1:
            return 'StepOne'
        case 2:
            return 'StepTwo'
        case 3:
            return 'StepThree'
        case 4:
            return 'StepFour'
        case 5:
            return 'StepFinal'
    }
}

export function getStringHour ({hour, minute}) {
    let _period = hour > 12 ? 'PM' : 'AM'
    let _hour = hour > 12 ? hour - 12 : hour
    let _minute = minute < 10 ? `0${minute}` : minute
    _hour = _hour < 10 ? `0${_hour}` : _hour
    return `${_hour}:${_minute} ${_period}`
}

export function getTealiumSection () {
    const hash = window.location.hash.replace('#/', '')
    const path = hash.split('/').slice(-2)[0]
    const origin = (path && path === 'az') ? 'az' : 'azpro'
    return `${origin}: Shop referral`
}

export function isAZPage () {
    const pathname = window.location.pathname;
    if (pathname.indexOf('findShop') > -1) {
        return true;
    }
    if (!window.location.hash) return false
    const hashParts = window.location.hash.toLowerCase().replace('#/', '').split('/')
    return hashParts[0] === 'az'
}

export function isClosedDay (openStr, closeStr) {
    if (openStr !== closeStr) return false
    return (parseInt(openStr.replace(':', '')) === 0 && parseInt(closeStr.replace(':', '')) === 0)
}

export function isIframe () {
    return window.location !== window.parent.location
}

export function isInvalidCharacter (data) {
    return INVALID_CHARS_PATTERN.test(data)
}

export function isMobile (userAgent) {
    return (new RegExp('Android|iPhone|iPad', 'gi')).test(userAgent)
}

export function isValid (type, data) {
    switch (type) {
        case validationTypes.url:
            return URL_PATTERN.test(data)
        case validationTypes.number:
            return !INVALID_NUMBERS_PATTERN.test(data.toString())
        case validationTypes.email:
            return EMAIL_PATTERN.test(data)
        case validationTypes.textOnly:
            return ONLY_LETTERS_PATTERN.test(data)
        case validationTypes.text:
        default:
            return ALPHANUMERIC_PATTERN.test(data)
    }
}

export function isValidProperty(data) {
    if (!data) return false
    if (typeof data === 'number') return data >= 0;
    if (typeof data === 'string') return data.length > 0
}

export function limitToWordsAndLength (data, words, length) {
    if (typeof data !== 'string') return data
    const parts = data.split(' ')
    if (parts.length > words) parts.pop()
    return parts.map(part => limitToLength(part, length)).join(' ')
}

export function limitToLength (data, length) {
    if (typeof data !== 'string') return data
    if (data.length > length) return data.substr(0, length)
    return data
}

export function ObjectWithoutKeys (object, keys) {
    if (typeof object !== 'object') return object
    return Object.keys(object)
            .filter(key => (!keys.includes(key)))
            .reduce((obj, key) => ({...obj, [key]: object[key]}), {})
}

export function maskPhone (phone, display = false) {
    const _phonePattern = /([0-9]{3})([0-9]{1,3})?([0-9]{1,4})?/
    const _invalidNumberPattern = /[\._\-~!@$^*+=(){}[\]<>"&%'#:|`´\\\/;a-z]/ig
    let _phone = phone.replace(_invalidNumberPattern, '')

    if (_phone.length <= 3) return _phone
    else if (_phone.length <= 6) {
        _phone = _phone.replace(_phonePattern, (display ? `($1) $2` : `$1-$2`))
        return _phone
    } else if (_phone.length <= 10) {
        _phone = _phone.replace(_phonePattern, (display ? `($1) $2-$3` : `$1-$2-$3`))
        return _phone
    } else {
        _phone = _phone.substring(0,10).replace(_phonePattern, (display ? `($1)-$2-$3` : `$1-$2-$3`))
        return _phone
    }
}

export function maskZipCode (code) {
    let _code = code.replace(INVALID_NUMBERS_PATTERN, '')
    if (_code.length > 5) {
        _code = _code.substring(0, 5)
    }

    return _code
}

export function milesToKm (miles) {
    return miles * 1.60934
}
export function kmToMiles (km) {
    return km / 1.60934
}

export function scrollIframeToViewPort (selector) {
    try {
        const domEl = window.document.querySelector(selector)
        const position = domEl.getBoundingClientRect()
        let scrolled = false
        if (window.parentIFrame) {
            window.parentIFrame.getPageInfo(function (pageInfo) {
                if (position && !scrolled) {
                    window.parentIFrame.scrollTo(0, position.top + pageInfo.offsetTop - 80)
                    scrolled = true
                }
            })
        }
    } catch (e) {
        console.log(e)
    }
}

export const sortStringsCallback = (order = 'asc') => (a, b) => {
    const nameA = a.toLowerCase()
    const nameB = b.toLowerCase()
    if (nameA < nameB) return (order === 'asc') ? -1 : 1
    if (nameA > nameB) return (order === 'asc') ? 1 : -1
    return 0
}

export function todayStr (dayNumber) {
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
    const day = dayNumber || ((new Date()).getDay() - 1)
    return days[day]
}

export function ToUpper(data){
     return data.toUpperCase();
}

export function ToLower(data){
    return data.toLowerCase();
}




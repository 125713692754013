/* global google */
import React from 'react';
import './googlePredictiveSearch.scss';
import {getLocale} from "../../../utilities/functions";
import ZipCodePrioritizedAutocomplete from './ZipCodePrioritizedAutocomplete';

const locale = getLocale();

export default class PredictiveSearch extends React.Component {
  state = {
    empty: true,
    text: null,
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      if (props.value && props.value.length > 0) {
        return { text: props.value, empty: false };
      }
    }
    return null;
  }

  handleChange = (e) => {
    const text = e.target.value;
    this.setState({empty: text.length === 0, text});
  }

  handleBlur = () => {
    this.setState({empty: (!this.state.text || this.state.text.length === 0)});
  }

  handlePlaceSelected = (place) => {
    const {onPlaceSelected} = this.props;

    this.setState({text: place.description, empty: false});
    onPlaceSelected(place);
  };

  clear = () => {
    this.setState({text: '', empty: true});
  }

  render() {
    const {placeholder, types} = this.props;
    const {empty, text} = this.state;
    const restrictions = {country: (locale === 'es' ? 'mx' : ['us', 'pr', 'vi'])};

    return (
      <div className={`predictiveSearch ${!empty ? 'filled' : ''}`}>
        <ZipCodePrioritizedAutocomplete
          types={types}
          onPlaceSelected={this.handlePlaceSelected}
          placeholder={placeholder}
          restrictions={restrictions}
          value={text}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
        />
        <span className="searchLabel">{placeholder}</span>
      </div>
    );
  }
}

PredictiveSearch.defaultProps = {
  placeholder: 'Enter Address or City and State or Zipcode',
  types: ['geocode'],
};
import {axiosPublic} from '../../utilities/http'
import {TEALIUM} from "../../utilities/constants";

export const CLEAR_SHOPS = 'CLEAR_SHOPS'

export const GET_SHOP_MEDIA_ERROR = 'GET_SHOP_MEDIA_ERROR'
export const GET_SHOP_MEDIA_REQUEST = 'GET_SHOP_MEDIA_REQUEST'
export const GET_SHOP_MEDIA_SUCCESS = 'GET_SHOP_MEDIA_SUCCESS'

export const REMOVE_ALL_FILTERS = 'REMOVE_ALL_FILTERS'
export const REMOVE_FILTER = 'REMOVE_FILTER'

export const SEARCH_SHOPS_ERROR = 'SEARCH_SHOPS_ERROR'
export const SEARCH_SHOPS_REQUEST = 'SEARCH_SHOPS_REQUEST'
export const SEARCH_SHOPS_SUCCESS = 'SEARCH_SHOPS_SUCCESS'

export const SET_COUPON = 'SET_COUPON'
export const SET_FILTER = 'SET_FILTER'
export const SET_LOCATION = 'SET_LOCATION'
export const SET_OPTIONS = 'SET_OPTIONS'
export const SET_PLACE = 'SET_PLACE'

export const SET_SHOP = 'SET_SHOP'
export const SET_SHOP_ACTION = 'SET_SHOP_ACTION'

export const clearShops = () => ({type: CLEAR_SHOPS})

export const getShopMedia = (ID) => async dispatch => {
    dispatch(getShopMediaRequest())
    try {
        const url = [window.env.PUBLIC_GATEWAY_BASE_URL, '/commercial/shop-info/v1/profiles/', ID, '/media'].join('')
        const {data} = await axiosPublic.get(url)
        dispatch(getShopMediaSuccess(data))
    } catch (e) {
        dispatch(getShopMediaError(e))
    }
}

const getShopMediaError = error => ({type: GET_SHOP_MEDIA_ERROR, payload: error})
const getShopMediaRequest = () => ({type: GET_SHOP_MEDIA_REQUEST})
const getShopMediaSuccess = media => ({type: GET_SHOP_MEDIA_SUCCESS, payload: media})

export const removeAllFilters = () => ({type: REMOVE_ALL_FILTERS})

export const removeFilter = item => ({type: REMOVE_FILTER, payload: item})

export const searchShops = (options, searchFields = {}, track = true) => async dispatch => {
    const firstTryRangeFilter = {
        "awsTierNumber": {"_range":{"gte":"2"}},
        "shopTypeName": {"_any": ["GENERAL REPAIR", "TIRE DEALER", "OIL - LUBE", "FOREIGN/SPECIALTY", "COLLISION"]}
    }
    const secondTryRangeFilter = {
        "averageWeeklySalesAmount": {"_range":{"gte":"50"}},
        "shopTechniciansNumber": {"_range":{"gte":"2"}},
        "isNationalAccountFlag": true,
        "shopTypeName": "GENERAL REPAIR"
    }
    //const firstTrySortFields = [{awsTierNumber: "desc"}, {accountPriorityNumber: "desc"}, {averageWeeklySalesAmount: "desc"},]
    //const secondTrySortFields = [{averageWeeklySalesAmount: "desc"}]
    const defaultOptions = {
        requestedPageNumber: 1,
        resultsLinesPerPage:50,
        searchFields: {
            ...firstTryRangeFilter,
            ...searchFields
        },
        sortFields: null,
        useDefaultSort: true
    }
    dispatch(searchShopsRequest())
    dispatch(clearShops())
    try {
        const url = [window.env.PUBLIC_GATEWAY_BASE_URL, '/commercial/shop-info/v1/profiles/search'].join('')
        const config = {...defaultOptions, ...options}
        const {data} = await axiosPublic.post(url, config)
        if (data) dispatch(searchShopsSuccess(data, track)) 
        else {
            config.searchFields = { ...config.searchFields, ...secondTryRangeFilter }
            config.useDefaultSort = false
            //config.sortFields = secondTrySortFields
            const {data, status} = await axiosPublic.post(url, config)
            if (data) dispatch(searchShopsSuccess(data, track))
            else if (status === 204) dispatch(searchShopsSuccess({originalRequest: null, profiles: [], rows: 0}, track))
        }
    } catch (e) {
        dispatch(searchShopsError(e))
    }
}

const searchShopsError = error => ({type: SEARCH_SHOPS_ERROR, payload: error})
const searchShopsRequest = () => ({type: SEARCH_SHOPS_REQUEST})
const searchShopsSuccess = (shops, track = true) => {
    //if (track) {
    if (track && shops.originalRequest!== null) {
        const {shops: {place}} = require('../store').default.getState()
        const {originalRequest: {searchFields}} = shops
        const pins = shops.profiles.map(shop => shop.id).join('|')
        let tealiumDetails = {[TEALIUM.shopSearchPIN]: pins}

        if (place && place.formatted_address) {
            tealiumDetails[TEALIUM.shopSearch] = place.formatted_address
        }

        // Filters
        if (searchFields["shopCertificationsArray"] && searchFields["shopCertificationsArray"]["_any"].length > 0) {
            if (!tealiumDetails[TEALIUM.shopSearchFilter]) tealiumDetails[TEALIUM.shopSearchFilter] = []
            tealiumDetails[TEALIUM.shopSearchFilter].push("shopCertifications")
            tealiumDetails["shop_certifications"] = searchFields["shopCertificationsArray"]["_any"]
        }

        if (searchFields["servicesPerformedArray"] && searchFields["servicesPerformedArray"]["_any"].length > 0) {
            if (!tealiumDetails[TEALIUM.shopSearchFilter]) tealiumDetails[TEALIUM.shopSearchFilter] = []
            tealiumDetails[TEALIUM.shopSearchFilter].push("servicesPerformed")
            tealiumDetails["services_performed"] = searchFields["servicesPerformedArray"]["_any"]
        }

        if (searchFields["regulatoryServicesArray"] && searchFields["regulatoryServicesArray"]["_any"].length > 0) {
            if (!tealiumDetails[TEALIUM.shopSearchFilter]) tealiumDetails[TEALIUM.shopSearchFilter] = []
            tealiumDetails[TEALIUM.shopSearchFilter].push("regulatoryServices")
            tealiumDetails["regulatory_services"] = searchFields["regulatoryServicesArray"]["_any"]
        }

        if (searchFields["vehiclesServicesArray"] && searchFields["vehiclesServicesArray"]["_any"].length > 0) {
            if (!tealiumDetails[TEALIUM.shopSearchFilter]) tealiumDetails[TEALIUM.shopSearchFilter] = []
            tealiumDetails[TEALIUM.shopSearchFilter].push("vehiclesServices")
            tealiumDetails["vehicles_services"] = searchFields["vehiclesServicesArray"]["_any"]
        }

        if (searchFields["shopLanguagesArray"] && searchFields["shopLanguagesArray"]["_any"].length > 0) {
            if (!tealiumDetails[TEALIUM.shopSearchFilter]) tealiumDetails[TEALIUM.shopSearchFilter] = []
            tealiumDetails[TEALIUM.shopSearchFilter].push("shopLanguages")
            tealiumDetails["shop_languages"] = searchFields["shopLanguagesArray"]["_any"]
        }

        if (searchFields["isProvantageCustomerFlag"]) {
            if (!tealiumDetails[TEALIUM.shopSearchFilter]) tealiumDetails[TEALIUM.shopSearchFilter] = []
            tealiumDetails[TEALIUM.shopSearchFilter].push("isProvantageCustomerFlag")
        }

        // Create string concatenation
        if (tealiumDetails[TEALIUM.shopSearchFilter] && tealiumDetails[TEALIUM.shopSearchFilter].length > 0) {
            tealiumDetails[TEALIUM.shopSearchFilter] = tealiumDetails[TEALIUM.shopSearchFilter].join("|")
        }

        //tealiumLink(tealiumDetails)
    }

    return {type: SEARCH_SHOPS_SUCCESS, payload: shops}
}

export const setCoupon = coupon => ({type: SET_COUPON, payload: coupon})

export const setFilter = item => ({type: SET_FILTER, payload: item})

export const setLocation = (coords) => ({type: SET_LOCATION, payload: coords})

export const setOptions = options => ({type: SET_OPTIONS, payload: options})

export const setPlace = place => ({type: SET_PLACE, payload: place})

export const setShop = shop => ({type: SET_SHOP, payload: shop})

export const setShopAction = action => ({type: SET_SHOP_ACTION, payload: action})

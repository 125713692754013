import React from 'react'
import PropTypes from 'prop-types'
import './modalFooter.scss'
import ButtonAutoZone from "../button-autozone";

const ModalFooter = ({buttons}) => {
    return (
            <div className="ModalFooter">
                {buttons.map((button, i) => (
                        <ButtonAutoZone
                            key={i}
                            backgroundColor={'#fff'}
                            borderColor={button.color}
                            color={button.color}
                            handler={button.handler}
                            height={28}
                            text={button.text}
                            width={84}
                        />
                ))}
            </div>
    )
}

ModalFooter.defaultProps = {
    buttons: []
}

ModalFooter.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.shape({
        color: PropTypes.string,
        handler: PropTypes.func,
        text: PropTypes.string
    }))
}

export default ModalFooter
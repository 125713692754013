import React from 'react'
import './button-autozone.scss'
import PropTypes from "prop-types";

export default class ButtonAutoZone extends React.PureComponent {
// eslint-disable-next-line
    constructor(props) {
        super(props)
    }

    getFontSize() {
        const {height, textSize} = this.props
        let _height = height

        if (textSize) return textSize
        if (typeof height === 'string') {
            _height = parseInt((height.replace('px', '')))
        }
        return (_height > 28) ? 16 : 14
    }

    render() {

        const {text, width, height, borderColor, backgroundColor, color, opacity, id, disabled} = this.props
        const fontSize = this.getFontSize()

        return (
                <button
                        id={id}
                        disabled={disabled}
                        style={{width, height, borderColor, backgroundColor, color, opacity}}
                        onClick={(e) => {
                    e.preventDefault()
                    this.props.handler()
                }}>
                    <span style={{fontSize}}>{text}</span>
                </button>
        )
    }
}


ButtonAutoZone.defaultProps = {
    height: 28,
    opacity: 1
}

ButtonAutoZone.propTypes = {
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    handler: PropTypes.func,
    id: PropTypes.string,
    opacity: PropTypes.number,
    text: PropTypes.string,
    textSize: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
}

import React from 'react'
import ButtonAutoZone from "../../../suite/button-autozone"
import '../../StepWrapper/alertBox/alert.scss'
import Localization from "../../../../utilities/localization";

const DeleteCouponAlert = (props) => (
        <div className="alert">

            <div className="header">
                <span className="title">{Localization.print("Delete Coupon")}</span>

            </div>

            <div className="content">
                <p>{Localization.print("Are you sure you want to delete this coupon?")}</p>
            </div>

            <div className="footer">
                <ButtonAutoZone
                        backgroundColor={'#fff'}
                        borderColor={'#000'}
                        color={'#3a3c3d'}
                        handler={props.onCancel}
                        height={38}
                        text={props.locale === 'es' ? 'CANCELAR' : 'NO'}
                        width={138}
                />
                <div style={{width: 16}} />
                <ButtonAutoZone
                        backgroundColor={'#f37f00'}
                        borderColor={'transparent'}
                        color={'#fff'}
                        handler={props.onContinue}
                        height={38}
                        text={props.locale === 'es' ? 'ACEPTAR' : 'YES'}
                        width={136}
                />
            </div>

        </div>
)

export default DeleteCouponAlert

export const circleTypes = {
    active: 'active',
    inactive: 'inactive',
    complete: 'complete'
}

export const barTypes = {
    active: 'active',
    inactive: 'inactive',
}

export const errorTypes = {
    required: 'This field is required',
    invalid: 'Invalid content',
    invalidAddress: 'Please enter a valid address',
    invalidCharacters: 'The character{1} {2} {3} not allowed',
    invalidEmail: 'Please enter a valid Email',
    invalidZipCode: 'Please enter a valid Zip Code',
    invalidPhoneNumber: 'Please enter a valid Phone Number',
    textOnly: 'Only letters are allowed'
}
export const systemErrors = [
    {key: 'systemError', message: 'This page is currently unavailable, please come back shortly to try again or contact program support 866-853-6459.'},    
    {key: 'getProfileError', message: 'We are unable to retrieve your profile information at this time, please try again later or call 866-853-6459 for assistance.'}
]

export const couponTypes = {
    free: 'FREE',
    percent: 'PERCENT',
    dollar: 'DOLLAR',
    special: 'SPECIAL'
}

export const HOUR_SECTION = {
    language: 'language',
    timeZone: 'timeZone',
    week: 'week'
}

export const AFFILIATION_SECTION = {
    affiliation: 'affiliation',
    amenities: 'amenities',
    certifications: 'certifications',
    other: 'other',
    member: 'member',
    state: 'state',
}

export const SERVICES_SECTION = {
    shopType: 'shopType',
    servicesPerformed: 'servicesPerformed',
    vehicleServiced: 'vehicleServiced'
}

export const COUPONS_SECTION = {
    coupons: 'coupons'
}

export const HOURS_SMALL = [
    {text: '12:00', id: 23, selected: false},
    {text: '12:30', id: 24, selected: false},
    {text: '01:00', id: 1, selected: false},
    {text: '01:30', id: 2, selected: false},
    {text: '02:00', id: 3, selected: false},
    {text: '02:30', id: 4, selected: false},
    {text: '03:00', id: 5, selected: false},
    {text: '03:30', id: 6, selected: false},
    {text: '04:00', id: 7, selected: false},
    {text: '04:30', id: 8, selected: false},
    {text: '05:00', id: 9, selected: false},
    {text: '05:30', id: 10, selected: false},
    {text: '06:00', id: 11, selected: false},
    {text: '06:30', id: 12, selected: false},
    {text: '07:00', id: 13, selected: false},
    {text: '07:30', id: 14, selected: false},
    {text: '08:00', id: 15, selected: false},
    {text: '08:30', id: 16, selected: false},
    {text: '09:00', id: 17, selected: false},
    {text: '09:30', id: 18, selected: false},
    {text: '10:00', id: 19, selected: false},
    {text: '10:30', id: 20, selected: false},
    {text: '11:00', id: 21, selected: false},
    {text: '11:30', id: 22, selected: false},
]

export const HOURS_RANGE = [
    {text: 'AM', id: 1, selected: false},
    {text: 'PM', id: 2, selected: false},
]

export const HOURS = [
    {text: '12:00 AM', id: 1, selected: false},
    {text: '12:30 AM', id: 2, selected: false},
    {text: '01:00 AM', id: 3, selected: false},
    {text: '01:30 AM', id: 4, selected: false},
    {text: '02:00 AM', id: 5, selected: false},
    {text: '02:30 AM', id: 6, selected: false},
    {text: '03:00 AM', id: 7, selected: false},
    {text: '03:30 AM', id: 8, selected: false},
    {text: '04:00 AM', id: 9, selected: false},
    {text: '04:30 AM', id: 10, selected: false},
    {text: '05:00 AM', id: 11, selected: false},
    {text: '05:30 AM', id: 12, selected: false},
    {text: '06:00 AM', id: 13, selected: false},
    {text: '06:30 AM', id: 14, selected: false},
    {text: '07:00 AM', id: 15, selected: false},
    {text: '07:30 AM', id: 16, selected: false},
    {text: '08:00 AM', id: 17, selected: false},
    {text: '08:30 AM', id: 18, selected: false},
    {text: '09:00 AM', id: 19, selected: false},
    {text: '09:30 AM', id: 20, selected: false},
    {text: '10:00 AM', id: 21, selected: false},
    {text: '10:30 AM', id: 22, selected: false},
    {text: '11:00 AM', id: 23, selected: false},
    {text: '11:30 AM', id: 24, selected: false},
    {text: '12:00 PM', id: 25, selected: false},
    {text: '12:30 PM', id: 26, selected: false},
    {text: '01:00 PM', id: 27, selected: false},
    {text: '01:30 PM', id: 28, selected: false},
    {text: '02:00 PM', id: 29, selected: false},
    {text: '02:30 PM', id: 30, selected: false},
    {text: '03:00 PM', id: 31, selected: false},
    {text: '03:30 PM', id: 32, selected: false},
    {text: '04:00 PM', id: 33, selected: false},
    {text: '04:30 PM', id: 34, selected: false},
    {text: '05:00 PM', id: 35, selected: false},
    {text: '05:30 PM', id: 36, selected: false},
    {text: '06:00 PM', id: 37, selected: false},
    {text: '06:30 PM', id: 38, selected: false},
    {text: '07:00 PM', id: 39, selected: false},
    {text: '07:30 PM', id: 40, selected: false},
    {text: '08:00 PM', id: 41, selected: false},
    {text: '08:30 PM', id: 42, selected: false},
    {text: '09:00 PM', id: 43, selected: false},
    {text: '09:30 PM', id: 44, selected: false},
    {text: '10:00 PM', id: 45, selected: false},
    {text: '10:30 PM', id: 46, selected: false},
    {text: '11:00 PM', id: 47, selected: false},
    {text: '11:30 PM', id: 48, selected: false},
]

export const shopTypes = [
    {text: 'Collision', value: 'COLSN', id: 1, selected: false},
    {text: 'General Repair', value: 'GENREP', id: 2, selected: false},
    {text: 'New Car Dealer', value: 'NEWCAR', id: 3, selected: false},
    {text: 'Tire Dealer', value: 'TIRE', id: 4, selected: false},
    {text: 'User Car Dealer', value: 'USEDCAR', id: 5, selected: false},
]

export const states = (locale = 'us') => {
    const collection = locale === 'es' ? require('./mx_states') : require('./us_states')
    return collection.map(state => ({
        text: state.name,
        abbreviation: state.abbreviation,
        id : state.code,
        selected: false
    }))
}

export const validationTypes = {
    address: 'address',
    email: 'email',
    number: 'number',
    text: 'text',
    textOnly: 'textOnly',
    url: 'url'
}

export const profileStep = {
    1: 'INFHRS',
    2: 'CERTAFFL',
    3: 'SERVVEH',
    4: 'COUPON',
    5: 'COMPLETE'
}

export const TEALIUM = {
    coupon_count: 'coupon_count',
    shop_status: 'shop_status',
    singup_status:'singup_status',
    image_status:'image_status',
    eligible: 'eligible',
    profilePicture: 'shop_referral_profile_picture',
    shopCouponClicks: 'shop_referral_coupon_clicks',
    shopClicks: 'shop_referral_shop_clicks',
    shopDetails: 'shop_referral_view_shop',
    shopCouponPIN: 'shop_referral_coupon_PIN',
    shopNotificationClicks: 'shop_referral_notifcation_clicks',
    shopForm: 'shop_referral_form',
    shopSearch: 'shop_referral_search_shop',
    shopSearchFilter: 'shop_referral_shop_filter',
    shopSearchPIN: 'shop_referral_search_PIN',
    //New Added
    shopReferralStatus:'shop_referral_status',
    shopReferralShopType:'shop_referral_shop_type',
    shopCouponCount:'shop_coupon_count'
}

export const sessionStorageKey = {
    "LOCALE": "SHRL-locale",
    "SHOP": "SHRL-shop-details",
    "SKIP": "SHRL-skip-tealium"
}

export const defaultHours = {
    open: '09:00 AM',
    close: '05:00 PM'
}

export const firestoneURL = "https://www.firestonedirect.com/";

export const fixFinderURL = "https://www.autozone.com/lp/fix-finder?intcmp=HOM:HER:1:20240827:20240923:GEN:FixFinder";
import React from 'react'
import {Row, Col} from 'react-grid-system';
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import './faq.scss'
import Box from '../../suite/box'
import {Link as ScrollLink} from "react-scroll";
import {getLocale, isIframe} from '../../../utilities/functions';

const locale = getLocale()
class FaqPage extends React.Component {

    SLink = (props) => {
        const _handleClick = (e) => {
            e.preventDefault()
            const targetElement = document.getElementById(props.to)
            const {top} = targetElement.getBoundingClientRect()
            try {
                window.parentIFrame.sendMessage({top, message: 'scrollto'}, '*')
            } catch (e) {
                console.warn(e)
            }
        }

        return (
                <a style={{cursor: 'pointer'}} onClick={_handleClick}>
                    {props.children}
                </a>
        )
    }

    render() {
        const Link = isIframe() ? this.SLink : ScrollLink
        return (
                <div id="faqs">
                    {locale === 'es' ? (
                            <Row gutterWidth={16}>
                                <Col sm={(!isIframe() ? 9 : 12)} md={(!isIframe() ? 9 : 12)}>
                                    <h1 className="faq-header"> Preguntas Frecuentes</h1>
                                    <Box width='100%' backgroundColor='#6c6f70' color='#ffffff' font-size="14px"
                                         title='Haga clic en la pregunta para ver la respuesta.'>
                                        <ul className="faq">
                                            <li>
                                                <Link activeClass="active"
                                                      to="qes-one"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>¿Cómo puedo ser
                                                    elegible a ser publicado en la lista del Programa de
                                                    Referidos?</Link></li>
                                            <li><Link activeClass="active"
                                                      to="qes-twoo"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>¿Cómo puedo conocer mis
                                                promedios semanales de compra?</Link></li>
                                            <li><Link activeClass="active"
                                                      to="qes-two"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>¿Cómo se calcula mi
                                                promedio de compra semanal?</Link></li>
                                            <li><Link activeClass="active"
                                                      to="qes-three"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>Si mi taller logra
                                                cumplir con los requerimientos de elegibilidad, ¿qué necesito para que
                                                mi taller se muestre en la lista?</Link></li>
                                            <li><Link activeClass="active"
                                                      to="qes-four"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>¿Dónde puedo ver si mi
                                                taller está en la lista de referidos?</Link></li>
                                            <li><Link activeClass="active"
                                                      to="qes-five"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>He creado un perfil del
                                                taller y mi taller actualmente es elegible para ser publicado, ¿pero qué
                                                sucede si mi promedio de compra semanal cae por debajo de los $3,500
                                                pesos?</Link></li>
                                            <li><Link activeClass="active"
                                                      to="qes-six"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>Creé mi perfil del
                                                taller, pero mi taller actualmente no cumple con la elegibilidad para
                                                estar publicado. ¿Mi taller va a ser publicado de todos modos pues ya
                                                creé el perfil y me he registrado al programa?</Link></li>
                                            <li><Link activeClass="active"
                                                      to="qes-seven"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>Cumplo con los
                                                requerimientos de elegibilidad para el programa, pero no he creado un
                                                perfil del taller. ¿Mi taller será publicado de todas formas sólo porque
                                                cumplo con los requerimientos?</Link></li>
                                            <li><Link activeClass="active"
                                                      to="qes-eight"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>He visitado el sitio de
                                                Ajustes al Perfil del Taller Referido por primera vez y veo que ya tengo
                                                creado un perfil y cierta información está alimentada, ¿de dónde
                                                proviene esa información?</Link></li>
                                            <li><Link activeClass="active"
                                                      to="qes-nine"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>Una vez que he creado
                                                mi perfil, ¿puedo actualizarlo después si mi información cambia?</Link>
                                            </li>
                                            <li><Link activeClass="active"
                                                      to="qes-ten"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>Una vez que he
                                                actualizado mi perfil, ¿cuánto tiempo toma en que se refleje en la
                                                aplicación “Encuentra un Taller”?</Link></li>
                                        </ul>
                                        <div className="answers">

                                            <p id="qes-one"><strong>¿Cómo puedo ser elegible a ser publicado en la lista
                                                del Programa de Referidos?</strong>
                                                Los talleres referidos pueden ser:
                                                <ul style={{paddingInlineStart: '15px', listStyleType: 'disc'}}>
                                                    <li>Cuentas Nacionales</li>
                                                    <li>Cualquier cuenta de AutoZone Profesional que tenga un promedio
                                                        de compra de al menos $3,500 pesos + IVA a la semana en AutoZone
                                                        Profesional puede ser elegible. Estar al corriente de tu
                                                        crédito.
                                                    </li>
                                                </ul></p>

                                            <p id="qes-twoo"><strong>¿Cómo puedo conocer mis promedios semanales de
                                                compra?</strong>
                                                Tu actual promedio de compra semanal se muestra en la esquina superior
                                                derecha de cada página dentro de Ajustes al Perfil del Taller Referido.
                                            </p>

                                            <p id="qes-two"><strong>¿Cómo se calcula mi promedio de compra
                                                semanal?</strong>
                                                El promedio semanal de compra se calcula así: el total de las compras
                                                menos las devoluciones del mes calendario anterior, divididas entre los
                                                días del mes, multiplicadas por siete</p>

                                            <p id="qes-three"><strong>Si mi taller logra cumplir con los requerimientos
                                                de elegibilidad, ¿qué necesito para que mi taller se muestre en la
                                                lista?</strong>
                                                Debes registrarte en el programa creando tu Perfil de Taller y aceptar
                                                los términos y condiciones del programa, ambas cosas se hacen en los
                                                Ajustes al Perfil del Taller Referido. Para crear tu perfil, simplemente
                                                haz clic en el botón de crear un perfil nuevo localizado en la página
                                                principal de Ajustes al Perfil del Taller Referido y llena la
                                                información solicitada.</p>

                                            <p id="qes-four"><strong>¿Dónde puedo ver si mi taller está en la lista de
                                                referidos?</strong>
                                                Accede a la lista de referidos ingresando al sitio <a
                                                        href="https://www.autozone.com.mx">www.autozone.com.mx</a> y da
                                                clic en “Encuentra un Taller” localizado en la esquina superior
                                                izquierda de la página. También puedes utilizar el siguiente enlace: <a
                                                        href="https://shop-referral.autozonepro.com/az/findShop?v=mx"
                                                        target="_blank">Encuentra un Taller</a>
                                            </p>

                                            <p id="qes-five"><strong>He creado un perfil del taller y mi taller
                                                actualmente es elegible para ser publicado, ¿pero qué sucede si mi
                                                promedio de compra semanal cae por debajo de los $3,500 pesos?</strong>
                                                Los talleres son removidos de la Lista de Referidos cuando el taller ya
                                                no cumple con el mínimo de $3,500 establecido. Cuando tu taller vuelva a
                                                cumplir con ese requerimiento, se agregará de nuevo a la Lista de
                                                Referidos automáticamente pues tu perfil ya había sido creado.</p>

                                            <p id="qes-six"><strong>Creé mi perfil del taller, pero mi taller
                                                actualmente no cumple con la elegibilidad para estar publicado. ¿Mi
                                                taller va a ser publicado de todos modos pues ya creé el perfil y me he
                                                registrado al programa?</strong>
                                                No hasta que cumplas con los requerimientos de elegibilidad. Sin
                                                embargo, es conveniente que ya hayas creado tu cuenta y estés registrado
                                                en el programa pues tu taller va a ser automáticamente publicado cuando
                                                logres cumplir el requerimiento del mínimo de $3,500 pesos. Estar al
                                                corriente de tu crédito.</p>

                                            <p id="qes-seven"><strong>Cumplo con los requerimientos de elegibilidad para
                                                el programa, pero no he creado un perfil del taller. ¿Mi taller será
                                                publicado de todas formas sólo porque cumplo con los
                                                requerimientos?</strong>
                                                Aun si cumples con los requerimientos, el taller no será publicado a
                                                menos que crees un perfil de taller y te hayas inscrito al programa.
                                                Debes crear un perfil del taller y cumplir con los requerimientos para
                                                ser publicado en la Lista de Referidos.</p>

                                            <p id="qes-eight"><strong>He visitado el sitio de Ajustes al Perfil del
                                                Taller Referido por primera vez y veo que ya tengo creado un perfil y
                                                cierta información está alimentada, ¿de dónde proviene esa
                                                información?</strong>
                                                Si ya tienes un perfil del taller creado es porque te has registrado en
                                                el programa previamente y nosotros teníamos cierta información de tu
                                                taller que pudimos alimentar. Por favor revisa la información existente
                                                y actualízala si es necesario. Además, por favor provéenos cualquier
                                                dato faltante para que podamos brindarle información más completa de tu
                                                taller a aquellos buscando un taller referido.</p>

                                            <p id="qes-nine"><strong>Una vez que he creado mi perfil, ¿puedo
                                                actualizarlo después si mi información cambia?</strong>
                                                Por supuesto que sí, puedes visitar el sitio de Ajustes al Perfil del
                                                Taller Referido las 24 horas del día, los 7 días de la semana para
                                                actualizar la información de tu taller y administrar tus cupones, si es
                                                que los ofreces.</p>

                                            <p id="qes-ten"><strong>Una vez que he actualizado mi perfil, ¿cuánto tiempo
                                                toma en que se refleje en la aplicación “Encuentra un
                                                Taller”?</strong> Se actualizará inmediatamente para cualquier cambio
                                                que hagas de: información del taller, horarios, servicios
                                                proporcionados, certificaciones del taller y cupones. La única
                                                actualización que puede tomar hasta 5 días hábiles es la de fotografías
                                                de tu taller pues necesitaríamos revisar la imagen y asegurarnos de que
                                                se despliega propiamente en la página de los detalles de tu taller.</p>

                                        </div>
                                    </Box>

                                </Col>
                            </Row>
                    ) : (
                            <Row gutterWidth={16}>
                                <Col sm={(!isIframe() ? 9 : 12)} md={(!isIframe() ? 9 : 12)}>
                                    <h1 className="faq-header"> Frequently Asked Questions</h1>
                                    <Box width='100%' backgroundColor='#6c6f70' color='#ffffff' font-size="14px"
                                         title='Click on the question to view the answer'>
                                        <ul className="faq">
                                            <li>
                                                <Link activeClass="active"
                                                      to="qes-one"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>How do shops become
                                                    eligible to be listed on the Find a Repair Shop list?</Link>
                                            </li>
                                            <li>
                                                <Link activeClass="active"
                                                      to="qes-twoo"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>How is my shop type
                                                    determined?</Link>
                                            </li>
                                            <li>
                                                <Link activeClass="active"
                                                      to="qes-two"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>How do I find out what
                                                    my shop’s average weekly purchases are?</Link>
                                            </li>
                                            <li><Link activeClass="active"
                                                      to="qes-three"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>How are the average
                                                weekly purchases calculated?</Link></li>
                                            <li><Link activeClass="active"
                                                      to="qes-four"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>If my shop meets the
                                                program eligibility requirements, what do I need to do for my shop to
                                                appear on the list?</Link></li>
                                            <li><Link activeClass="active"
                                                      to="qes-five"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>Where can I see my shop
                                                listed on the referral list?</Link></li>
                                            <li><Link activeClass="active"
                                                      to="qes-six"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>I created a shop
                                                profile, but my shop doesn’t currently meet the eligibility requirement
                                                to be listed. Will my shop be listed anyway since I created a profile
                                                and signed up for the program?</Link></li>
                                            <li><Link activeClass="active"
                                                      to="qes-seven"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>I meet the eligibility
                                                requirements for the program, but I haven’t created a shop profile. Will
                                                I be listed anyway just because I meet the requirements?</Link></li>
                                            <li><Link activeClass="active"
                                                      to="qes-eight"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>I just visited the Shop
                                                Referral for the first time and I see I already have a profile and
                                                certain information is populated, where is that information coming
                                                from?</Link></li>
                                            <li><Link activeClass="active"
                                                      to="qes-nine"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>Who are the shops
                                                listed on Find A Repair Shop and where is their information coming
                                                from?</Link></li>
                                            <li><Link activeClass="active"
                                                      to="qes-ten"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>Once I create my shop
                                                profile, can I update it later if my information changes?</Link></li>
                                            <li><Link activeClass="active"
                                                      to="qes-eleven"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>Once I update my
                                                profile, how long does it take to reflect on the “Find A Repair Shop”
                                                application?</Link></li>
                                            <li><Link activeClass="active"
                                                      to="qes-twelve"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>How do I make sure my
                                                shop appears at the top of the Find A Repair Shop list?</Link></li>
                                            <li><Link activeClass="active"
                                                      to="qes-thirteen"
                                                      spy={true}
                                                      smooth={true}
                                                      offset={0}
                                                      duration={500} style={{cursor: 'pointer'}}>How can I use coupons
                                                to improve my search results?</Link></li>
                                        </ul>
                                        <div className="answers">
                                            <p id="qes-one"><strong>How do shops become eligible to be listed on the
                                                Find a Repair Shop list?</strong>
                                                Shops must be an AutoZone Preferred Shop to be eligible to appear on the
                                                AutoZone Find a Shop list. Preferred Shops are defined as:
                                                <ul>
                                                    <li>National Accounts or</li>
                                                    <li>General Repair, Wheel and Tire, Oil and Lube, Auto Body,
                                                        Foreign/Specialty
                                                    </li>
                                                    <li>Any Commercial account that spends an average of at least
                                                        $300/week
                                                    </li>
                                                </ul>
                                                <b>Note:</b> All shops are pre-filtered for General Repair, and the
                                                consumer must select other types to have non-general repair shops show
                                                up on the list. Dealerships are not eligible for the program.
                                            </p>

                                            <p id="qes-twoo"><strong>How is my shop type determined?</strong>
                                                Shop type was assigned when you signed up with AutoZone. You may change
                                                your shop type in the My Account section of <a href="https://www.AutoZonePro.com" target="_blank" title="">AutoZonePro.com</a></p>

                                            <p id="qes-two"><strong>How do I find out what my shop’s average weekly
                                                purchases are?</strong>
                                                The shop’s current average weekly purchase amount is displayed on the
                                                header of the Shop Referral in <a href="https://www.AutoZonePro.com" target="_blank">AutoZonepro.com</a>.</p>

                                            <p id="qes-three"><strong>How are the average weekly purchases
                                                calculated?</strong>
                                                Average weekly purchase is defined as: total of purchases (excluding
                                                salvage parts and AutoZone extended credit terms), minus returns and
                                                credits, over the previous calendar month, divided by days in month
                                                multiplied by seven.</p>

                                            <p id="qes-four"><strong>If my shop meets the program eligibility
                                                requirements, what do I need to do for my shop to appear on the
                                                list?</strong>
                                                You must sign up for the program by creating your Shop Profile and
                                                accepting the program terms and conditions, both of which are done
                                                within the AutoZonePro by going to My Account/Update Shop Referral
                                                Information/Join Shop Referral. To create your profile, simply click on
                                                the Join Shop Referral button located on the landing page of Shop
                                                Referral and fill out the required information.</p>

                                            <p id="qes-five"><strong>Where can I see my shop listed on the referral
                                                list?</strong>
                                                The referral list is accessed through the Find a Repair Shop link on
                                                Autozone.com (upper left corner). You can also use the following link to
                                                access it: <a
                                                        href="https://www.autozone.com/landing/page.jsp?name=find-a-repair-shop"
                                                        target="_blank">Find A Shop Repair</a> <br/><br/>

                                                If you are currently eligible and have created a profile, and when you
                                                meet the selected search criteria, you will be included on the list the
                                                week following your enrollment.</p>

                                            <p id="qes-six"><strong>I created a shop profile, but my shop doesn’t
                                                currently meet the eligibility requirement to be listed. Will my shop be
                                                listed anyway since I created a profile and signed up for the
                                                program?</strong>
                                                Shops are removed from the Referral List when they no longer meet the
                                                $300/week spend or other requirement. When your shop meets the
                                                requirements again, you are added back to the Referral List
                                                automatically.</p>

                                            <p id="qes-seven"><strong>I meet the eligibility requirements for the
                                                program, but I haven’t created a shop profile. Will I be listed anyway
                                                just because I meet the requirements?</strong>
                                                No. Your shop won’t be listed until you create a shop profile and sign
                                                up for the program. You must create a shop profile AND meet the
                                                requirements to be listed.</p>

                                            <p id="qes-eight"><strong>I just visited the Referral Program Shop Profile
                                                Manager for the first time and I see I already have a profile and
                                                certain information is populated, where is that information coming
                                                from?</strong>
                                                If you have a shop profile already created it is because you or someone
                                                from your organization previously signed up for the program. Please
                                                review the exiting information and make any updates needed.</p>

                                            <p id="qes-nine"><strong>Who are the shops listed on Find A Repair Shop and
                                                where is their information coming from?</strong>
                                                The list is populated with shops that have signed up for the program and
                                                currently meet the eligibility requirements. Since the updated program
                                                has new features, it is important for shops to access their profile
                                                on <a href="https://www.AutoZonePro.com" target="_blank">www.AutoZonePro.com</a>, review
                                                the exiting information and make any needed updates.</p>

                                            <p id="qes-ten"><strong>Once I create my shop profile, can I update it later
                                                if my information changes?</strong>
                                                Absolutely, you can visit Account/My Account/Update Shop Referral
                                                Information/Join Shop Referral 24/7 to update your shop information and
                                                add/manage your coupons.</p>

                                            <p id="qes-eleven"><strong>Once I update my profile, how long does it take
                                                to reflect on the “Find A Repair Shop” application?</strong>
                                                Updates to shop information / hours, services performed, shop
                                                certifications, coupons, and shop picture occur immediately.</p>

                                            <p id="qes-twelve"><strong>How do I make sure my shop appears at the top of
                                                the Find A Repair Shop list?</strong>
                                                Sorting of the list is done by spend levels. Increasing spend levels
                                                will move your listing up</p>
                                            <p id="qes-thirteen"><strong>How can I use coupons to improve my search
                                                results?</strong>
                                                On the search results page, shops with coupons display a coupon icon. Be
                                                sure to activate/update coupons to entice customers to visit your shop.
                                            </p>

                                        </div>
                                    </Box>

                                </Col>
                            </Row>
                    )}
                </div>
        )
    }
}

const mapStateToProps = state => ({
    locale: state.global.locale,
})
export default connect(mapStateToProps)(withRouter(FaqPage))

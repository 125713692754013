import React from 'react'
import './noresults.scss'
import Localization from "../../../../utilities/localization";
class NoResultsFound extends React.Component {
    constructor (props) {
        super(props)

    }
    render(){
        return (
            <div className="results-container">
                <span className="icon-noresults"></span>
               <h4>{Localization.print("We were unable to locate any shops within your search criteria. Please try again.")}</h4>
            </div>
        )
    }
}

export default NoResultsFound

import React from 'react'
import CloseButton from "../../../suite/closeButton";
import './emailModal.scss'

class EmailModal extends React.Component{

    constructor (props) {
        super(props)
        this.state = {           
        }
    }

    render() {    
        const {onClose, onClink} = this.props    
        return (
            <div className="email-modal">
                <div className="modal-header">
                    <CloseButton onClick={onClose} white/>
                </div>
                <div className="content-header">
                    <h2 className="modal-title">Email Page </h2>          
                </div>
                <div className="modal-content">
                    <p>Send shop details via email. Enter email address and sender’s name</p>
                    <input className="" type="email" name="email" />
                    <input className="" type="text" name="senders-name" />
                    <button className="modal-cta" onClick={onClink}>SEND INFO</button>
                </div>                
            </div>
        )
    }
}

export default EmailModal
import React from 'react'
import Coupon from '../../suite/coupon'
import azLogo from '../../../assets/logo-print.JPG'
import './couponPage.scss'
import {getCouponTitle} from "../../../utilities/functions";

export default class CouponPage extends React.Component {
    constructor (props) {
        super(props);

        let currentShop = localStorage.getItem('currentShop')
        let currentCoupon = localStorage.getItem('currentCoupon')
        if (currentShop) currentShop = JSON.parse(currentShop)
        if (currentCoupon) currentCoupon = JSON.parse(currentCoupon)
        this.state = {currentCoupon, currentShop}
    }

    componentDidMount() {
        const {currentCoupon, currentShop} = this.state
        if (currentCoupon && currentShop) {
            if (window.matchMedia) {
                this.mediaQueryList = window.matchMedia('print')
                this.mediaQueryList.addEventListener('change', this._handleMediaPrintChange)
            }

            window.print()
        }
    }

    _handleMediaPrintChange = mql => {
        if (!mql.matches) {
            //this.mediaQueryList.removeEventListener('change', this._handleMediaPrintChange)
            //window.close()
        }
    }

    render () {
        const {currentCoupon, currentShop} = this.state
        if (!currentCoupon || !currentShop) return null
        const shopAddress = [
            currentShop.shopAddressObject.postalAddressLine1Text + ', ',
            currentShop.shopAddressObject.cityName + ', ',
            currentShop.shopAddressObject.stateCode + ' ',
            currentShop.shopAddressObject.zipCode + ' '
        ].join('')
        return (
                <div className="CouponPage">
                    <header>
                        <div className="info">
                            <h1>{currentShop.shopName}</h1>
                            <p>{shopAddress}</p>
                        </div>
                        <div className="brand">
                            <img src={azLogo}/>
                        </div>
                    </header>
                    <div className="content">
                        <Coupon
                                disclaimerText={currentCoupon.disclaimerText}
                                endDate={currentCoupon.endDate}
                                salesText={currentCoupon.salesText}
                                shopAddress={shopAddress}
                                shopContactEmail={currentShop.shopContactObject.emailAddressValue}
                                shopName={currentShop.shopName}
                                shopPhoneNumber={currentShop.shopContactObject.mainPhoneNumber}
                                size="pt"
                                startDate={currentCoupon.startDate}
                                title={getCouponTitle(currentCoupon)}
                        />
                    </div>
                </div>
        )
    }
}

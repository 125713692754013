import React, {useEffect} from 'react'
import ReactDom from 'react-dom'
import PropTypes from 'prop-types'
import './blurPortal.scss'
import {scrollIframeToViewPort} from "../../utilities/functions";

const BlurPortal = props => {
    useEffect(() => {
        scrollIframeToViewPort('#portal-container')
    }, [])

    return ReactDom.createPortal(
            <div className="BlurPortal">
                <div className="backdrop" onClick={props.onBackdropClick} />
                <div id="portal-container" className={'portal-container'}>{props.children}</div>
            </div>,
            document.getElementById('portal-root')
    )
}

BlurPortal.propTypes = {
    onBackdropClick: PropTypes.func
}

export default BlurPortal

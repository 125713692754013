export const STEP_ONE_LANGUAGE_UPDATE_VALUE = "STEP_ONE_LANGUAGE_UPDATE_VALUE"
export const STEP_ONE_LANGUAGE_SWITCH_ALL = "STEP_ONE_LANGUAGE_SWITCH_ALL"
export const STEP_ONE_CHANGE_TIME_ZONE = "STEP_ONE_CHANGE_TIME_ZONE"
export const STEP_ONE_CHANGE_DAY = "STEP_ONE_CHANGE_DAY"
export const STEP_ONE_CHANGE_HOURS = "STEP_ONE_CHANGE_HOURS"
export const STEP_ONE_PROFILE_DATA = "STEP_ONE_PROFILE_DATA"
export const STEP_ONE_PROFILE_PROPERTY = "STEP_ONE_PROFILE_PROPERTY"
export const STEP_ONE_CLEAR_ERROR = "STEP_ONE_CLEAR_ERROR"
export const STEP_ONE_SET_ERROR = "STEP_ONE_SET_ERROR"

export const UPDATE_STEP = "UPDATE_STEP"
export const STEP_TWO_UPDATE_VALUE = "STEP_TWO_UPDATE_VALUE"
export const STEP_TWO_SWITCH_ALL = "STEP_TWO_SWITCH_ALL"
export const STEP_TWO_CHANGE_OTHER = "STEP_TWO_CHANGE_OTHER"

export const STEP_THREE_UPDATE_VALUE = "STEP_THREE_UPDATE_VALUE"
export const STEP_THREE_SWITCH_ALL = "STEP_THREE_SWITCH_ALL"
export const STEP_THREE_CHANGE_OTHER = "STEP_THREE_CHANGE_OTHER"
export const STEP_THREE_CHANGE_RADIO_VALUE = "STEP_THREE_CHANGE_RADIO_VALUE"

export const CAN_COMPLETE_PROCESS = "CAN_COMPLETE_PROCESS"

export const RESET_STEPS_DATA = "RESET_STEPS_DATA"
export const SET_STEPS_DATA = "SET_STEPS_DATA"
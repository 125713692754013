import React from 'react'
import { Row, Col } from 'react-grid-system'
import { connect } from 'react-redux'
import './homePage.scss'
import BodyHeader from '../body-header'
import ButtonAutoZone from '../../suite/button-autozone'
import Portal from '../../portal'
import Eligible from '../body-header/eligible'
import {getProfileStatus} from "../../../redux/actions/profile";

import ErrorMessage from "../../suite/error-message";
import Spinner from "../../suite/spinner";
import {systemErrors, TEALIUM} from "../../../utilities/constants";
import {tealiumLink} from "../../../utilities/utag";



import {isIframe, scrollIframeToViewPort} from "../../../utilities/functions";
import Localization from "../../../utilities/localization";

class HomePage extends React.Component{
    constructor(props){
        super(props)

        this.state={
            showEligible:false,
            status: props.profile.user ? props.profile.user.currentStatus : null
        }
    }

   

    static getDerivedStateFromProps (props, state) {
        if (props.profile.user && props.profile.user.currentStatus && props.profile.user.currentStatus !== state.status) {
            state.status = props.profile.user.currentStatus
            let destiny = null
            let method = 'push'
            
            switch (state.status) {
                case "INFHRS":
                    destiny = "/StepOne"
                    break
                case "CERTAFFL":
                    destiny = "StepTwo"
                    break
                case "SERVVEH":
                    destiny = "StepThree"
                    break
                case "COUPON":
                    destiny = "StepFour"
                    break
                case "COMPLETE":
                    destiny = "/Account"
                    method = "replace"
                   
                    break
                default:
                    destiny = null
            }

            if (destiny){
                    props.history[method](destiny)
                     
            } 
            return state
        }

        return null
    }

    componentDidMount() {

        if (isIframe()) {
            scrollIframeToViewPort('.Stepper')
        } else {
            window.scrollTo(0,0)
        }
    }

    

    switchShowEligible(){
        this.setState( previous=>({
            showEligible:!previous.showEligible
        }))
    }

    callStatus = () => {
        const {profile} = this.props
        const user = JSON.parse(sessionStorage.getItem('user'))
        if (profile && profile.user && profile.user.currentStatus) return
        if (user && user.hasOwnProperty('currentpin')) this.props.getProfileStatus(user.currentpin)
        else setTimeout(this.callStatus, 200)
    }

    goStepOne(){
        //tealiumLink({[TEALIUM.shopForm]: 'Signup Start'})
        sessionStorage.setItem(TEALIUM.singup_status, 'Signup Start')
        this.props.history.push('/StepOne')
    }

    _renderButton = () => {
        return <ButtonAutoZone handler={this.goStepOne.bind(this)} text={Localization.print("Join Shop Referral")} width={'283px'} height={'38px'} borderColor={'#f37f00'} backgroundColor={'#f37f00'} color='#ffffff'/>
    }

    _renderEnglishDescription = () => {
        return (
                <React.Fragment>
                    <p>
                        With over 5 million walk-in customers, 5 million online customers and internet search traffic each week, our shop referral program helps you get the attention you deserve.
                    </p>

                    <h2>Don’t Miss Out on this Great Source of Potential New Business</h2>

                    <span>Let us add your shop to our referral list for:</span>
                    <ul>
                        <li>FREE advertising as a part of our list of trusted professionals</li>
                        <li>Potential new customers and repeat business</li>
                        <li>The opportunity to offer customers coupons as an incentive</li>
                    </ul>

                    <h2 className="started-today">Get Started Today</h2>

                    <p>Review our qualification requirements to determine if your shop is eligible, then manage your shop profile here to be included on our shop referral list.</p>

                    {this._renderButton()}

                    <p>Not ready to create a profile? Return to <a href={window.env.MAIN_PAGE_URL} target="_parent"><span>AutoZonePro.com</span></a></p>

                    <p><strong>*</strong>Participating shops must meet qualification requirements, including weekly sales level thresholds. <a onClick={()=>this.switchShowEligible()}>See Qualification Details</a>&nbsp;for more info.</p>
                </React.Fragment>
        )
    }

    _renderSpanishDescription = () => {
        return (
                <React.Fragment>
                    <ul style={{paddingLeft: 14}}>
                        <li>Más de 240 mil personas* necesitan un mecánico a la semana.</li>
                        <li>Únete al programa para talleres referidos.</li>
                    </ul>

                    <p><small>*Visitas semanales de clientes con problemas mecánicos en su vehículo que compran refacciones en AutoZone</small></p>

                    <h2>Aprovecha esta gran oportunidad para incrementar tu negocio.</h2>

                    <span>Permítenos agregar tu negocio a nuestra lista de talleres referidos y obtén:</span>
                    <ul>
                        <li>Publicidad GRATIS como parte de nuestra lista de profesionales de confianza</li>
                        <li>Clientes potenciales, nuevos y recurrentes</li>
                        <li>La oportunidad de ofrecer cupones como incentivo</li>
                    </ul>

                    <h2 className="started-today">Inicia hoy mismo</h2>

                    <p>Revisa los requisitos para determinar si tu taller califica para participar, luego llena la información necesaria y te incluiremos dentro de nuestra lista de talleres referidos.</p>

                    {this._renderButton()}

                    <p><strong>*</strong>Los talleres participantes deberán reunir todos los requisitos, así como el nivel de compra semanal establecido. Para mas información consulta los <a onClick={()=>this.switchShowEligible()}>Términos y condiciones para calificar</a>.</p>
                </React.Fragment>
        )
    }

    render(){
        const {showEligible, status} = this.state;
        const {
            global: {locale}, 
            steps: {systemError}, 
            profile:{profileStatusLoaded, profileInfoLoaded, eligibilityRequestLoaded, profileStatusError}
        } = this.props
        const error = systemErrors.find(item => item.key === systemError)

        //This condition added to avoid jump from home page to error screen
        //request for profileInfo is called only if request profileStatus is success 
        if(((profileStatusLoaded && profileInfoLoaded)  || profileStatusError) 
            && eligibilityRequestLoaded
        ){
            return  (
                <div className={`homePage ${locale}`}>
                    <div className="body-header" >
                        <BodyHeader/>
                    </div>
                    {!!systemError && (<ErrorMessage messageText={error.message} />)}
                    {!systemError && (
                            <React.Fragment >
                                <Row>
                                    <Col sm={12} md={12}>
                                        <h1 className="big-title"> {Localization.print("GROW YOUR BUSINESS WITH AUTOZONE’S SHOP REFERRAL PROGRAM!")}</h1>
                                    </Col>
                                </Row>
                                <Row nogutter>
                                    <Col sm={6} md={6}>
                                        <div className="description" >
                                            {locale === 'es' ? this._renderSpanishDescription() : this._renderEnglishDescription()}
                                        </div>
                                    </Col>

                                    <Col sm={6} md={6}>
                                        <div style={{
                                            marginLeft: locale === 'es' ? 10 : 0
                                        }}>
                                            <div className={`mapImg ${locale}`} />
                                            <div className="contact">
                                                <span >{Localization.print("PROGRAM SUPPORT")}: </span><span style={{fontFamily: 'Arial'}}>
                                            {Localization.print("Phone: 866-853-6459")} </span><span className={'pipe'}>|</span>
                                                <a href="mailto:Electronic.Ordering@autozone.com" className={'link'}>{Localization.print("Email Us")}</a>
                                                <span className={'pipe'}>|</span><span>{Localization.print("Support Hours")}: 8am - 5pm CT </span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </React.Fragment>
                    )}

                    
                    {showEligible && <Portal>
                        <Eligible handler={this.switchShowEligible.bind(this)}/>
                        </Portal>
                    }

                </div>
                    
            )
        }
        return (
            <div className="spinnerContainer">
                <Spinner />
            </div>
        );
    }
}

export default connect(({global, profile, steps}) => ({global, profile, steps}), {getProfileStatus})(HomePage)

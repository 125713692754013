import React from 'react'
import './SimpleList.scss'

export default class SimpleList extends React.PureComponent {
    timer = null
    typedText = ''

    constructor (props) {
        super(props)
    }

    componentDidMount() {
        if (!this.props.stopKeyboardEvents) {
            this.scrollToSelected()
            document.addEventListener('keydown', this.handleKeyDown)
        }
    }

    componentWillUnmount() {
        if (!this.props.stopKeyboardEvents) {
            document.removeEventListener('keydown', this.handleKeyDown)
        }
    }

    handleKeyDown = e => {
        e.preventDefault()
        const {props: {handler}} = this
        const key = e.key.toLowerCase()
        const dropDown = this.list.parentElement

        if (!isNaN(e.key)) return

        // Arrows
        if (key === 'arrowup' || key === 'arrowdown') {
            let selected = this.list.querySelector('.elementSelected')
            let newSelected

            if (!selected) selected = this.list.querySelector('.dropDownElement')

            if (key === 'arrowup') {
                newSelected = selected.previousSibling
            } else if (key === 'arrowdown') {
                newSelected = selected.nextSibling
            }

            if (newSelected) {
                handler(newSelected.getAttribute('data-id'), false)
                dropDown.scrollTo(0, newSelected.offsetTop)
            }
        }

        // Typing
        if (this.timer) clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.typedText = ''
        }, 300)

        this.typedText += key
        if (!this.itemsArr) this.itemsArr = Array.from(this.list.childNodes)
        if (!this.arrValues) this.arrValues = this.itemsArr.map(item => item.textContent)

        const index = this.arrValues.findIndex(text => text.toLowerCase().startsWith(this.typedText.toLowerCase()))
        if (index !== -1) {
            handler(this.list.childNodes[index].getAttribute('data-id'), false)
            dropDown.scrollTo(0, this.list.childNodes[index].offsetTop)
        }
    }

    scrollToSelected () {
        if (!this.list) setTimeout(() => this.scrollToSelected(), 200)
        let selected = this.list.querySelector('.elementSelected')
        if (!selected) {
            selected = this.list.childNodes[0]
        }

        this.list.parentNode.scrollTo(0, selected.offsetTop)
        this.props.handler(selected.getAttribute('data-id'), false)
    }

    render() {

        const {items, handler, selected} = this.props

        return (
                <div ref={(node) => { this.list = node }}>
                    {items.map((item, index) => (
                            <div className={item.selected === true || (selected && selected === item.id) ? "dropDownElement elementSelected" : "dropDownElement"}
                                 key={index}
                                 data-id={item.id}
                                 onClick={() => handler(item.id)}>
                                {item.text}
                            </div>
                    ))}
                </div>
        )
    }
}

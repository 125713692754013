import React from 'react'
import {Visible} from 'react-grid-system'
import './box.scss'

const Box = (props) => (

        <div className="box" style={{
            width: props.width,              
            marginBottom: props.bottomMargin}}>
            <div className="header" style={{backgroundColor: props.backgroundColor || '#6c6f70', 
            color: props.color || '#fff', border: props.Border, borderBottom: props.bottomBorder,}}>
                                    <span className="title">
                                            {props.title.toUpperCase()}
                                    </span>
                {props.hint && (
                        <Visible xl lg>
                            <span className="hint">
                                {props.hint}                               
                            </span>
                        </Visible>
                )
                }

            </div>

            <div className="content" style={{backgroundColor: props.contentBackgroundColor}}>
                {props.children}
            </div>
        </div>
)

export default Box;
import React from 'react'
import './week.scss'
import {connect} from 'react-redux'
import {HOUR_SECTION} from '../../../../utilities/constants'
import Checkbox from '../../../suite/checkBox'
import TimePicker from './Day/timePicker'
import {setOneChangeDay} from '../../../../redux/actions'
import Localization from "../../../../utilities/localization";

const DayDescription=()=><React.Fragment>
                                {/* <div className="dayDescription"></div> */}
                                <span style={{marginTop: '12px'}}>{Localization.print("Open Hours")}</span>
                                <span style={{marginBottom: '0'}}>{Localization.print("Close Hours")}</span>
                        
                        </React.Fragment>

class Week extends React.PureComponent{ // this component needs to be conected to redux
// eslint-disable-next-line
    constructor(props)
    {
        super(props)
    }

    changeDay(item){
        this.props.setOneChangeDay(item)
    }  

    render(){

        const {week} = this.props

        return  <React.Fragment>

                    <table id="weekdayselect" cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th>
                                    &nbsp;
                                </th>
                                {
                                    week.map(item => ({...item, text: Localization.print(item.text), textSmall: Localization.print(item.textSmall)})).map((item,index)=>
                                        <th key={index}>
                                            <Checkbox item={item} handler={this.changeDay.bind(this)} />
                                        </th>
                                    )
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <DayDescription />
                                </td>

                                {
                                    week.map((item,index)=>
                                        <td key={index}>
                                            <TimePicker item={item} />    
                                        </td>   
                                                    
                                    )
                                }
                            </tr>
                        </tbody>
                    </table>
                    
                </React.Fragment>
    }
}

const mapStateToProps = state => ({
    week:state.steps.stepOne[HOUR_SECTION.week]
})

const mapDispatchToProps = dispatch => {
    return {
        setOneChangeDay: data => dispatch(setOneChangeDay(data))
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(Week)

import React from 'react'
import {Row, Col, ScreenClassRender} from 'react-grid-system'
import StepWrapper from '../../body/StepWrapper'
import BodyHeader from '../body-header'
import CheckBox from '../../suite/checkBox'
import './stepFinal.scss'
import {connect} from "react-redux";
import {canCompleteProcess} from "../../../redux/actions";
import {isIframe, scrollIframeToViewPort} from "../../../utilities/functions";
import {systemErrors} from "../../../utilities/constants";
import ErrorMessage from "../../suite/error-message";
import {setComponent} from "../../../redux/actions/global";

class StepFinal extends React.Component{
    // eslint-disable-next-line
    constructor(props){
        super(props)

        this.state = {
            checked: false
        }
    }
    
    componentDidMount() {
        if (isIframe()) {
            scrollIframeToViewPort('.Stepper')
        } else {
            window.scrollTo(0,0)
        }
        let name = 'Registration: Confirmation';
        this.props.setComponent(name);
    }

    handler = () => {
        const newValue = !this.state.checked
        this.props.canCompleteProcess(newValue)
        this.setState({checked: newValue})
    }

    goToTnc(){
        const {global: {locale}} = this.props
        let url = locale === 'es' ? '/az/termsncondition?v=mx' : '/az/termsncondition'
  
        if (isIframe()) {
            if (locale === 'es') {
                window.open(window.env.REACT_REDIRECT_URI + url, '_blank')
            } else {
                window.open(window.env.BASE_URL + '/info/terms/shopReferral.jsp', '_blank')
            }
        } else {
            window.open(url, "_blank")
        }
    }

    repairShop(){
        const url = ' https://www.autozone.com/landing/page.jsp?name=find-a-repair-shop';
        window.open(url, '_blank');
    }

    repairShopMX(){
        const url = 'https://shop-referral.autozonepro.com/az/findShop?v=mx';
        window.open(url, '_blank');
    }

    requestaccess(){
        const url = ' https://www.autozonepro.com/myzone/requestAccess/existingCustomerRequest.jsp';
        window.open(url, '_blank');
    }

    renderMXContent = () => {
        return (
                <div className="container">
                    <h2 className="check-icon">¡Felicidades!</h2>
                    <p>Haz completado y activado tu perfil en el Programa de Talleres Referidos.</p>
                    <p>Si tu taller está actualmente calificado, aparecerá inmediatamente dentro de la lista de "Encuentra un Taller" cuando una búsqueda coincida con tu perfil. Visita <a href="javascript:;" onClick={this.repairShopMX.bind(this)}>https://shop-referral.autozonepro.com/az/findShop?v=mx</a>  para buscar y ver tu taller.</p>
                    <p>Si aún no estás calificado, aparecerás en la lista tan pronto lo estés.</p>
                    <p>El estatus de tu calificación y los requerimientos los puedes consultar en la esquina superior derecha de esta página.</p>
                    <p style={{display: 'flex', alignItems: 'flex-start'}}>
                        <CheckBox item={{checked: this.state.checked}} handler={this.handler}/>

                        <span className="clickable" onClick={this.handler}>He leído y acepto los <a href="javascript:;" onClick={this.goToTnc.bind(this)}>Términos y Condiciones</a></span>
                    </p>
                </div>
        )
    }

    renderUSContent = () => {
        return (
                <div className="container">
                    <h2 className="check-icon">Congratulations!</h2>
                    <p>You have completed and activated your Referral Shop Profile.</p>
                    <p>If you are currently eligible to be listed on the Referral list, you will immediately begin displaying in the “Find a Repair Shop” search application when you match a search criteria. Visit <a href="javascript:;" onClick={this.repairShop.bind(this)}>FindARepairShop</a> to search your shop and view your listing.</p>

                    <p>If you are not eligible to be listed, you will be listed when you become eligible.</p>

                    <p>Eligibility status and qualifications requirements are available on the top right hand corner of this page.</p>
                    <p style={{display: 'flex', alignItems: 'flex-start'}}>
                        <CheckBox item={{checked: this.state.checked}} handler={this.handler}/>

                        <span className="clickable" onClick={this.handler}>I have read and agree to the <a href="javascript:;" onClick={this.goToTnc.bind(this)}>Terms and Conditions</a>
                                </span>
                    </p>
                </div>
        )
    }

    render(){
        const {global: {locale}, steps: {systemError}} = this.props
        const error = systemErrors.find(item => item.key === systemError)

        return  <ScreenClassRender render={screenClass => (
                <React.Fragment>
                    <div className="body-header" >
                        <BodyHeader/>
                    </div>
                    {!!systemError && (<ErrorMessage messageText={error.message} />)}
                    {!systemError && (
                            <StepWrapper screenClass={screenClass}>
                                <Row>
                                    <Col sm={12} md={12}>
                                        {locale === 'es' ? this.renderMXContent() : this.renderUSContent()}
                                    </Col>
                                </Row>
                            </StepWrapper>
                    )}
                </React.Fragment>
        )}/>
    }
}

export default connect(({global, steps}) => ({global, steps}),{canCompleteProcess, setComponent})(StepFinal)

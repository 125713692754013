import React from 'react'
import { Row, Col, Hidden, Visible } from 'react-grid-system';
import './body-header.scss'
import IconFaq from '../../../assets/faq';
import {withRouter} from 'react-router-dom'
import Portal from '../../portal'
import Eligible from './eligible'
import {connect} from "react-redux";
import { isIframe } from '../../../utilities/functions';
import Localization from "../../../utilities/localization";
import tealiumView from "../../../utilities/utag";

class BodyHeader extends React.Component{

    constructor(props){
        super(props)

        this.state={
            showEligible:false
        }
    }

    componentDidUpdate(prevProps)
    {
        
        if(this.props.global.componentName !== prevProps.global.componentName){
            if(this.props.global.componentName !== ''){
                this.calltealiumView(this.props.global.componentName)
            }
        }
    }

    calltealiumView(stepName)
    {
        const section = `Shop referral : ${stepName}`
        tealiumView(section, section);  
    }

    switchShowEligible(){
        this.setState( previous=>({
            showEligible:!previous.showEligible
        }))
    }

    goFaqPage = () => {
        const {global: {locale}} = this.props
        let url
        url = locale === 'es' ? '/az/faq-page?v=mx' : '/az/faq-page'
        if (isIframe()) {
            if (locale === 'es') {
                window.open(window.env.REACT_REDIRECT_URI + url, '_blank')
            } else {
                window.open(window.env.BASE_URL + '/info/shop-referral/faq.jsp', '_blank')
            }
        } else {
            window.open(url, "_blank")
        }
    }

    render(){

        const {showEligible} = this.state
        const {global: {locale}, hideControls, profile: {user}} = this.props

        return  <React.Fragment>
            <Row>
                <Col className={'col'}>
                    <h1> {Localization.print("Shop Referral")}</h1>
                    {!hideControls && (
                            <Visible sm xs>
                                <p style={{paddingRight: 8}}>
                                    <a onClick={this.goFaqPage} style={{textDecoration:'none', cursor: 'pointer'}}>
                                        <IconFaq width={23} height={21} />
                                        <span className="faq"> FAQ </span>
                                    </a>
                                </p>
                                <hr />
                            </Visible>
                    )}
                </Col>
                {!hideControls && (
                        <Col sm="content" md="content" className={'col'}>
                            <p>   
                                <span>{Localization.print("Average Weekly Purchases")}</span>
                                {/* <span>{locale === 'es' ? '$3,500.00' : '$300.00'}</span> */}
                                <span>${this.props.profile.eligibleValue}</span>
                            </p>

                            <p>
                        <span>{Localization.print("Referral Program Eligibility")}
                            <a className="details" onClick={()=>this.switchShowEligible()}>({Localization.print("See Details")})</a>
                        </span>
                                <span>{Localization.print("Current Eligible to be listed")} = {(user && user.eligible) ? Localization.print("Yes") : 'No'}</span>
                            </p>

                            <Hidden sm xs>
                                <p style={{paddingRight: 8}}>
                                    <a onClick={this.goFaqPage} style={{textDecoration:'none', cursor: 'pointer'}}>
                                        <IconFaq width={23} height={21} />
                                        <span className="faq"> FAQ </span>
                                    </a>
                                </p>
                            </Hidden>
                        </Col>
                )}
            </Row>
            

        {showEligible && <Portal>
            <Eligible handler={this.switchShowEligible.bind(this)}/>
        </Portal>}
        
       
        </React.Fragment>
    }
}

//export default withRouter(BodyHeader)
export default connect(
        ({global, profile}) => ({global, profile})
)(withRouter(BodyHeader))

import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Col, Row, Visible} from "react-grid-system"
import Input from "../../suite/input"
import {couponTypes} from "../../../utilities/constants"
import DateRange from "../../suite/date-range"
import {limitToLength} from "../../../utilities/functions"
import Localization from "../../../utilities/localization";

const getDateFromString = (dateString) => {
    let startDay, startMonth, startYear
    if (dateString.includes('/')) {
        [startMonth, startDay, startYear] = dateString.split('/')
    } else if (dateString.includes('-')) {
        [startYear, startMonth, startDay] = dateString.split('-')
    }
    return new Date(startYear, parseInt(startMonth) - 1, startDay)
}

class CouponForm extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            currentCoupon: null,
            minDate: new Date(),
            startDate: new Date(),
            endDate: new Date()
        }
    }

    static getDerivedStateFromProps (props, state) {
        if (props.steps.stepFour.currentCoupon !== state.currentCoupon && props.steps.stepFour.currentCoupon) {
            state.currentCoupon = props.steps.stepFour.currentCoupon

            const {steps: {stepFour: {currentCoupon}}} = props

            if (currentCoupon.startDate) {
                if (typeof currentCoupon.startDate.getTime === 'function') {
                    state.startDate = currentCoupon.startDate
                } else if (typeof currentCoupon.startDate === 'string') {
                    state.startDate = getDateFromString(currentCoupon.startDate)
                }
            }

            if (currentCoupon.endDate) {
                if (typeof currentCoupon.endDate.getTime === 'function') {
                    state.endDate = currentCoupon.endDate
                } else if (typeof currentCoupon.endDate === 'string') {
                    state.endDate = getDateFromString(currentCoupon.endDate)
                }
            }

            return state
        }

        return null
    }

    _getValueOffLimit = (type) => {
        return type === couponTypes.percent ? 2 : 7
    }

    _handleInputChange = (name, length, text) => {
        const {onInputChange} = this.props
        onInputChange(name, limitToLength(text, length))
    }

    render () {
        const {
            onInputChange,
            steps: {stepFour: {couponTypes: {items: couponTypeItems}, currentCoupon, errors}}
        } = this.props
        const {endDate, startDate} = this.state
        const now = new Date()
        const minDate = startDate.getTime() < now.getTime() ? startDate : now

        return (
                <React.Fragment>
                    <Row gutterWidth={16}>
                        <Col xl={4} lg={4} md={12} sm={12} className={'col'}>
                            <Input
                                    error={errors['name']}
                                    inputType={"text"}
                                    mandatory
                                    name={'name'}
                                    onChange={this._handleInputChange.bind(this, 'name', 40)}
                                    title={Localization.print("Coupon Name")}
                                    value={currentCoupon.name}
                            />
                        </Col>
                        <Col xl={4} lg={4} md={12} sm={12} className={'col'}>
                            {(currentCoupon.type === couponTypes.free || currentCoupon.type === couponTypes.special) ? (
                                            <DateRange
                                                error={errors['date']}
                                                mandatory 
                                                startLabelText={Localization.print("Start Date")}
                                                endLabelText={Localization.print("End Date")}
                                                onStartDateChange={onInputChange.bind(this, 'startDate')}
                                                onEndDateChange={onInputChange.bind(this, 'endDate')}
                                                minDate={minDate}
                                                startDate={startDate}
                                                endDate={endDate}
                                            />
                            ) : (
                                    <Input
                                            error={errors[(currentCoupon.type === couponTypes.dollar
                                                    ? 'dollarOff'
                                                    : 'percentOff')]}
                                            inputType={'number'}
                                            mandatory
                                            name={'valueOff'}
                                            onChange={text => {
                                                let fieldName = currentCoupon.type === couponTypes.dollar
                                                        ? 'dollarOff' : 'percentOff'
                                                onInputChange(fieldName, limitToLength(text, this._getValueOffLimit(currentCoupon.type)))
                                            }}
                                            title={Localization.print(couponTypeItems.find(el => el.value === currentCoupon.type).text)}
                                            value={currentCoupon[(currentCoupon.type === couponTypes.dollar ? 'dollarOff' : 'percentOff')]}
                                    />
                            )}
                        </Col>
                        {(currentCoupon.type === couponTypes.percent || currentCoupon.type === couponTypes.dollar) && (
                                <Col xl={4} lg={4} md={12} sm={12} className={'col'}>
                                    <DateRange
                                        error={errors['date']}
                                        mandatory
                                        startLabelText={Localization.print("Start Date")}
                                        endLabelText={Localization.print("End Date")}
                                        onStartDateChange={onInputChange.bind(this, 'startDate')}
                                        onEndDateChange={onInputChange.bind(this, 'endDate')}
                                        minDate={minDate}
                                        startDate={startDate}
                                        endDate={endDate}
                                    />
                                </Col>
                        )}
                    </Row>
                    <Row gutterWidth={16}>
                        <Col xl={4} lg={4} md={12} sm={12} className={'col last'}>
                            <Visible sm xs>
                                <Input
                                        error={errors['salesText']}
                                        height={170}
                                        info={`Max ${(160 - currentCoupon.salesText.length)} ${Localization.print("characters allowed")}`}
                                        mandatory
                                        multiline
                                        onChange={this._handleInputChange.bind(this, 'salesText', 160)}
                                        style={{marginBottom: 16}}
                                        title={Localization.print("Sales Text")}
                                        value={currentCoupon.salesText}
                                />
                            </Visible>
                            <Visible xl lg md>
                                <Input
                                        error={errors['salesText']}
                                        height={90}
                                        info={`Max ${(160 - currentCoupon.salesText.length)} ${Localization.print("characters allowed")}`}
                                        mandatory
                                        multiline
                                        onChange={this._handleInputChange.bind(this, 'salesText', 160)}
                                        title={Localization.print("Sales Text")}
                                        value={currentCoupon.salesText}
                                />
                            </Visible>
                        </Col>
                        <Col xl={4} lg={4} md={12} sm={12} className={'col last'}>
                            <Visible sm xs>
                                <Input
                                        height={170}
                                        info={`Max ${(254 - currentCoupon.disclaimerText.length)} ${Localization.print("characters allowed")}`}
                                        multiline
                                        onChange={this._handleInputChange.bind(this, 'disclaimerText', 254)}
                                        style={{marginBottom: 16}}
                                        title={Localization.print("Disclaimer Text")}
                                        value={currentCoupon.disclaimerText}
                                />
                            </Visible>
                            <Visible xl lg md>
                                <Input
                                        height={90}
                                        info={`Max ${(254 - currentCoupon.disclaimerText.length)} ${Localization.print("characters allowed")}`}
                                        multiline
                                        onChange={this._handleInputChange.bind(this, 'disclaimerText', 254)}
                                        style={{marginBottom: 16}}
                                        title={Localization.print("Disclaimer Text")}
                                        value={currentCoupon.disclaimerText}
                                />
                            </Visible>
                        </Col>
                    </Row>
                </React.Fragment>
        )
    }
}

CouponForm.propTypes = {
    onInputChange: PropTypes.func
}

export default connect(({steps}) => ({steps}))(CouponForm)

import React from 'react'
import {connect} from 'react-redux'
import './eligible.scss'
import Localization from "../../../../utilities/localization";

class Eligible extends React.Component {
    constructor(props){
        super(props)   
    }

emailUs(){
    const URL = 'https://www.autozonepro.com/info/contact/index.jsp'
    window.open(URL, '_blank');
}

emailRender()
{ 
    return(
        this.props.global.locale === 'es'
        ? <a href={'mailto:cliente.comercial@autozone.com'}>{Localization.print("Email Us")}</a>
        : <a href="javascript:;" onClick={this.emailUs.bind(this)}>{Localization.print("Email Us")}</a>
    )
}

render(){
    return (
            <div className="eligible" id="eligible-modal">

                <div className="header">
                    <span className="title"> {Localization.print("Referral Program Eligibility")}</span>
                    <span className="close" onClick={()=>this.props.handler()}> {Localization.print("CLOSE")} X</span>
                </div>

                <h1 className="support">
                    <span >{Localization.print("Program Support")}: </span>
                    <span>{Localization.print("Phone: 866-853-6459")} </span>
                    <span> | </span>
                    {/* <a href={email}> */}
                    {this.emailRender()}
                    <span> | </span>
                    <span>{Localization.print("Support Hours")}: 8 am - 5 pm CT </span>
                </h1>

                <div className="dashed">
                    {this.props.global.locale === 'es' ? (
                           <div className="content-es">
                               <span className="earn">PARA SER UN TALLER ELEGIDO DEBERÁS</span>

                               <ul>
                                   <li>Tener un promerio de compra semanal de al menos $3,500 + IVA pesos en AutoZone Profesional.</li>
                                   <li>O ser una Cuenta Nacional</li>
                                   <li>y estar al corriente con tu crédito.</li>
                               </ul>
                           </div>
                    ) : (
                            <div>
                                <span className="earn">EARN YOUR PLACE ON THE LIST</span>

                                <div className="price">
                                    <h1 className="value">$300</h1>
                                    <p>Average weekly sales of $300 or more.</p>
                                </div>

                                <span className="approved">&amp; Approved Shop Types</span>

                                <p>Shop Types: General Repair, Wheel & Tire, Oil & Lube, Auto Body, Foreign/Specialty</p>
                            </div>
                    )}
                </div>
            </div>
    )
  }
}
export default connect(({global}) => ({global}))(Eligible)


import { tealiumLink } from './utag';

const clickTrack = {
    findRepairShopClicks: (linkName, linkData) => {
        let data = {
            pageType: 'az:shop referral',
            pageName: 'az:Find A Repair Shop:Home',
            findRepairShopPins: linkData.pin,
        };

        switch (linkName) {
            case 'getDirections': 
                data.findRepairShopClicks = 'Get Directions';
                data.eventType = 'DirectionUsage';
                data.customLinkName = 'Get Directions - Find a Repair Shop Near You';
                break;
            case 'applyFilter': 
                data.findRepairShopClicks = `Filters:${linkData.selectedFilters}`;
                data.eventType = 'ApplyFilterFindRepair';
                data.customLinkName = 'ApplyFilter - Find a Repair Shop Near You';
                break;
            case 'print': 
                data.findRepairShopClicks = 'Print - Find a Repair Shop Near You';
                data.eventType = 'Printfindarepairshop';
                data.customLinkName = 'Print Page - Find a Repair Shop Near You';
                break;
        }
        tealiumLink(data);
    },
    shopDetailsClicks: (linkName, linkData) => {
        let data = {
            pageType: 'az:shop referral:Shop details',
            pageName: `az:Find A Repair Shop:${linkData.shopName}`,
            findRepairShopPins: linkData.pin,
        };
        switch (linkName) {
            case 'getDirections':
                data.findRepairShopClicks = 'Get Directions';
                data.eventType = 'DirectionUsage';
                data.customLinkName = 'Get Directions - Find a Repair Shop Near You';
                break;
            case 'viewCoupon':
                data.eventType = 'PrintViewCoupon';
                data.findRepairShopClicks = 'View Coupon';
                data.customLinkName = 'View Coupon - Find a Repair Shop Near You';
                break;
            case 'printCoupon': 
                data.eventType = 'PrintViewCoupon';
                data.findRepairShopClicks = 'Print Coupon';
                data.customLinkName = 'Print Coupon - Find a Repair Shop Near You';
                break;
            case 'print': 
                data.eventType = 'Printfindarepairshop';
                data.findRepairShopClicks = 'Print - Find a Repair Shop Near You';
                data.customLinkName = 'Print Page - Find a Repair Shop Near You';
                break;
        } 
        tealiumLink(data);
    }
};

export default clickTrack;
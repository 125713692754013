export const SET_NETWORK_STATE = 'SET_NETWORK_STATE'
export const SET_MODAL_STATE = 'SET_MODAL_STATE'
export const SET_LOCALE = 'SET_LOCALE'
export const SET_COMPONENT = 'SET_COMPONENT'

export function setNetworkState (connected) {
    return {
        type: SET_NETWORK_STATE,
        payload: connected
    }
}

export function setModalState (active) {
    return {
        type: SET_MODAL_STATE,
        payload: active
    }
}

export function setLocale (locale) {
    return {
        type: SET_LOCALE,
        payload: locale
    }
}

export function setComponent (name) {
    return {
        type: SET_COMPONENT,
        payload: name
    }
}

import {
    CAN_COMPLETE_PROCESS,
    UPDATE_STEP,
    STEP_TWO_UPDATE_VALUE,
    STEP_TWO_SWITCH_ALL,
    STEP_TWO_CHANGE_OTHER,
    STEP_THREE_CHANGE_OTHER,
    STEP_THREE_SWITCH_ALL,
    STEP_THREE_UPDATE_VALUE,
    STEP_THREE_CHANGE_RADIO_VALUE,
    STEP_ONE_LANGUAGE_SWITCH_ALL,
    STEP_ONE_LANGUAGE_UPDATE_VALUE,
    STEP_ONE_CHANGE_TIME_ZONE,
    STEP_ONE_CHANGE_DAY,
    STEP_ONE_CHANGE_HOURS,
    STEP_ONE_PROFILE_DATA,
    STEP_ONE_PROFILE_PROPERTY,
    STEP_ONE_SET_ERROR,
    STEP_ONE_CLEAR_ERROR,
    SET_STEPS_DATA,
    RESET_STEPS_DATA
} from '../actions/types'
import {GET_TOKEN_INFO_SUCCESS} from '../actions/token'
import {
    ADD_COUPON,
    CLEAR_COUPON_ERROR, CLEAR_CURRENT_COUPON, RECOVER_COUPON, REMOVE_COUPON,
    SET_COUPON_ERROR,
    SET_CURRENT_COUPON,
    SET_CURRENT_PROPERTY,
    UPDATE_COUPON
} from '../actions/coupons'
import {
    GET_PROFILE_INFO_SUCCESS,
    GET_PROFILE_INFO_ERROR,
    UPDATE_PROFILE_REQUEST, GET_PROFILE_INFO_REQUEST
} from "../actions/profile";
import {
    AFFILIATION_SECTION,
    SERVICES_SECTION,
    HOUR_SECTION,
    shopTypes,
    COUPONS_SECTION, couponTypes, TEALIUM, defaultHours
} from '../../utilities/constants'
import {getStringHour, isClosedDay, isValidProperty, ObjectWithoutKeys} from "../../utilities/functions";
import Coupon from "../../models/Coupon";
import {SET_NETWORK_STATE} from "../actions/global";
import {getStateAbbreviationForDisplay} from '../../utilities/functions';


const initialState = {
    canComplete: false,
    currentStep: 1,
    fullCover: false,
    systemError: '',
    profileError: '',
    stepOne: {
        'language': {
            items: [
                {id: 1, index: 1,  text: 'Arabic', value: 'ARABIC', half: 1, section: HOUR_SECTION.language, checked: false},
                {id: 2, index: 2,  text: 'Chinese', value: 'CHINESE', half: 1, section: HOUR_SECTION.language, checked: false},
                {id: 3, index: 3, index_es: 2,  text: 'English', es: 'Inglés', value: 'ENGLISH', half: 1, half_es: 2, section: HOUR_SECTION.language, checked: false},
                {id: 4, index: 4,  text: 'French', value: 'FRENCH', half: 1, section: HOUR_SECTION.language, checked: false},
                {id: 5, index: 5,  text: 'German', value: 'GERMAN', half: 1, section: HOUR_SECTION.language, checked: false},
                {id: 9, index: 6,  text: 'Hindi-Urdu', value: 'HINDI', half: 1, section: HOUR_SECTION.language, checked: false},
                {id: 14, index: 7, text: 'Hmong', value: 'HMONG', half: 1, section: HOUR_SECTION.language, checked: false},
                {id: 15, index: 8, text: 'Italian', value: 'ITALIAN', half: 1, section: HOUR_SECTION.language, checked: false},
                {id: 10, index: 9, text: 'Japanese', value: 'JAPAN', half: 2, section: HOUR_SECTION.language, checked: false},
                {id: 8, index: 10,  text: 'Korean', value: 'KOREAN', half: 2, section: HOUR_SECTION.language, checked: false},
                {id: 11, index: 11, text: 'Portuguese', value: 'PORTGES', half: 2, section: HOUR_SECTION.language, checked: false},
                {id: 12, index: 12, text: 'Russian', value: 'RUSSIAN', half: 2, section: HOUR_SECTION.language, checked: false},
                {id: 16, index: 13, text: 'Somalian', value: 'SOMALN', half: 2, section: HOUR_SECTION.language, checked: false},
                {id: 13, index: 14, index_es: 1, text: 'Spanish', es: 'Español', value: 'SPANISH', half: 2, half_es: 1, section: HOUR_SECTION.language, checked: false},
                {id: 7, index: 15,  text: 'Tagalog', value: 'TAGALOG', half: 2, section: HOUR_SECTION.language, checked: false},
                {id: 6, index: 16,  text: 'Vietnamese', value: 'VIETNM', half: 2, section: HOUR_SECTION.language, checked: false}

            ]
            , other: ''
        },
        "timeZone": [
            {text: 'Eastern', value: 'EASTERN', id: 1, selected: false},
            {text: 'Central', value: 'CENTRAL', id: 2, selected: false},
            {text: 'Mountain', value: 'MOUNTAIN', id: 3, selected: false},
            {text: 'Pacific', value: 'PACIFIC', id: 4, selected: false}
        ],
        "week": [
            {id: 1, text: "Monday", textSmall: "Mon", open: defaultHours.open, close: defaultHours.close, checked: false},
            {id: 2, text: "Tuesday", textSmall: "Tue", open: defaultHours.open, close: defaultHours.close, checked: false},
            {id: 3, text: "Wednesday", textSmall: "Wed", open: defaultHours.open, close: defaultHours.close, checked: false},
            {id: 4, text: "Thursday", textSmall: "Thu", open: defaultHours.open, close: defaultHours.close, checked: false},
            {id: 5, text: "Friday", textSmall: "Fri", open: defaultHours.open, close: defaultHours.close, checked: false},
            {id: 6, text: "Saturday", textSmall: "Sat", open: defaultHours.open, close: defaultHours.close, checked: false},
            {id: 7, text: "Sunday", textSmall: "Sun", open: defaultHours.open, close: defaultHours.close, checked: false}
        ],
        /**
         * Here goes all the info related to
         * the profile info form in step one
         */
        "profileInfo": {
            customerID: '',
            accountNumber: '',
            city: '',
            fax: '',
            hideShop: false,
            mobilePhoneNumber: '',
            shopAddress: '',
            shopContactName: '',
            shopContactEmail: '',
            shopName: '',
            shopPicture: '',
            shopPhoneNumber: '',
            shopType: 2,
            shopWebAddress: '',
            state: '',
            stateCodeForDisplay: '',
            zipCode: '',
            croppedPicture: '',
            croppedData: '',
            filled: false
        },

        /**
         * Errors object for profile info screen
         */
        "errors": {},

        /**
         * The fields that should be validated
         */
        "requiredFields": [
            'shopAddress',
            'shopContactEmail',
            //'shopType',
            'shopName',
            'city',
            'state',            
            'shopPhoneNumber',
            'shopContactName',
            'zipCode'
        ]
    },
    stepTwo: {
        "certifications": {
            items: [
                {id: 1, es: 'A.M.P.S.A.S.', value: 'AMPSAS', half: 1, section: AFFILIATION_SECTION.certifications, checked: false},
                {id: 2, text: 'ASE Certified', es: 'ASE', value: 'ASECERT', half: 1, section: AFFILIATION_SECTION.certifications, checked: false},
                {id: 3, text: 'ASE Master Technician', value: 'ASEMASTERTECH', half: 1, section: AFFILIATION_SECTION.certifications, checked: false},
            ],
            other: ''
        },
        "affiliation": {
            items: [
                {id: 1, text: 'AASP', value: 'AASP', half: 1, section: AFFILIATION_SECTION.affiliation, checked: false},
                {id: 3, text: 'ASC', value: 'ASC', half: 1, section: AFFILIATION_SECTION.affiliation, checked: false},
                {id: 4, text: 'iATN', value: 'IATN', half: 1, section: AFFILIATION_SECTION.affiliation, checked: false},
                {id: 5, text: 'AAA', value: 'AAA', half: 1, section: AFFILIATION_SECTION.affiliation, checked: false},
            ]
        },
        "amenities": {
            items: [
                {id: 1, text: 'Loaner Vehicle', es: 'Préstamo de Vehículos', value: 'LOANVEH', half: 1, section: AFFILIATION_SECTION.amenities, checked: false},
                {id: 2, text: 'Towing / Wrecker Service', es: 'Servicio de Grua y/o Remolque', value: 'TOWWRECK', half: 1, section: AFFILIATION_SECTION.amenities, checked: false},
                {id: 3, text: 'Waiting Area', value: 'WAITAREA', half: 1, section: AFFILIATION_SECTION.amenities, checked: false},
                {id: 4, text: 'Wifi Available', es: 'Internet Wifi disponible', value: 'WIFIAVL', half: 1, section: AFFILIATION_SECTION.amenities, checked: false}
            ],
            other: ''
        }
    },
    stepThree: {
        'shopType': {
            items: [
                //{id: 1, text: 'Approved Vehicle Repair', value: 'APPRVEHREPAIR', half: 1, section: SERVICES_SECTION.shopType, checked: false},
                {id: 2, text: 'Emission Inspection', es: 'Inspección de Emisiones', value: 'EMISSINSPEC', half: 1, section: SERVICES_SECTION.shopType, checked: false},
                {id: 3, text: 'Emission Repair', es: 'Reparación de Emisiones', value: 'EMISSREPAIR', half: 1, section: SERVICES_SECTION.shopType, checked: false},
                {id: 4, text: 'License Inspection', value: 'LICENSEINSPEC', half: 1, section: SERVICES_SECTION.shopType, checked: false},
                {id: 5, text: 'State Inspection', value: 'STATEINSPEC', half: 1, section: SERVICES_SECTION.shopType, checked: false},
                {id: 6, text: 'Safety Inspection', es: 'Inspección de Seguridad', value: 'SAFETYINSPEC', half: 1, section: SERVICES_SECTION.shopType, checked: false}
            ],
            other: ''
        },
        "servicesPerformed": {
            items: [
                {id: 1, text: 'Air Conditioning & Heating', es: 'Aire Acondicionado y Calefacción', value:'HAC', half: 1, section: SERVICES_SECTION.servicesPerformed, checked: false},
                {id: 2, text: 'Alignment', es: 'Alineación', value: 'ALIGN', half: 1, section: SERVICES_SECTION.servicesPerformed, checked: false},
                {id: 3, text: 'Battery', es: 'Batería', value: 'BATT', half: 1, section: SERVICES_SECTION.servicesPerformed, checked: false},
                {id: 4, text: 'Brake', es: 'Frenos', value: 'BRAKE', half: 1, section: SERVICES_SECTION.servicesPerformed, checked: false},
                {id: 5, text: 'Collision Repair', es: 'Reparación de Partes de Colisión', value: 'COLLREPAIR', half: 1, section: SERVICES_SECTION.servicesPerformed, checked: false},
                {id: 6, text: 'Cooling Systems', es: 'Sistema de Enfriamiento', value: 'COOLSYS', half: 1, section: SERVICES_SECTION.servicesPerformed, checked: false},
                {id: 7, text: 'Engine Repair', es: 'Reparación de Motor', value: 'ENGREPAIR', half: 1, section: SERVICES_SECTION.servicesPerformed, checked: false},
                {id: 8, text: 'Exhaust', es: 'Escape', value: 'EXHAUST', half: 1, section: SERVICES_SECTION.servicesPerformed, checked: false},
                {id: 9, text: 'Fuel System', es: 'Sistema de Combustible', value: 'FUELSYS', half: 1, section: SERVICES_SECTION.servicesPerformed, checked: false},
                {id: 10, text: 'Glass Repair', es: 'Reparación de Vidrios y Ventanas', value: 'GLASSREPAIR', half: 1, section: SERVICES_SECTION.servicesPerformed, checked: false},
                {id: 11, text: 'Maintenance Services', es: 'Servicios de Mantenimiento', value: 'MAINSERVICE', half: 2, section: SERVICES_SECTION.servicesPerformed, checked: false},
                {id: 12, text: 'Oil Change', es: 'Cambio de Aceite', value: 'OILCHG', half: 2, section: SERVICES_SECTION.servicesPerformed, checked: false},
                {id: 13, text: 'Radiator Repair', es: 'Reparación del Radiador', value: 'RADREPAIR', half: 2, section: SERVICES_SECTION.servicesPerformed, checked: false},
                {id: 14, text: 'Steering & Suspension / Strut Repair', es: 'Reparaciones de la suspensión, tren motriz y/o amortiguadores', value: 'STEERSUSPSTRUCREPAIR', half: 2, section: SERVICES_SECTION.servicesPerformed, checked: false},
                {id: 15, text: 'Starting & Charging System', es: 'Sistema de Carga y Arranque', value: 'STARTCHRGSYS', half: 2, section: SERVICES_SECTION.servicesPerformed, checked: false},
                {id: 16, text: 'Tire Sales', es: 'Venta de Llantas', value: 'TIRESALES', half: 2, section: SERVICES_SECTION.servicesPerformed, checked: false},
                {id: 17, text: 'Transmission Repair', es: 'Reparación de la Transmisión', value: 'TRANSREPAI', half: 2, section: SERVICES_SECTION.servicesPerformed, checked: false},
                {id: 18, text: 'Tune-Up', es: 'Afinación', value: 'TUNEUP', half: 2, section: SERVICES_SECTION.servicesPerformed, checked: false},
                {id: 19, text: 'Vehicle Inspection', es: 'Inspección Vehicular', value: 'VEHICLEINSPEC', half: 2, section: SERVICES_SECTION.servicesPerformed, checked: false},
                //{id: 20, text: 'Install Customer Purchased Parts', value: 'CUSTINSTPARTS', half: 2, section: SERVICES_SECTION.servicesPerformed, checked: false},
            ]
            , other: ''
        },
        "vehicleServiced": {
            items: [
                {id: 1, text: 'Asian', es: 'Asiáticos', value: 'ASIAN', half: 1, section: SERVICES_SECTION.vehicleServiced, checked: false},
                {id: 2, es: 'Americanos', value: 'AMERICAN', half: 1, section: SERVICES_SECTION.vehicleServiced, checked: false},
                {id: 3, text: 'Domestic', es: 'Nacionales', value: 'DOMESTIC', half: 1, section: SERVICES_SECTION.vehicleServiced, checked: false},
                {id: 4, text: 'European', es: 'Europeos', value: 'EUROPEAN', half: 1, section: SERVICES_SECTION.vehicleServiced, checked: false},
                {id: 5, text: 'Electric', es: 'Eléctricos', value: 'ELECTRIC', half: 1, section: SERVICES_SECTION.vehicleServiced, checked: false},
                {id: 6, text: 'Hybrid', es: 'Híbridos', value: 'HYBRID', half: 1, section: SERVICES_SECTION.vehicleServiced, checked: false},
            ]
            , other: ''
        }
    },
    stepFour: {
        currentCoupon: {
            id: null,
            name: '',
            disclaimerText: '',
            dollarOff: '',
            endDate: (new Date()).toLocaleDateString(),
            percentOff: '',
            salesText: '',
            startDate: (new Date()).toLocaleDateString(),
            type: couponTypes.free,
            valueOff: 0
        },
        coupons: [],
        couponTypes: {
            items: [
                {id: 1, text: 'Free', value: couponTypes.free, section: COUPONS_SECTION.coupons, checked: false},
                {id: 2, text: 'Percent Off', value: couponTypes.percent, section: COUPONS_SECTION.coupons, checked: false},
                {id: 3, text: 'Dollar Off', value: couponTypes.dollar, section: COUPONS_SECTION.coupons, checked: false},
                {id: 4, text: 'Special', value: couponTypes.special, section: COUPONS_SECTION.coupons, checked: false}
            ]
        },
        errors: {}
    }
}

export default function (state = initialState, action) {
    let item, index
    let copy = JSON.parse(JSON.stringify(state))

    switch (action.type) {
        case CAN_COMPLETE_PROCESS:
            return {
                ...state,
                canComplete: action.payload
            }

        case UPDATE_STEP:
            return {
                ...state, currentStep: action.payload
            }

        case RESET_STEPS_DATA:
            return initialState

        case STEP_TWO_UPDATE_VALUE:

            copy.stepTwo[action.payload.section].items.forEach(c => {
                if (c.id === action.payload.id) {
                    c.checked = !c.checked
                }

            })

            return copy;

        case STEP_TWO_SWITCH_ALL:

            const propertyTwo = action.payload.data.locale === 'es' ? 'es' : 'text'
            const localTwo = copy.stepTwo[action.payload.section].items.filter(el => !!el[propertyTwo])
            if(action.payload.data.value === true){
                for(let i=0;i<localTwo.length;i++){
                    copy.stepTwo[action.payload.section].items.forEach(c => {
                        if (localTwo[i].id === c.id ){
                            c.checked = true;
                        }
                    })
                }
            }
            else{
                copy.stepTwo[action.payload.section].items.forEach(c => {
                    c.checked = false;
                })
            }

            // copy.stepTwo[action.payload.section].items.forEach(c => {
            //     c.checked = action.payload.value
            // })

            return copy;

        case STEP_TWO_CHANGE_OTHER:

            return {
                ...state, stepTwo: {
                    ...state.stepTwo, [action.payload.section]: {
                        ...state.stepTwo[action.payload.section], other: action.payload.value
                    }
                }
            }

        case STEP_THREE_UPDATE_VALUE:

            copy.stepThree[action.payload.section].items.forEach(c => {
                if (c.id === action.payload.id) {
                    c.checked = !c.checked
                }

            })

            return copy;

        case STEP_THREE_SWITCH_ALL:
            const property = action.payload.data.locale === 'es' ? 'es' : 'text'
            const localizedCollection = copy.stepThree[action.payload.section].items.filter(el => !!el[property])
            if(action.payload.data.value === true){
                for(let i=0;i<localizedCollection.length;i++){
                    copy.stepThree[action.payload.section].items.forEach(c => {
                        if (localizedCollection[i].id === c.id ){
                            c.checked = true;
                        }
                    })
                }
            }
            else{
                copy.stepThree[action.payload.section].items.forEach(c => {
                    c.checked = false;
                })
            }

            // copy.stepThree[action.payload.section].items.forEach(c => {
            //     c.checked = action.payload.value
            // })

            return copy;

        case STEP_THREE_CHANGE_RADIO_VALUE:

            copy.stepThree['shopType'].items.forEach(c => {
                c.checked = action.payload !== c.id ? false : true
            })

            return copy;

        case STEP_THREE_CHANGE_OTHER:

            return {
                ...state, stepThree: {
                    ...state.stepThree, [action.payload.section]: {
                        ...state.stepThree[action.payload.section], other: action.payload.value
                    }
                }
            }

        case STEP_ONE_CLEAR_ERROR:
            if (action.payload.all) copy.stepOne.errors = {}
            else if (action.payload.key && copy.stepOne.errors[action.payload.key]) {
                copy.stepOne.errors = Object.keys(copy.stepOne.errors)
                        .filter(key => key !== action.payload.key)
                        .reduce((obj, key) => ({...obj, [key]: copy.stepOne.errors[key]}), {})
            }

            return copy;

        case STEP_ONE_LANGUAGE_UPDATE_VALUE:
            const collection = copy.stepOne.language.items
            // When it comes as an array that means that is coming from a
            // native select control so we clean the previous selection
            // and update the store with the new one
            if (Array.isArray(action.payload)) {
                collection.forEach(item => { item.checked = false })
                action.payload.forEach(option => {
                    const el = collection.find(item => item.id === option.id)
                    if (el) el.checked = true
                })
            }
            // Normal selection through the custom component
            else {
                item = collection.find(item => item.id === action.payload.id)
                if (item) item.checked = !item.checked
            }

            return copy;

        case STEP_ONE_LANGUAGE_SWITCH_ALL:
            const propertyOne = action.payload.data.locale === 'es' ? 'es' : 'text'
            const localOne = copy.stepOne[action.payload.section].items.filter(el => !!el[propertyOne])
            if(action.payload.data.value === true){
                for(let i=0;i<localOne.length;i++){
                    copy.stepOne[action.payload.section].items.forEach(c => {
                        if (localOne[i].id === c.id ){
                            c.checked = true;
                        }
                    })
                }
            }
            else{
                copy.stepOne[action.payload.section].items.forEach(c => {
                    c.checked = false;
                })
            }
            // copy.stepOne[action.payload.section].items.forEach(c => {
            //     c.checked = action.payload.value
            // })

            return copy;

        case STEP_ONE_CHANGE_TIME_ZONE:

            copy.stepOne[HOUR_SECTION.timeZone].forEach(c => {
                if (c.id === action.payload)
                    c.selected = true
                else
                    c.selected = false
            })

            return copy;

        case STEP_ONE_CHANGE_DAY:

            copy.stepOne[HOUR_SECTION.week].forEach(c => {
                if (c.id === action.payload.id)
                    c.checked = !c.checked
            })

            return copy;


        case STEP_ONE_CHANGE_HOURS:

            copy.stepOne[HOUR_SECTION.week].forEach(c => {
                if (c.id === action.payload.id)
                    if (action.payload.open)
                        c.open = action.payload.text
                    else
                        c.close = action.payload.text
            })

            return copy;

        /**
         * Replaces all the current info with the incoming data
         */
        case STEP_ONE_PROFILE_DATA:
            copy.stepOne.profileInfo = action.payload;
            return copy;

        /**
         * Assigns the value for a specific key in profile info object
         */
        case STEP_ONE_PROFILE_PROPERTY:
            if (copy.stepOne.profileInfo.hasOwnProperty(action.payload.key))
                copy.stepOne.profileInfo[action.payload.key] = action.payload.value;
            if (copy.stepOne.errors.hasOwnProperty(action.payload.key) && isValidProperty(action.payload.value)) {
                copy.stepOne.errors = ObjectWithoutKeys(copy.stepOne.errors, [action.payload.key])
            }
            return copy;

        /**
         * Assigns the error for a specific property in profile info
         */
        case STEP_ONE_SET_ERROR:
            copy.stepOne.errors[action.payload.key] = action.payload.errorType
            return copy;

        case GET_TOKEN_INFO_SUCCESS:
            copy.stepOne.profileInfo.customerID = parseInt(action.payload.claims.currentpin)
            return copy

        case GET_PROFILE_INFO_SUCCESS:
            copy.systemError = null
            const data = action.payload          
            if (data.shopName) {
                copy.stepOne.profileInfo.shopName = data.shopName
                sessionStorage.setItem(TEALIUM.shop_status, 'TRUE')
            } else{
                    sessionStorage.setItem(TEALIUM.shop_status, 'FALSE')  
            }
                if (data.shopPicture && data.shopPicture.image) {
                    copy.stepOne.profileInfo.croppedPicture = null
                    copy.stepOne.profileInfo.croppedData = null
                    copy.stepOne.profileInfo.shopPicture = `data:image/png;base64,${data.shopPicture.image}`
                }
                if (data.contactInfo) {
                    if (data.contactInfo.shopContactName) copy.stepOne.profileInfo.shopContactName = data.contactInfo.shopContactName
                    if (data.contactInfo.fax) copy.stepOne.profileInfo.fax = data.contactInfo.fax
                    if (data.contactInfo.email) copy.stepOne.profileInfo.shopContactEmail = data.contactInfo.email
                    if (data.contactInfo.shopNumber) copy.stepOne.profileInfo.shopPhoneNumber = data.contactInfo.shopNumber
                    if (data.contactInfo.mobileNumber) copy.stepOne.profileInfo.mobilePhoneNumber = data.contactInfo.mobileNumber
                }
                if (data.address) {
                    copy.shopAddress = ''
                    if (data.address.addressLine1) copy.stepOne.profileInfo.shopAddress = data.address.addressLine1
                    //if (data.address.addressLine2) copy.stepOne.profileInfo.shopAddress += ', ' + data.address.addressLine2
                    if (data.address.city) copy.stepOne.profileInfo.city = data.address.city
                    if (data.address.state) {
                        
                        const stateAbbreviation = getStateAbbreviationForDisplay(data.address.state, data.locale);
                        // for some reason if state abbrevation cannot be found then reset state to empty 
                        // so that user can select correct state.
                        // this may happen in dev env when you are switching to mx version and 
                        // previously have a us state selected or vice versa
                        if (stateAbbreviation === '') {
                            copy.stepOne.profileInfo.state = '';
                        } else {
                            copy.stepOne.profileInfo.state = data.address.state;
                        }
                        copy.stepOne.profileInfo.stateCodeForDisplay = stateAbbreviation;
                    }
                    if (data.address.zip) copy.stepOne.profileInfo.zipCode = data.address.zip

                }
                if (data.shopWebAddress && data.shopWebAddress.trim().toLowerCase() !== 'none specified')
                    copy.stepOne.profileInfo.shopWebAddress = data.shopWebAddress
                if (data.shopType) {
                    const shopType = shopTypes.find(item => item.value === data.shopType)
                    if (shopType) copy.stepOne.profileInfo.shopType = shopType.id
                }
                if (data.languages) {
                    data.languages.forEach(value => {
                        const language = copy.stepOne.language.items.find(item => item.value === value)
                        if (language) language.checked = true
                    })
                }
                if (data.shopHours && data.shopHours.dayHours) {
                    Object.keys(data.shopHours.dayHours).forEach((day) => {
                        const _day = copy.stepOne.week.find(item => (day.toLowerCase() === item.text.toLowerCase()))
                        const _openStrHour = getStringHour(data.shopHours.dayHours[day].openHour)
                        const _closeStrHour = getStringHour(data.shopHours.dayHours[day].closeHour)
                        const _isClosed = isClosedDay(_openStrHour, _closeStrHour)
                        if (_day) {
                            _day.checked = !_isClosed
                            _day.close = _isClosed ? defaultHours.close : _closeStrHour
                            _day.open = _isClosed ? defaultHours.open : _openStrHour
                        }
                    })
                }
                if (data.shopCertifications) {
                    data.shopCertifications.forEach(value => {
                        const _certification = copy.stepTwo.certifications.items.find(item => item.value === value)
                        if (_certification) _certification.checked = true
                    })
                }
                if (data.shopMemberships) {
                    data.shopMemberships.forEach(value => {
                        const _membership = copy.stepTwo.affiliation.items.find(item => item.value === value)
                        if (_membership) _membership.checked = true
                    })
                }
                if (data.shopAmenities) {
                    data.shopAmenities.forEach(value => {
                        const _amenity = copy.stepTwo.amenities.items.find(item => item.value === value)
                        if (_amenity) _amenity.checked = true
                    })
                }
                if (data.servicesPerformed) {
                    data.servicesPerformed.forEach(value => {
                        const _service = copy.stepThree.servicesPerformed.items.find(item => item.value === value)
                        if (_service) _service.checked = true
                    })
                }
                if (data.vehiclesServiced) {
                    data.vehiclesServiced.forEach(value => {
                        const _service = copy.stepThree.vehicleServiced.items.find(item => item.value === value)
                        if (_service) _service.checked = true
                    })
                }
                if (data.shopRegulatoryServices) {
                    data.shopRegulatoryServices.forEach(value => {
                        const _service = copy.stepThree.shopType.items.find(item => item.value === value)
                        if (_service) _service.checked = true
                    })
                }
                if (data.coupons) {
                    copy.stepFour.coupons = data.coupons.map(coupon => new Coupon(coupon))
                    sessionStorage.setItem(TEALIUM.coupon_count, copy.stepFour.coupons.length.toString())
                }
            return copy

        case SET_STEPS_DATA:
            const {
                payload: {
                    affiliation,
                    amenities,
                    certification,
                    language,
                    profileInfo,
                    regulatoryServices,
                    servicesPerformed,
                    timeZone,
                    vehicleServiced,
                    week
                }
            } = action

            // PROFILE
            if (profileInfo) {
                copy.stepOne.profileInfo = profileInfo
            }

            // AFFILIATIONS
            if (affiliation && affiliation.items) {
                const _affiliations = affiliation.items.map(item => item.id)
                copy.stepTwo.affiliation.items.forEach(item => {
                    item.checked = (_affiliations.includes(item.id))
                })
            }

            // AMENITIES
            if (amenities && amenities.items) {
                const _amenities = amenities.items.map(item => item.id)
                copy.stepTwo.amenities.items.forEach(item => {
                    item.checked = _amenities.includes(item.id)
                })
                copy.stepTwo.amenities.other = amenities.other
            }

            // CERTIFICATIONS
            if (certification && certification.items) {
                const _certifications = certification.items.map(item => item.id)
                copy.stepTwo.certifications.items.forEach(item => {
                    item.checked = _certifications.includes(item.id)
                })
                copy.stepTwo.certifications.other = certification.other
            }

            // LANGUAGES
            if (language && language.items) {
                const _languages = language.items.map(item => item.id)
                copy.stepOne.language.items.forEach(item => {
                    item.checked = _languages.includes(item.id)
                })
                copy.stepOne.language.other = language.other
            }

            // REGULATORY SERVICES
            if (regulatoryServices && regulatoryServices.items) {
                const _regulatoryServices = regulatoryServices.items.map(item => item.id)
                copy.stepThree.shopType.items.forEach(item => {
                    item.checked = _regulatoryServices.includes(item.id)
                })
            }

            // SERVICES PERFORMED
            if (servicesPerformed && servicesPerformed.items) {
                const _servicesPerformed = servicesPerformed.items.map(item => item.id)
                copy.stepThree.servicesPerformed.items.forEach(item => {
                    item.checked = _servicesPerformed.includes(item.id)
                })
            }

            // TIME ZONE
            if (timeZone && timeZone.items) {
                const _timeZones = timeZone.items.map(item => item.id)
                copy.stepOne.timeZone.forEach(item => {
                    item.selected = _timeZones.includes(item.id)
                })
            }

            // VEHICLES SERVICED
            if (vehicleServiced && vehicleServiced.items) {
                const _vehiclesServiced = vehicleServiced.items.map(item => item.id)
                copy.stepThree.vehicleServiced.items.forEach(item => {
                    item.checked = _vehiclesServiced.includes(item.id)
                })
            }

            // WEEK
            if (week && week.items) {
                const _week = week.items.map(item => item.id)
                copy.stepOne.week.forEach(item => {
                    item.checked = _week.includes(item.id)
                })
            }

            return copy

        case CLEAR_COUPON_ERROR:
            if (action.payload) {
                copy.stepFour.errors = ObjectWithoutKeys(state.stepFour.errors, [action.payload])
            } else {
                copy.stepFour.errors = {}
            }
            return copy

        case SET_COUPON_ERROR:
            return {
                ...state,
                stepFour: {
                    ...state.stepFour,
                    errors: {
                        ...state.stepFour.errors,
                        [action.payload.field]: action.payload.error
                    }
                }
            }

        case SET_CURRENT_PROPERTY:
            return {
                ...state,
                stepFour: {
                    ...state.stepFour,
                    currentCoupon: {
                        ...state.stepFour.currentCoupon,
                        [action.payload.key]: action.payload.val
                    }
                }
            }

        case ADD_COUPON:
            copy.stepFour.coupons.push({
                ...action.payload,
                id: (new Date()).getTime().toString()
            });
            sessionStorage.setItem(TEALIUM.coupon_count, copy.stepFour.coupons.length.toString())
            return copy

        case CLEAR_CURRENT_COUPON:
            copy.stepFour.currentCoupon = initialState.stepFour.currentCoupon
            return copy

        case UPDATE_COUPON:
            index = copy.stepFour.coupons.findIndex(item => item.id === action.payload.id)
            if (index !== -1) copy.stepFour.coupons[index] = { ...action.payload }
            return copy

        case RECOVER_COUPON:
            index = copy.stepFour.coupons.findIndex(item => item.id === action.payload.id)
            if (index !== -1) copy.stepFour.coupons[index].deleted = false
            return copy

        case REMOVE_COUPON:
            index = copy.stepFour.coupons.findIndex(item => item.id === action.payload.id)
            if (index !== -1) copy.stepFour.coupons[index].deleted = true
            return copy

        case SET_CURRENT_COUPON:
            copy.stepFour.currentCoupon = action.payload
            return copy

        case GET_PROFILE_INFO_REQUEST:
            copy.systemError = null
            return copy

        case GET_PROFILE_INFO_ERROR:
            copy.systemError = 'getProfileError'
            return copy
        
        case SET_NETWORK_STATE:
            if (!action.payload) {
                copy.systemError = 'systemError'
            } else {
                copy.systemError = null
            }

            return copy

        case UPDATE_PROFILE_REQUEST:
            copy.stepFour.coupons = copy.stepFour.coupons.filter(item => !item.deleted)
            return copy

        default:
            return state;

    }

}

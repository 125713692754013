import React from 'react'
import { Row, Col, Visible } from 'react-grid-system';
import {connect} from "react-redux";
import {shopTypes} from "../../../../utilities/constants";
import Portal from "../../../portal";
import ImageModal from "./ImageModal";
import './infoProfile.scss'
import {maskPhone} from "../../../../utilities/functions";
import Localization from "../../../../utilities/localization";
class InfoProfile extends React.Component {
    state = {
        showPictureShopModal: false
    }

    render () {
        const {data} = this.props
        const {showPictureShopModal} = this.state
        const _shopType = shopTypes.find(item => item.id === data.shopType)
        const _webAddress = data.shopWebAddress
                ? (data.shopWebAddress.includes('http') ? data.shopWebAddress : `http://${data.shopWebAddress}`)
                : ''
        return (
                <React.Fragment>
                     <div id="boxStepOne" className="InfoProfile">
                        <div className={"row"}>
                            <div md={3} className="col auto-expand">
                                <p id="address">
                                    <span className="title">{Localization.print("Shop Address")}</span>

                                    <span className="address-card">
                                        <Visible sm xs>
                                            {(data.croppedPicture || data.shopPicture) && (
                                                    <img
                                                            className={'clickable'}
                                                            src={data.croppedPicture || data.shopPicture}
                                                            alt='Shop'
                                                            onClick={() => this.setState({showPictureShopModal: true})}/>
                                            )}
                                        </Visible>
                                        <span className="card-info">
                                            <span className="name"> <strong>{data.shopName} </strong></span>
                                            <span className="address">{data.shopAddress} <br/> {data.city}, {data.state} {data.zipCode}</span>
                                        </span>
                                    </span>

                                    <span className="email"><a href={`mailto:${data.shopContactEmail}`}>{data.shopContactEmail}</a></span>
                                    <span className="web"><a href={_webAddress} target={'_blank'}>{data.shopWebAddress}</a></span>
                                </p>
                            </div>
                            <div md={3} className="col">
                                <p>
                                    <span className="title">{Localization.print("Account Number")}</span>
                                    <span className="value">{data.customerID}</span>

                                    <span className="title">{Localization.print("Shop Contact Name")}</span>
                                    <span className="value">{data.shopContactName}</span>

                                    {/*
                                    <span className="title">Shop Type</span>
                                    <span className="value">{_shopType.text}</span>
                                    */}
                                </p>
                            </div>
                            <div md={3} className="col">
                                <p>
                                    <span className="title">{Localization.print("Shop Phone")} </span>
                                    <span className="value">{maskPhone(data.shopPhoneNumber)}</span>

                                    <span className="title">{Localization.print("Mobile Number")}</span>
                                    <span className="value">{maskPhone(data.mobilePhoneNumber)}</span>

                                    {/*
                                    <span className="title"> Fax</span>
                                    <span className="value">{data.fax}</span>
                                    */}
                                </p>
                            </div>
                            <Visible md lg xl>
                                <div lg={3} className="col">
                                    {(data.croppedPicture || data.shopPicture) && (
                                            <img
                                                    className={'clickable'}
                                                    src={data.croppedPicture || data.shopPicture}
                                                    alt='Shop'
                                                    onClick={() => this.setState({showPictureShopModal: true})}/>
                                    )}

                                </div>
                            </Visible>
                        </div>
                    </div>
                    {showPictureShopModal && (
                            <Portal>
                                <ImageModal
                                        onClose={() => this.setState({showPictureShopModal: false})}
                                        source={data.croppedPicture || data.shopPicture}
                                />
                            </Portal>
                    )}
                </React.Fragment>
        )
    }
}

export default connect(
        state => ({
            data: state.steps.stepOne.profileInfo
        })
)(InfoProfile)

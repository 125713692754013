import {
    CAN_COMPLETE_PROCESS,
    UPDATE_STEP,
    RESET_STEPS_DATA,
    STEP_TWO_UPDATE_VALUE,
    STEP_TWO_SWITCH_ALL,
    STEP_TWO_CHANGE_OTHER,
    STEP_THREE_CHANGE_OTHER,
    STEP_THREE_SWITCH_ALL,
    STEP_THREE_UPDATE_VALUE,
    STEP_THREE_CHANGE_RADIO_VALUE,
    STEP_ONE_LANGUAGE_SWITCH_ALL,
    STEP_ONE_LANGUAGE_UPDATE_VALUE,
    STEP_ONE_CHANGE_TIME_ZONE,
    STEP_ONE_CHANGE_DAY,
    STEP_ONE_CHANGE_HOURS,
    STEP_ONE_PROFILE_DATA,
    STEP_ONE_PROFILE_PROPERTY,
    STEP_ONE_SET_ERROR,
    STEP_ONE_CLEAR_ERROR,
    SET_STEPS_DATA
} from './types'
import {isValidProperty} from "../../utilities/functions";
import {errorTypes} from "../../utilities/constants";

export const canCompleteProcess = value => ({type: CAN_COMPLETE_PROCESS, payload: value})

export const resetStepsData = () => ({type: RESET_STEPS_DATA})

export const setOneLanguageUpdateValue = (data) => dispatch=> { dispatch({type:STEP_ONE_LANGUAGE_UPDATE_VALUE,payload:data}) }

export const setOneLanguageeSwitchAll = (data) => dispatch=> { dispatch({type:STEP_ONE_LANGUAGE_SWITCH_ALL,payload:data}) }

export const setOneChangeTimeZone = (data) => dispatch=> { dispatch({type:STEP_ONE_CHANGE_TIME_ZONE,payload:data}) }

export const setOneChangeDay = (data) => dispatch=> { dispatch({type:STEP_ONE_CHANGE_DAY,payload:data}) }

export const setOneChangeHours = (data) => dispatch=> { dispatch({type:STEP_ONE_CHANGE_HOURS,payload:data}) }

/**
 * Update one of the properties of the profileInfo object
 * @param key
 * @param value
 * @returns {function(*): *}
 */
export const setProfileProperty = (key, value) => dispatch => dispatch({type: STEP_ONE_PROFILE_PROPERTY, payload: {key, value}})

/**
 * Updates the profileInfo object with the data received
 * @param data
 * @returns {function(*): *}
 */
export const setProfileData = data => dispatch => dispatch({type: STEP_ONE_PROFILE_DATA, payload: data})

/**
 * Clears one or all the errors saved for the profile info fields
 * @param all
 * @param key
 * @returns {function(*): *}
 */
export const setOneClearError = (all, key) => dispatch => dispatch({type: STEP_ONE_CLEAR_ERROR, payload: {all, key}})

/**
 * Updates the errors object for the fields in profile info form
 * @param key
 * @param errorType
 * @returns {function(*): *}
 */
export const setOneError = (key, errorType) => dispatch => dispatch({type: STEP_ONE_SET_ERROR, payload: {key, errorType}})

export const setCurrentStep = (step) => dispatch=> { dispatch({type:UPDATE_STEP,payload:step}) }

export const setTwoStepValue = (data) => dispatch=> { dispatch({type:STEP_TWO_UPDATE_VALUE,payload:data}) }

export const setTwoSwitchAll = (data) => dispatch=> { dispatch({type: STEP_TWO_SWITCH_ALL,payload:data}) }

export const setTwoChangeOther = (data) => dispatch=> { dispatch({type:STEP_TWO_CHANGE_OTHER,payload:data}) }



export const setThreeStepValue = (data) => dispatch=> { dispatch({type:STEP_THREE_UPDATE_VALUE,payload:data}) }

export const setThreeSwitchAll = (data) => dispatch=> { dispatch({type: STEP_THREE_SWITCH_ALL,payload:data}) }

export const setThreeChangeOther = (data) => dispatch=> { dispatch({type:STEP_THREE_CHANGE_OTHER,payload:data}) }

export const setThreeChangeRadiovalue = (data) => dispatch=> { dispatch({type:STEP_THREE_CHANGE_RADIO_VALUE,payload:data}) }

export const setStepsData = data => ({type: SET_STEPS_DATA, payload: data})

export const validateStepOne = () => dispatch => {
    let isValid = true
    const {steps: {stepOne: {requiredFields, profileInfo: info, errors}}} = require('../store').default.getState()

    requiredFields.forEach(key => {
        if (!isValidProperty(info[key])) {
            dispatch(setOneError(key, errorTypes.required))
            isValid = false
        } else if (errors[key] && errors[key] === errorTypes.required) {
            dispatch(setOneClearError(false, key))
        }
    })

    if (Object.keys(errors).length > 0) {
        isValid = false
    }

    return isValid
}

/*
export function fetchPosts(){
    return function(dispatch){

        fetch('https://jsonplaceholder.typicode.com/posts')
        .then(result=>result.json())
        .then(data=> 
                    dispatch({type:FETCH_POST,payload:data}) 
        )

    }
}
*/ 
export const ADD_COOKIE = 'ADD_COOKIE'
export const ADD_COOKIE_ERROR = 'ADD_COOKIE_ERROR'
export const REMOVE_COOKIE = 'REMOVE_COOKIE'

export const readCookie = name => {
    let value = "; " + document.cookie
    let parts = value.split("; " + name + "=")
    if (parts.length === 2) return parts.pop().split(";").shift()
}

export const writeCookie = val => document.cookie = val

export const addCookie = name => {
    const cookie = readCookie(name)
    if (cookie && cookie.length > 2) {
        return {
            type: ADD_COOKIE,
            payload: cookie
        }
    } else {
        return {
            type: ADD_COOKIE_ERROR,
            payload: "Could not read JSESSION ID from cookie"
        }
    }
}

export const removeCookie = () => ({type: REMOVE_COOKIE})
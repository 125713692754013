import React from 'react'
import {connect} from 'react-redux'
import {Col, Row, Visible, ScreenClassRender} from 'react-grid-system'
import StepWrapper from '../../body/StepWrapper'
import BodyHeader from '../body-header'
import Box from '../../suite/box'
import './stepFour.scss'
import colors from "../../../utilities/colors";
import ButtonAutoZone from "../../suite/button-autozone";
import {couponTypes, sessionStorageKey, systemErrors} from "../../../utilities/constants";
import {setCurrentProperty, validateCoupon} from "../../../redux/actions/coupons";
import Coupon from "../../suite/coupon";
import {isIframe, maskPhone, scrollIframeToViewPort} from "../../../utilities/functions";
import CouponForm from "../CouponForm";
import ErrorMessage from "../../suite/error-message";
import Localization from "../../../utilities/localization";
import {setComponent} from "../../../redux/actions/global";

let FreeCoupon, PercentCoupon, DollarCoupon, SpecialCoupon;

class StepFour extends React.Component {
    state = {
        previewCoupon: false
    }

    componentDidMount() {
        const locale = sessionStorage.getItem(sessionStorageKey.LOCALE) || 'us'

        if (isIframe()) {
            scrollIframeToViewPort('.Stepper')
        } else {
            window.scrollTo(0,0)
        }
        let name = 'Registration: Coupons';
        this.props.setComponent(name);

        FreeCoupon = locale === 'es' ? require('../../../assets/coupon_free-es.png') : require('../../../assets/Free-savings-coupon.jpg')
        PercentCoupon = locale === 'es' ? require('../../../assets/coupon_percent-es.png') : require('../../../assets/coupon-2.jpg')
        DollarCoupon = locale === 'es' ? require('../../../assets/coupon_dollar-es.png') : require('../../../assets/coupon-3.jpg')
        SpecialCoupon = locale === 'es' ? require('../../../assets/coupon_special-es.png') : require('../../../assets/special-savings-coupn.jpg')
    }

    _getCouponImageSrc (type) {
        switch (type) {
            case couponTypes.dollar:
                return DollarCoupon
            case couponTypes.percent:
                return PercentCoupon
            case couponTypes.special:
                return SpecialCoupon
            case couponTypes.free:
            default:
                return FreeCoupon
        }
    }

    _getCouponSize (screenClass) {
        switch (screenClass) {
            case "sm":
            case "xs":
                return "mobile"
            default:
                return "lg"
        }
    }

    _getCouponTitle (type = null) {
        const {steps: {stepFour: {currentCoupon}}} = this.props
        switch (type) {
            case couponTypes.free:
                return Localization.print("FREE")
            case couponTypes.special:
                return Localization.print("SPECIAL")
            case couponTypes.percent:
                return `${currentCoupon.percentOff}% ${Localization.print("OFF")}`
            case couponTypes.dollar:
                return `$${currentCoupon.dollarOff} ${Localization.print("OFF")}`
            default:
                return ''
        }
    }

    _handleInputChange = (fieldName, value) => {
        const {setCurrentProperty} = this.props
        let _value = value
        if (value !== null && typeof value.getDate === 'function') {
            _value = value.toLocaleDateString()
        }
        setCurrentProperty(fieldName, _value)
    }

    _handleTypeSelected = e => {
        this.props.setCurrentProperty('type', e.target.value)
    }

    _togglePreview = () => {
        const {validateCoupon} = this.props
        const {previewCoupon} = this.state
        if (!previewCoupon) {
            if (validateCoupon()) this.setState({previewCoupon: !previewCoupon})
        } else {
            this.setState({previewCoupon: !previewCoupon})
        }
    }

    render() {
        const {
            steps: {
                stepOne: {
                    profileInfo
                },
                stepFour: {
                    couponTypes: {items: couponTypeItems},
                    currentCoupon,
                    errors
                },
                systemError
            }
        } = this.props
        const error = systemErrors.find(item => item.key === systemError)

        return (
                <ScreenClassRender render={screenClass => (
                        <React.Fragment>
                            <div className="body-header">
                                <BodyHeader/>
                            </div>
                            {!!systemError && (<ErrorMessage messageText={error.message} />)}
                            {!systemError && (
                                    <StepWrapper screenClass={screenClass}>
                                        <div className="active-coupons">
                                            <Box width='100%' backgroundColor='#6c6f70' color='#ffffff'
                                                 title={Localization.print("Coupons")}
                                                 hint={["xl","lg"].includes(screenClass) ? `* ${Localization.print("Required fields")}` : ''}>
                                                <fieldset>
                                                    <legend>{Localization.print("Select the type of coupon you would like to Create")}</legend>
                                                    <div className="container">
                                                        <Row>
                                                            {couponTypeItems.map((type, i) => (
                                                                    <Col sm={12} md={3} key={i}>
                                                                        <div className="coupon-row">
                                                                            <div className="radio">
                                                                                <input
                                                                                        id={`type-radio-${i}`}
                                                                                        checked={type.value === currentCoupon.type}
                                                                                        name="type-radio"
                                                                                        onChange={this._handleTypeSelected}
                                                                                        type="radio"
                                                                                        value={type.value}
                                                                                />
                                                                                <label htmlFor={`type-radio-${i}`} className="radio-label">
                                                                                    {Localization.print(type.text)}
                                                                                </label>
                                                                            </div>
                                                                            <img
                                                                                    src={this._getCouponImageSrc(type.value)} alt={`${type.text} coupon image`}
                                                                                    style={{width: 145}}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                            ))}
                                                        </Row>
                                                    </div>
                                                    <CouponForm onInputChange={this._handleInputChange} />
                                                </fieldset>

                                            </Box>
                                            <div className="info-container">
                                                <div className="informationButtonContainer">
                                                    {this.state.previewCoupon ? (
                                                            <p>{Localization.print("To Edit this coupon, use the form above and click ‘Preview Coupon’ to view changes.")}</p>
                                                    ) : <p/>}

                                                    <Visible sm xs>
                                                        <ButtonAutoZone
                                                                id={'preview-coupons'}
                                                                text={Localization.print("PREVIEW COUPON")}
                                                                width={'254px'}
                                                                height='28px'
                                                                borderColor={colors.autozoneOrangeColor}
                                                                backgroundColor='white'
                                                                color={colors.autozoneOrangeColor}
                                                                handler={this._togglePreview}
                                                        />
                                                    </Visible>

                                                    <Visible xl lg md>
                                                        <ButtonAutoZone
                                                                id={'preview-coupons'}
                                                                text={Localization.print("PREVIEW COUPON")}
                                                                width={'167px'}
                                                                height='28px'
                                                                borderColor={colors.autozoneOrangeColor}
                                                                backgroundColor='white'
                                                                color={colors.autozoneOrangeColor}
                                                                handler={this._togglePreview}
                                                        />
                                                    </Visible>

                                                </div>
                                                {this.state.previewCoupon && (
                                                        <React.Fragment>
                                                            <div style={{height: 8}} />
                                                            <Coupon
                                                                    disclaimerText={currentCoupon.disclaimerText}
                                                                    endDate={currentCoupon.endDate}
                                                                    isFree={currentCoupon.type === couponTypes.free}
                                                                    salesText={currentCoupon.salesText}
                                                                    shopAddress={[
                                                                        profileInfo.shopAddress + ',',
                                                                        profileInfo.city + ',',
                                                                        profileInfo.state,
                                                                        profileInfo.zipCode
                                                                    ].join(' ')}
                                                                    shopContactEmail={profileInfo.shopContactEmail}
                                                                    shopName={profileInfo.shopName}
                                                                    shopPhoneNumber={maskPhone(profileInfo.shopPhoneNumber)}
                                                                    size={this._getCouponSize(screenClass)}
                                                                    startDate={currentCoupon.startDate}
                                                                    title={this._getCouponTitle(currentCoupon.type)}
                                                            />
                                                        </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                    </StepWrapper>
                            )}
                        </React.Fragment>
                )}/>
        )
    }
}

export default connect(
        ({global, steps}) => ({global, steps}),
        {setCurrentProperty, validateCoupon, setComponent}
        
)(StepFour)

import store from '../redux/store'
export default class Localization {
    static dictionary = {
        es: require('../locale/es.json')
    }

    static print (key, locale) {
        const lang = locale || store.getState().global.locale
        return (undefined === lang || lang === 'en')
                ? key
                : (function () {
                    if (undefined === this.dictionary[lang]) return key
                    if (undefined === this.dictionary[lang][key]) return key
                    return this.dictionary[lang][key]
                }.bind(this)())
    }
}

import React from 'react'
import {Row, Col} from 'react-grid-system';
import './errors.scss'

export default class ErrorMessage extends React.PureComponent {
// eslint-disable-next-line
    constructor(props) {
        super(props)
              
    }

    render() {        
        const {messageText, noBackground, noBorder, noIcon} = this.props
        return (
            <React.Fragment>
                <Row>
                    <Col sm={12} md={12}>
                        <div className="error-container" style={{backgroundColor: noBackground, border: noBorder}}>     
                            <span className="icon-error" style={{display: noIcon}} /><span className="error-text">{messageText}</span>
                        </div>
                    </Col>
                </Row>                
            </React.Fragment>            
        )
    }
}

import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import store from "../../../redux/store";

const PrivateRoute = ({component: Component, ...rest}) => (
        <Route
                {...rest}
                render={props => {
                    const {token} = store.getState()
                    if ((token && token.access_token) || sessionStorage.token)
                        return <Component {...props} />
                    else {
                        const path = window.location.hash.replace('#/', '')
                        sessionStorage.setItem('previousPath', path)
                        return <Redirect to="/login" />
                    }
                }}
        />
)

export default PrivateRoute
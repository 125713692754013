import React from 'react'
import './time.scss'
import SimpleList from '../../../../../../suite/dropDown/simpleList'
import {HOURS} from '../../../../../../../utilities/constants'
import arrows from '../../../../../../../assets/two_arrow.svg'

export default class Time extends React.PureComponent {
    timer = null
    typedText = ''
    // eslint-disable-next-line
    constructor(props) {
        super(props)

        this.state = {
            open: false,
            selected: null
        }

        document.addEventListener('mousedown', this.handleClick, false)
        document.addEventListener('keydown', this.handleType)
    }

    componentDidMount() {
        this.setSelectedItem()
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.item !== this.props.item) {
            this.setSelectedItem()
        }

        if (prevState.open !== this.state.open && this.state.open) {
            const {refs: {pickerList}} = this
            const elementSelected = pickerList.querySelector('.elementSelected')
            if (elementSelected) {
                pickerList.scrollTo(0, elementSelected.offsetTop)
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false)
        document.removeEventListener('keydown', this.handleType)
        this.typedText = ''
        if (this.timer) clearTimeout(this.timer)
    }

    handleClick = (e) => {

        try {
            if (this.list !== null && this.list !== undefined && this.list.contains(e.target))
                return;

            this.setState({open: false})
        } catch (e) {

        }

    }

    handleType = (e) => {
        const {props: {handler}, refs: {pickerList}, state: {open}} = this
        if (!open) return
        const items = pickerList.childNodes[0].childNodes
        const itemsArr = Array.from(items)

        if (e.key.toLowerCase() === 'enter' && open) {
            this.setState({open: false})
            return
        }

        // Moving list through arrow keys
        if (e.key.toLowerCase() === "arrowup" || e.key.toLowerCase() === "arrowdown") {
            e.preventDefault()
            const arrow = e.key.toLowerCase()
            const currentIndex = itemsArr.findIndex(el => {
                return el.getAttribute('class').includes('elementSelected')
            })

            if (currentIndex === -1) return
            if (arrow === "arrowup" && currentIndex === 0) return
            if (arrow === "arrowdown" && currentIndex === items.length - 1) return

            const index = arrow === "arrowup" ? currentIndex - 1 : currentIndex + 1
            pickerList.scrollTo(0, items[index].offsetTop)
            handler(items[index].textContent)
            return
        }

        if (isNaN(e.key)) return
        if (this.typedText.length === 0)
            this.timer = setTimeout(() => {
                this.typedText = ''
            }, 200)

        const key = e.key
        this.typedText+= key
        const arrValues = itemsArr.map(item => item.textContent)
        const index = arrValues.findIndex(text => {
            const _parts = text.split(':')
            if (parseInt(this.typedText) > 12) {
                const _hour = parseInt(this.typedText) - 12
                return _parts[0].includes(_hour.toString()) && _parts[1].toLowerCase().includes('pm')
            } else {
                return _parts[0].includes(this.typedText)
            }
        })
        if (index !== -1) {
            pickerList.scrollTo(0, items[index].offsetTop)
            handler(items[index].textContent)
        }
    }

    toggle = (e) => {
        this.setState(pre => ({open: !pre.open}))
    }

    listHandler = (id, close = true) => {
        try {
            const ID = parseInt(id)
            this.setState({selected: ID, open: !close})
            this.props.handler(HOURS.find(h => h.id === ID).text)
        } catch (e) {

        }
    }

    setSelectedItem () {
        const {item} = this.props
        const selected = HOURS.find(hour => (hour.text.toLowerCase() === item.toLowerCase()))
        if (selected) {
            this.setState({selected: selected.id})
        }
    }

    render() {

        const {item} = this.props
        const {open, selected} = this.state

        return (
                <div ref={(list) => {
                    this.list = list
                }} className="timerWrapper">
                    <div className={`timer ${open ? 'opened' : ''}`} onClick={this.toggle}>
                        <span>{item}</span>
                        <img src={arrows} alt='arrows' style={{width: 10}}/>
                    </div>

                    {open && (
                            <div className="timePickerList" ref={'pickerList'}>
                                <SimpleList
                                        items={HOURS}
                                        handler={this.listHandler}
                                        selected={selected}
                                        stopKeyboardEvents
                                />
                            </div>
                    )}
                </div>
        )
    }
}

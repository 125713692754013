import React from 'react'
import {Row, Col, ScreenClassRender} from 'react-grid-system';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import DropDown from "../../suite/dropDown";
import ButtonAutoZone from "../../suite/button-autozone";
import colors from "../../../utilities/colors";
import {
    setOneChangeTimeZone,
    setOneClearError,
    setOneError,
    setOneLanguageeSwitchAll,
    setOneLanguageUpdateValue, setProfileData, setProfileProperty,
    setStepsData,
    setThreeChangeOther,
    setThreeStepValue,
    setThreeSwitchAll,
    setTwoChangeOther,
    setTwoStepValue,
    setTwoSwitchAll
} from "../../../redux/actions";
import BodyHeader from "../body-header";
import Box from '../../suite/box'
import Week from '../StepOne/week'
import CheckboxGroup from '../../suite/checkBoxGroup'
import {couponTypes, errorTypes, HOUR_SECTION, SERVICES_SECTION} from '../../../utilities/constants'
import EditProfile from "../StepOne/EditProfile";
import './userProfile.scss'
import InfoProfile from "../StepOne/InfoProfile";
import {
    getCouponTitle,
    isValidProperty,
    maskPhone,
    scrollIframeToViewPort
} from "../../../utilities/functions";
import UserProfileComp from '../../suite/userProfile';
import Portal from "../../portal";
import SaveAlert from "../StepWrapper/alertBox";
import {updateProfile} from "../../../redux/actions/profile";
import {setComponent} from "../../../redux/actions/global";
import Coupon from "../../suite/coupon";
import {recoverCoupon, removeCoupon, setCurrentCoupon} from "../../../redux/actions/coupons";
import DeleteCouponAlert from "./deleteCouponAlert";
import UpdateProfileAlert from "./updateProfileAlert";
import DeactivateProfileAlert from "./deactivateProfileAlert";
import Localization from "../../../utilities/localization";
class UserProfile extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            cancelButton: 'top',
            editingProfile: true,
            languageSelected: "",
            tempData: null,
            timeZoneSelected: "",
            selectedCoupon: null,
            showSaveAlert: false,
            showUpdateProfileError: false,
            showEditCouponModal: false,
            showDeleteCouponModal: false,
            showDeactivateProfileAlert: false
        }
        this.profileStatusRef = React.createRef();
    }

    componentDidMount() {
        const {stepData} = this.props
        this.setLanguageLocally()
        let name = 'Edit Profile';
        this.props.setComponent(name);

        let initialTimeZone = stepData[HOUR_SECTION.timeZone].find(t => t.selected === true)
        if (initialTimeZone !== undefined)
            this.setState({timeZoneSelected: initialTimeZone})

        this.setTempData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(this.props.stepData[HOUR_SECTION.language]) !== JSON.stringify(prevProps.stepData[HOUR_SECTION.language]))
            this.setLanguageLocally()

        if (JSON.stringify(this.props.stepData[HOUR_SECTION.timeZone]) !== JSON.stringify(prevProps.stepData[HOUR_SECTION.timeZone])) {
            let timeZoneSelected = this.props.stepData[HOUR_SECTION.timeZone].find(t => t.selected === true)
            this.setState({timeZoneSelected})
        }
    }

    cancelEdition = () => {
        this.setState({cancelButton: 'bottom', showSaveAlert: true})
    }

    /**
     * Opens Edit Coupons modal
     */
    AddCoupon = () =>{
        this.props.history.push('/AddCoupon')
    }

    getData () {
        const {profileInfo, stepData, stepThree, stepTwo} = this.props
        return {
            affiliation: {
                items: stepTwo.affiliation.items.filter(item => item.checked)
            },
            amenities: {
                items: stepTwo.amenities.items.filter(item => item.checked),
                other: stepTwo.amenities.other
            },
            certification: {
                items: stepTwo.certifications.items.filter(item => item.checked),
                other: stepTwo.certifications.other
            },
            language: {
                items: stepData.language.items.filter(item => item.checked),
                other: stepData.language.other
            },
            profileInfo,
            regulatoryServices: {
                items: stepThree.shopType.items.filter(item => item.checked)
            },
            servicesPerformed: {
                items: stepThree.servicesPerformed.items.filter(item => item.checked)
            },
            timeZone: {
                items: stepData.timeZone.filter(item => item.selected)
            },
            vehicleServiced: {
                items: stepThree.vehicleServiced.items.filter(item => item.checked)
            },
            week: {
                items: stepData.week.filter(item => item.checked)
            }
        }
    }

    getFooterButtonWidth (screenClass, locale = 'us') {
        switch (screenClass) {
            case "xl":
                return locale === 'es' ? 284 : 184
            case "lg":
                return locale === 'es' ? 268 : 168
            default:
                return locale === 'es' ? 236 : 136
        }
    }

    /**
     * Return Language dropdown width according to device size
     * @param screenClass
     * @returns {string|number}
     */
    getLanguageDropDownWidth(screenClass) {
        switch (screenClass) {
            case "xl":
                return 368;
            case "lg":
                return 304;
            default:
                return null
        }
    }

    /**
     * Just close the alert
     */
    handleCancelQuit = () => this.setState({showSaveAlert: false})

    handleDeleteItem = (coupon) => {
        this.setState({showDeleteCouponModal: true, selectedCoupon: coupon})
    }

    handleDeleteItemConfirmation = () => {
        const {selectedCoupon} = this.state
        this.props.removeCoupon(selectedCoupon)
        this.setState({showDeleteCouponModal: false, selectedCoupon: null})
    }

    handleRecoverCoupon = coupon => this.props.recoverCoupon(coupon)

    /**
     * Redirects the user to HomePage and after the user is no longer
     * on StepOne page we call a function to reset the data to its
     * original state.
     */
    handleContinueQuit = () => {
        const {setOneClearError, setProfileData} = this.props
        const {cancelButton, tempData} = this.state

        if (cancelButton === 'bottom') {
            this.props.setStepsData(tempData)
        } else {
            this.setState({editingProfile: false, tempData: {...tempData, profileInfo: null}}, () => {
                setOneClearError(true)
                setProfileData(tempData.profileInfo)
            })
        }

        this.props.history.goBack()
    }

    handleSave = () => {
        if (this.validateProfile()) {
            this.props.updateProfile()
                    .then(() => {
                        this.props.history.replace('/Account')
                    })
                    .catch(e => {
                        this.setState({showUpdateProfileError: true})
                    })
        } else {
            scrollIframeToViewPort('.editProfile')
        }
    }

    setLanguageLocally() {
        const {locale} = this.props
        let languageSelected = this.props.stepData[HOUR_SECTION.language].items.filter(el => {
            if (locale === 'es') return !!el.es && el.checked
            return el.checked === true
        }).map(el => {
            if (locale === 'es') el.text = el.es
            return el
        })
        this.setState({languageSelected})
    }

    /**
     * Saves current info to set it back if the user
     * clicks on Cancel button
     */
    setTempData () {
        this.setState({tempData: this.getData()})
    }

    /**
     * Iterates over all the requiredFields array defined in redux
     * and validates that fields are not empty and set the corresponding
     * error for the ones that are empty.
     * @returns {boolean}
     */
    validateProfile () {
        const {requiredFields, errors, profileInfo, setOneClearError, setOneError} = this.props
        let isValid = true

        requiredFields.forEach(key => {
            if (!isValidProperty(profileInfo[key])) {
                setOneError(key, errorTypes.required)
                isValid = false
            } else if (errors[key] && errors[key] === errorTypes.required) {
                setOneClearError(false, key)
            }
        })

        if (Object.keys(errors).length > 0) {
            isValid = false
        }

        return isValid
    }

    render() {
        const {locale, profile: {data: profile}, stepData: {profileInfo}, stepFour: {coupons}} = this.props
        const {editingProfile, languageSelected, showDeleteCouponModal, showSaveAlert, showUpdateProfileError, showDeactivateProfileAlert} = this.state
        return (
                <ScreenClassRender render={screenClass => (
                        <div className={'userProfile'}>
                            <div className="body-header" style={{marginBottom: '16px'}}>
                                <BodyHeader/>
                            </div>
                            <Box id="form-container" title={Localization.print("Shop information and hours")} hint={`* ${Localization.print("Required fields")}`}>
                                {!editingProfile ? <InfoProfile /> : <EditProfile showAlert />}
                            </Box>
                            <div className="informationButtonContainer">
                                <Row>
                                    <Col lg={12}>
                                        <UserProfileComp
                                                active={!profileInfo.hideShop}
                                                onChange={hide => {
                                                    if (!hide) this.props.setProfileProperty('hideShop', hide)
                                                    this.setState({showDeactivateProfileAlert: hide})
                                                }}
                                                locale={locale}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="detailsBox" style={{marginBottom: '16px'}}>
                                <Row>
                                    <Col sm={12} md={12}>
                                        <fieldset>
                                            <legend>{Localization.print("Please check all languages spoken at your shop")}</legend>
                                            <DropDown
                                                    labelText={Localization.print("Select")}
                                                    width={this.getLanguageDropDownWidth(screenClass)}
                                                    selected={languageSelected}
                                                    multiple
                                                    items={this.props.stepData[HOUR_SECTION.language].items}
                                                    handler={this.props.setOneLanguageUpdateValue}
                                            >
                                                <div style={{padding: '16px 12px 0 12px'}}>
                                                    <CheckboxGroup
                                                            checkAllLegend={Localization.print("Select All")}
                                                            section={HOUR_SECTION.language} data={this.props.stepData}
                                                            handleSetValue={this.props.setOneLanguageUpdateValue}
                                                            handleSwitchAll={this.props.setOneLanguageeSwitchAll}
                                                            locale={locale}
                                                    />
                                                </div>
                                            </DropDown>
                                        </fieldset>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12}>
                                        <fieldset>
                                            <legend>{Localization.print("Please check the days and the hours that you are open. Unselected days will be marked as closed")}</legend>
                                            <Week/>
                                        </fieldset>
                                    </Col>
                                </Row>
                            </div>
                            <Box title={Localization.print("shop certification and affiliation")} hint={`(${Localization.print("Check all that apply")})`}
                                 bottomMargin='16px'>
                                <Row className={'boxes-horizontal stepTwo'}>
                                    <Col md={4} className={'col'}>
                                        <Box backgroundColor='#e9e9e9' color='#3a3c3d' title={Localization.print("Certification")} bottomBorder='0'>
                                            <CheckboxGroup
                                                           section="certifications"
                                                           data={this.props.stepTwo}
                                                           handleChangeOther={this.props.setTwoChangeOther}
                                                           handleSetValue={this.props.setTwoStepValue}
                                                           handleSwitchAll={this.props.setTwoSwitchAll}
                                                           checkAllLegend={Localization.print("Check All")}
                                                           locale={locale}
                                            />
                                        </Box>
                                    </Col>
                                    {locale !== 'es' && (
                                            <Col md={4} className={'col'} style={{
                                                paddingLeft: (["xl", "lg", "md"].includes(screenClass) ? 0 : 16),
                                                paddingTop: (["sm", "xs"].includes(screenClass) ? 16 : 0)
                                            }}>
                                                <Box backgroundColor='#e9e9e9' color='#3a3c3d' title='Affiliation' bottomBorder='0'>
                                                    <CheckboxGroup
                                                            section="affiliation"
                                                            data={this.props.stepTwo}
                                                            handleChangeOther={this.props.setTwoChangeOther}
                                                            handleSetValue={this.props.setTwoStepValue}
                                                            handleSwitchAll={this.props.setTwoSwitchAll}
                                                    />
                                                </Box>
                                            </Col>
                                    )}
                                    <Col md={4} className={'col'} style={{
                                        paddingLeft: (["xl", "lg", "md"].includes(screenClass) ? 0 : 16),
                                        paddingTop: (["sm", "xs"].includes(screenClass) ? 16 : 0)
                                    }}>
                                        <Box backgroundColor='#e9e9e9' color='#3a3c3d' title={Localization.print("Shop Amenities")} bottomBorder='0'>
                                            <CheckboxGroup
                                                           section="amenities"
                                                           data={this.props.stepTwo}
                                                           handleChangeOther={this.props.setTwoChangeOther}
                                                           handleSetValue={this.props.setTwoStepValue}
                                                           handleSwitchAll={this.props.setTwoSwitchAll}
                                                           locale={locale}
                                                           checkAllLegend={Localization.print("Check All")}
                                            />
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>
                            <Box title={Localization.print("Services Performed and vehicles serviced")} hint={`(${Localization.print("Check all that apply")})`}
                                 bottomMargin='16px'>
                                <Row className={'boxes-horizontal stepThree'}>
                                    <Col md={6} className={'col'}>
                                        <Box backgroundColor='#e9e9e9' color='#3a3c3d' title={Localization.print("Services Performed")} bottomBorder='0'>
                                            <CheckboxGroup
                                                    section={SERVICES_SECTION.servicesPerformed}
                                                    data={this.props.stepThree}
                                                    handleChangeOther={this.props.setThreeChangeOther}
                                                    handleSetValue={this.props.setThreeStepValue}
                                                    handleSwitchAll={this.props.setThreeSwitchAll}
                                                    locale={locale}
                                                    checkAllLegend={Localization.print("Check All")}
                                            />
                                        </Box>
                                    </Col>
                                    <Col md={3} className={'col'} style={{
                                        paddingLeft: (["xl", "lg", "md"].includes(screenClass) ? 0 : 16),
                                        paddingTop: (["sm", "xs"].includes(screenClass) ? 16 : 0)
                                    }}>
                                        <Box backgroundColor='#e9e9e9' color='#3a3c3d' title={Localization.print("Regulatory Services")} bottomBorder='0'>
                                            <CheckboxGroup
                                                    data={this.props.stepThree}
                                                    section={SERVICES_SECTION.shopType}
                                                    handleChangeOther={this.props.setThreeChangeOther}
                                                    handleSetValue={this.props.setThreeStepValue}
                                                    handleSwitchAll={this.props.setThreeSwitchAll}
                                                    locale={locale}
                                                    checkAllLegend={Localization.print("Check All")}
                                            />
                                        </Box>
                                    </Col>
                                    <Col md={3} className={'col'} style={{
                                        paddingLeft: (["xl", "lg", "md"].includes(screenClass) ? 0 : 16),
                                        paddingTop: (["sm", "xs"].includes(screenClass) ? 16 : 0)
                                    }}>
                                        <Box backgroundColor='#e9e9e9' color='#3a3c3d' title={Localization.print("Vehicles Serviced")} bottomBorder='0'>
                                            <CheckboxGroup
                                                    section={SERVICES_SECTION.vehicleServiced}
                                                    data={this.props.stepThree}
                                                    handleChangeOther={this.props.setThreeChangeOther}
                                                    handleSetValue={this.props.setThreeStepValue}
                                                    handleSwitchAll={this.props.setThreeSwitchAll}
                                                    locale={locale}
                                                    checkAllLegend={Localization.print("Check All")}
                                            />
                                        </Box>
                                    </Col>
                                </Row>

                            </Box>
                            <Box title={Localization.print("Active Coupons")}>
                               <div className="couponsarea">
                                   {(coupons && coupons.length > 0) ? (
                                           <Row nogutter>
                                               {coupons.map((coupon, i) => (
                                                       <Col lg={3} md={4} sm={6} key={i}>
                                                           <div className={`coupon-container ${(coupon.deleted ? 'deleted' : '')}`}>
                                                               <h3>{coupon.name}</h3>
                                                               <div className={`coupon`}>
                                                                   <Coupon
                                                                           disclaimerText={coupon.disclaimerText}
                                                                           endDate={coupon.endDate}
                                                                           isFree={coupon.type === couponTypes.free}
                                                                           salesText={coupon.salesText}
                                                                           shopAddress={[
                                                                               profile.address.addressLine1 + ',',
                                                                               profile.address.city + ',',
                                                                               profile.address.state,
                                                                               profile.address.zip
                                                                           ].join(' ')}
                                                                           shopContactEmail={profile.contactInfo.email}
                                                                           shopName={profile.shopName}
                                                                           shopPhoneNumber={maskPhone(profile.contactInfo.shopNumber)}
                                                                           size={'xs'}
                                                                           startDate={coupon.startDate}
                                                                           title={getCouponTitle(coupon)}
                                                                   />
                                                               </div>
                                                               <p>
                                                                   {!coupon.deleted ? (
                                                                           <React.Fragment>
                                                                               <Link to={{
                                                                                   pathname: '/EditCoupon',
                                                                                   params: {flag: 'couponTypeFree', coupon}
                                                                               }}>{Localization.print("Edit")}</Link> <span/>&nbsp;
                                                                               <a onClick={this.handleDeleteItem.bind(this, coupon)}>
                                                                                   {Localization.print("Delete")}
                                                                               </a>
                                                                           </React.Fragment>
                                                                   ) : (
                                                                           <a onClick={this.handleRecoverCoupon.bind(this, coupon)}>{Localization.print("Undo")}</a>
                                                                       )}
                                                               </p>

                                                           </div>
                                                       </Col>
                                               ))}
                                           </Row>
                                   ) : (<p style={{fontSize: 14, color: '#231f20', margin: '6px 0 20px'}}>{Localization.print("No coupons available")}</p>)}
                                </div>  
                                     
                            </Box>
                            <div className="addcoupons">
                                <button className={`smallbutton ${locale}`} onClick={this.AddCoupon}> {Localization.print("Add Coupon")} </button>
                            </div>   
                            <div className="footer">
                                <ButtonAutoZone
                                        backgroundColor={'#fff'}
                                        borderColor={colors.autozoneBlackColor}
                                        color={colors.autozoneBlackColor}
                                        handler={this.cancelEdition}                                        
                                        height={38}
                                        text={Localization.print("CANCEL")}
                                        width={this.getFooterButtonWidth(screenClass)}
                                />
                                <div style={{width: 16}} />
                                <ButtonAutoZone
                                        backgroundColor={colors.autozoneOrangeColor}
                                        borderColor={'transparent'}
                                        color={'#fff'}
                                        handler={this.handleSave}                                       
                                        height={38}
                                        text={Localization.print("SAVE AND CONFIRM")}
                                        width={this.getFooterButtonWidth(screenClass, locale)}
                                />
                            </div>

                            {showSaveAlert && (
                                    <Portal>
                                        <SaveAlert onCancel={this.handleCancelQuit}
                                                   onContinue={this.handleContinueQuit}
                                                   locale={locale}
                                        />
                                    </Portal>
                            )}

                            {showDeleteCouponModal && (
                                    <Portal>
                                        <DeleteCouponAlert
                                                onCancel={() => this.setState({showDeleteCouponModal: false})}
                                                onContinue={this.handleDeleteItemConfirmation}
                                                locale={locale}
                                        />
                                    </Portal>
                            )}

                            {showUpdateProfileError && (
                                    <Portal>
                                        <UpdateProfileAlert
                                            onOk={() => this.setState({showUpdateProfileError: false})}
                                            locale={locale}
                                        />
                                    </Portal>
                            )}

                            {showDeactivateProfileAlert && (
                                    <Portal>
                                        <DeactivateProfileAlert
                                            onCancel={() => {
                                                this.props.setProfileProperty('hideShop', false)
                                                this.setState({showDeactivateProfileAlert: false})
                                            }}
                                            onContinue={() => {
                                                this.props.setProfileProperty('hideShop', true)
                                                this.setState({showDeactivateProfileAlert: false})
                                            }}
                                            locale={locale}
                                        />
                                    </Portal>
                            )}
                        </div>
                )}/>
        )

    }

}

const mapStateToProps = state => ({
    errors: state.steps.stepOne.errors,
    requiredFields: state.steps.stepOne.requiredFields,
    profile: state.profile,
    profileInfo: state.steps.stepOne.profileInfo,
    stepData: state.steps.stepOne,
    stepFour: state.steps.stepFour,
    stepTwo: state.steps.stepTwo,
    stepThree: state.steps.stepThree,
    timeZone: state.steps.stepOne.timeZone,
    profileError: state.steps.profileError,
    locale: state.global.locale
})

const mapDispatchToProps = ({
    recoverCoupon,
    removeCoupon,
    setCurrentCoupon,
    setOneChangeTimeZone,
    setOneClearError,
    setOneError,
    setOneLanguageUpdateValue,
    setOneLanguageeSwitchAll,
    setProfileData,
    setProfileProperty,
    setStepsData,
    setThreeSwitchAll,
    setThreeStepValue,
    setThreeChangeOther,
    setTwoStepValue,
    setTwoSwitchAll,
    setTwoChangeOther,
    updateProfile,
    setComponent
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)

import {isValidProperty} from "../../utilities/functions";
import {couponTypes, errorTypes} from "../../utilities/constants";
import Coupon from "../../models/Coupon";

export const ADD_COUPON = 'ADD_COUPON'
export const CLEAR_COUPON_ERROR = 'CLEAR_COUPON_ERROR'
export const CLEAR_CURRENT_COUPON = 'CLEAR_CURRENT_COUPON'
export const RECOVER_COUPON = 'RECOVER_COUPON'
export const REMOVE_COUPON = 'REMOVE_COUPON'
export const SET_CURRENT_COUPON = 'SET_CURRENT_COUPON'
export const SET_CURRENT_PROPERTY = 'SET_CURRENT_PROPERTY'
export const SET_COUPON_ERROR = 'SET_COUPON_ERROR'
export const UPDATE_COUPON = 'UPDATE_COUPON'

export const clearCouponError = field => ({
    type: CLEAR_COUPON_ERROR,
    payload: field
})

export const clearCurrentCoupon = () => ({type: CLEAR_CURRENT_COUPON})

export const recoverCoupon = coupon => ({type: RECOVER_COUPON, payload: coupon})

export const removeCoupon = coupon => ({type: REMOVE_COUPON, payload: coupon})

export const saveCoupon = () => dispatch => {
    return new Promise((resolve, reject) => {
        const {steps: {stepFour: {currentCoupon}}} = require('../store').default.getState()

        try {
            const coupon = new Coupon(currentCoupon)
            dispatch({
                type: coupon.id ? UPDATE_COUPON : ADD_COUPON,
                payload: coupon
            })
            resolve(coupon)
        } catch (e) {
            reject(e)
        }
    })
}

export const setCurrentCoupon = coupon => ({type: SET_CURRENT_COUPON, payload: coupon})

export const setCouponError = (field, error) => ({
    type: SET_COUPON_ERROR,
    payload: {field, error}
})

export const setCurrentProperty = (key, val) => ({
    type: SET_CURRENT_PROPERTY,
    payload: {key, val}
})

export const validateCoupon = (showErrors = true) => dispatch => {
   
    let isValid = true
    const {steps: {stepFour: {currentCoupon, errors}}} = require('../store').default.getState()

    if (!isValidProperty(currentCoupon.name)) {
        showErrors && dispatch(setCouponError('name', errorTypes.required))
        isValid = false
    } else if (errors['name']) {
        dispatch(clearCouponError('name'))
    }

    if (!isValidProperty(currentCoupon.salesText)) {
        showErrors && dispatch(setCouponError('salesText', errorTypes.required))
        isValid = false
    } else if (errors['salesText']) {
        dispatch(clearCouponError('salesText'))
    }

    if (!isValidProperty(currentCoupon.startDate) || !isValidProperty(currentCoupon.endDate)) {
        showErrors && dispatch(setCouponError('date', errorTypes.required))
        isValid = false
    } else if (errors['date']) {
        dispatch(clearCouponError('date'))
    }

    if (currentCoupon.type === couponTypes.percent) {
        if (!isValidProperty(currentCoupon.percentOff)) {
            showErrors && dispatch(setCouponError('percentOff', errorTypes.required))
            isValid = false
        } else if (errors['percentOff']) {
            dispatch(clearCouponError('percentOff'))
        }
    }

    if (currentCoupon.type === couponTypes.dollar) {
        if (!isValidProperty(currentCoupon.dollarOff)) {
            showErrors && dispatch(setCouponError('dollarOff', errorTypes.required))
            isValid = false
        } else if (errors['dollarOff']) {
            dispatch(clearCouponError('dollarOff'))
        }
    }

    if (currentCoupon.startDate && currentCoupon.endDate) {
        let startDay, startMonth, startYear, endDay, endMonth, endYear
        const now = new Date();
        if (currentCoupon.startDate.includes('/')) {
            [startMonth, startDay, startYear] = currentCoupon.startDate.split('/').map(el => parseInt(el))
        } else if (currentCoupon.startDate.includes('-')) {
            [startYear, startMonth, startDay] = currentCoupon.startDate.split('-').map(el => parseInt(el))
        }

        if (currentCoupon.endDate.includes('/')) {
            [endMonth, endDay, endYear] = currentCoupon.endDate.split('/').map(el => parseInt(el))
        } else if (currentCoupon.endDate.includes('-')) {
            [endYear, endMonth, endDay] = currentCoupon.endDate.split('-').map(el => parseInt(el))
        }

        const startDate = new Date(startYear, startMonth - 1, startDay)
        const endDate = new Date(endYear, endMonth - 1, endDay)

        if (isNaN(startMonth) || isNaN(startDay) || isNaN(startYear) || isNaN(endMonth) || isNaN(endDay) || isNaN(endYear)) {
            showErrors && dispatch(setCouponError('date', 'Provide a valid date range'))
            isValid = false
        } else if (startYear < (now.getFullYear() - 2) || endYear < (now.getFullYear() - 2)) {
            showErrors && dispatch(setCouponError('date', 'Provide a valid date range'))
            isValid = false
        } else if (startDate > endDate) {
            showErrors && dispatch(setCouponError('date', 'Provide a valid date range'))
            isValid = false
        } else if (errors['date']) {
            dispatch(clearCouponError('date'))
        }
    }

    return isValid
}

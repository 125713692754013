import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {ScreenClassRender} from 'react-grid-system'
import './couponPreviewModal.scss'
import CloseButton from "../../../suite/closeButton";
import Coupon from "../../../suite/coupon";
import {getCouponTitle, maskPhone} from "../../../../utilities/functions";
import Localization from "../../../../utilities/localization";

function CouponPreviewModal ({coupon, onClose, onPrint, shop}) {
    const [fullSize, setFullSize] = useState(false)
    const {shopAddressObject: address, shopContactObject: contact, media, ...currentShop} = shop
    const isMobile = () => (window.innerWidth <= 567);

    function handleDoubleClick (screenClass) {
        if (screenClass !== 'xs') return
        setFullSize(!fullSize)
    }

    return (
            <ScreenClassRender render={screenClass => (
                    <div className="CouponPreviewModal">
                        <CloseButton onClick={onClose} white={["xs"].includes(screenClass)} />
                        <div className="coupon-container" onClick={handleDoubleClick.bind(this, screenClass)}>
                            <Coupon
                                    disclaimerText={coupon.disclaimerText}
                                    endDate={coupon.endDate}
                                    salesText={coupon.salesText}
                                    shopAddress={[
                                        address.postalAddressLine1Text + ',',
                                        address.cityName + ',',
                                        address.stateCode,
                                        address.zipCode
                                    ].join(' ')}
                                    shopContactEmail={contact.emailAddressValue}
                                    shopName={currentShop.shopName}
                                    shopPhoneNumber={maskPhone(contact.mainPhoneNumber)}
                                    size={(["xs"].includes(screenClass) && !fullSize) ? "sm" : "md"}
                                    startDate={coupon.startDate}
                                    title={getCouponTitle(coupon)}
                            />
                        </div>
                        <button className={'cta'} onClick={onPrint}>
                            {isMobile()
                                    ? Localization.print("Download Coupon")
                                    : Localization.print("Print Coupon")
                            }
                        </button>
                    </div>
            )}/>
    )
}

CouponPreviewModal.propTypes = {
    onClose: PropTypes.func,
    onPrint: PropTypes.func
}

export default CouponPreviewModal

import React from 'react'
import PropTypes from 'prop-types'
import './input.scss'
import Autocomplete from "react-google-autocomplete";
import Localization from "../../../utilities/localization";
import {getLocale} from "../../../utilities/functions";
const locale = getLocale()

export default class Input extends React.Component {
    state = {
        active: false
    }

    _handlePlaceSelected = place => {
        const {onChange} = this.props
        const {refs: {autoCompleteInput: {refs: {input}}}} = this
        const cleanedValue = input.value.split(',')[0]
        onChange(cleanedValue)
    }

    render() {

        const {title, width, height, disabled, mandatory, multiline, value, className, onBlur, onChange, inputType, error, index, info, name, style, ...props} = this.props
        const {active} = this.state
        const _labelClassName = ((!value || value.length === 0) && !active && !props.masked) ? 'placeholder' : ''

        return (
                <div className={`input ${className}${error && ' error'}${props.icon ? ' with-icon' : ''}`} style={style}>

                    <label className={_labelClassName} style={{backgroundColor: disabled ? '#e9e9e9' : ''}}>                       
                        <span>{mandatory ? '*' : ''}</span>
                        <span>{title}</span>                        
                    </label>

                    {!!props.icon && (<img src={props.icon} className="input-icon"/>)}

                    {disabled && (multiline ? (
                            <textarea style={{width, height, backgroundColor: '#e9e9e9'}} disabled {...props} defaultValue={value} />
                    ) : (
                            <input style={{width, height, backgroundColor: '#e9e9e9'}} disabled defaultValue={value}/>
                    ))}
                    {!disabled && (multiline ? (
                                    <div className="textArea-container">
                                        <textarea
                                                onChange={e => onChange(e.target.value)}
                                                onFocus={() => this.setState({active: true})}
                                                onBlur={({nativeEvent: {target}}) => this.setState({active: false}, () => {
                                                    onBlur && onBlur(target)
                                                })}
                                                style={{width, height}}
                                                tabIndex={index}
                                                name={name}
                                                title={title}
                                                value={value}
                                                {...props}
                                        />
                                    </div>
                            )
                            : (inputType !== 'autocomplete') ? (
                                    <input
                                            onChange={e => onChange(e.target.value)}
                                            onFocus={() => this.setState({active: true})}
                                            onBlur={({nativeEvent: {target}}) => this.setState({active: false}, () => {
                                                onBlur && onBlur(target)
                                            })}
                                            style={{width, height}}
                                            tabIndex={index}
                                            type={inputType}
                                            value={value}
                                            name={name}
                                            title={title}
                                            className={props.masked ? 'masked' : ''}
                                            {...props}
                                    />
                            ) : (
                                    <Autocomplete
                                            className="searchTerm"
                                            componentRestrictions={{country: locale === 'es' ? 'mx' : 'us'}}
                                            onBlur={({nativeEvent: {target}}) => this.setState({active: false}, () => {
                                                onBlur && onBlur(target)
                                            })}
                                            onChange={e => onChange(e.target.value)}
                                            onFocus={() => this.setState({active: true})}
                                            onPlaceSelected={this._handlePlaceSelected}
                                            placeholder={""}
                                            ref={'autoCompleteInput'}
                                            types={['address']}
                                            value={value}
                                    />
                            ))}
                    {!!error && <span className="error-message">{Localization.print(error)}</span>}
                    {!!info && <span className="info-message">{Localization.print(info)}</span>}
                </div>
        )
    }
}

Input.defaultProps = {
    onChange: e => {},
    inputType: 'text'
}

Input.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    multiline: PropTypes.bool,
    mandatory: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    title: PropTypes.string,
    width: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
    ]),
    value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
    ]),
    inputType: PropTypes.oneOf([
            'autocomplete',
            'color',
            'date',
            'email',
            'number',
            'password',
            'range',
            'search',
            'tel',
            'text',
            'time',
            'url'
    ])
}

import React from 'react'
import { Container } from 'react-grid-system';
import qs from 'querystring'
import './body.scss'
import FaqPage from './faq-page'
import Terms from './termsncondition'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import StepFour from './StepFour'
import StepFinal from './StepFinal'
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom'
import PrivateRoute from "../routes/private";
import LoginPage from "./login";
import HomeRoute from "../routes/home";
import {connect} from 'react-redux'
import HomePage from "./homePage";
import LandingPage from "./LandingPage";
import {addToken, getTokenInfo} from "../../redux/actions/token";
import AccountPage from "./AccountPage";
import UserProfile from './user-profile'
import FindShop from './findShop'
import EditCoupon from './EditCoupon'
import AddCoupon from './AddCoupon'
import ShopResultsDetails from './shopDetails'
import {isAZPage, isIframe} from "../../utilities/functions";
import {setLocale, setNetworkState,setComponent} from "../../redux/actions/global";

import SuccessPage from "./Success";
import CouponPage from "./CouponPage";
import errorIconSrc from '../../assets/error.svg'
import {sessionStorageKey} from "../../utilities/constants";

let Home = HomeRoute
let Private = PrivateRoute
let locale = 'us'

if (window.location.search) {
    const params = qs.parse(window.location.search.replace(/[\?|\/]/g, ''))
    if (undefined !== params.noauth && params.noauth === '1') {
        Home = props => <Route {...props} component={HomePage}/>
        Private = Route
    }

    if (undefined !== params.v && (params.v === 'mx' || params.v === 'es')) {
        locale = 'es'
    }
}

sessionStorage.setItem(sessionStorageKey.LOCALE, locale)

class Body extends React.Component{
    constructor (props) {
        super(props)

        if (!this.bodyTag) {
            this.bodyTag = window.document.querySelector('body')
        }

        this.state = {
            global: props.global,
            bodyTag: this.bodyTag,
            systemErrors: '',
            showSaveProfileError: ''
        }
    }

    static getDerivedStateFromProps (props, state) {
        if (props.global.showingModal !== state.global.showingModal) {
            state.global = props.global
            state.bodyTag.style.overflow = (state.global.showingModal) ? 'hidden' : 'auto'

            return state
        }

        return null
    }

    componentDidMount() {
        this.props.setLocale(locale);
        if (!isAZPage() && !isIframe()) {
            const {addToken, getTokenInfo, token} = this.props
            if (!sessionStorage.token) return
            if (token && token.access_token) return
            addToken(JSON.parse(sessionStorage.getItem('token')))
            getTokenInfo()
        }
        let name = 'Home';
        this.props.setComponent(name);
        this.props.setNetworkState(navigator.onLine)
        //this.props.setLocale(locale)
        window.addEventListener('online', this.props.setNetworkState.bind(this, true))
        window.addEventListener('offline', this.props.setNetworkState.bind(this, false))
    }

   

    componentWillUnmount() {
        window.removeEventListener('online', this.props.setNetworkState)
        window.removeEventListener('offline', this.props.setNetworkState)
    }

    render(){
        return  <Container style={{paddingLeft: 0, paddingRight: 0}}>
            <div className={`body ${this.props.fullWidth ? 'fullWidth' : ''}`}>
                <Router>
                    <Switch>
                        <Home path='/' exact />
                        <Route path='/landing' component={LandingPage} exact/>
                        <Route path='/login' component={LoginPage} exact/>
                        <Route path='/az/faq-page' component = {FaqPage} />
                        <Route path='/az/termsncondition' component = {Terms} />
                        <Private path='/StepOne' component = {StepOne}  />
                        <Private path='/StepTwo' component = {StepTwo}  />
                        <Private path='/StepThree' component = {StepThree}  />
                        <Private path='/StepFour' component = {StepFour}  />
                        <Private path='/StepFinal' component = {StepFinal}  />
                        <Private path='/Account' component = {AccountPage} />
                        <Private path='/EditAccount' component = {UserProfile}  />
                        <Private path='/EditCoupon' component = {EditCoupon}  />
                        <Private path='/AddCoupon' component = {AddCoupon}  />
                        {/* AZ.com pages */}
                        {/*
                          * For all the AZ.com pages we need to use "/az/:pageName" pattern,
                          * so that way we can differentiate code sent to Tealium analytics.
                          * Tealium code on: line 34 /src/App.js file
                          */}
                        <Route path='/az/findShop' component={FindShop} />
                        <Route path='/az/shopDetails' component={ShopResultsDetails} />
                        <Route path='/az/coupon' component={CouponPage} />

                        <Route path="/access_token=:params" component={SuccessPage} />
                    </Switch>
                </Router>
                <img src={errorIconSrc} style={{display: 'none'}} />
            </div>
        </Container>
    }
}

export default connect(
        ({cookie, global, token, componentName }) => ({cookie, global, token, componentName}),
        {addToken, getTokenInfo, setNetworkState, setLocale,setComponent}
)(Body)

import {couponTypes} from "../utilities/constants";
import {zeroFill} from "../utilities/functions";

export default class Coupon {
    id = null
    name = ''
    disclaimerText= ''
    dollarOff = null
    endDate = null
    percentOff = null
    salesText = ''
    startDate = null
    type = couponTypes.free
    deleted = false

    constructor (values) {
        if (!values.type) throw new Error(('Type is mandatory'))
        if (!values.name) throw new Error('Name is mandatory')
        if (!values.startDate) throw new Error('Start Date is mandatory')
        if (!values.endDate) throw new Error('End Date is mandatory')
        if (!values.salesText) throw new Error('Sales Text is mandatory')

        if (values.id) this.id = values.id
        if (values.disclaimerText) this.disclaimerText = values.disclaimerText
        if (values.dollarOff && values.type === couponTypes.dollar) {
            this.dollarOff = typeof values.dollarOff === 'string'
                    ? parseFloat(values.dollarOff.replace(/\$|OFF/g, ''))
                    : values.dollarOff
        }
        if (values.percentOff && values.type === couponTypes.percent) {
            this.percentOff = typeof values.percentOff === 'string'
                    ? parseFloat(values.percentOff.replace(/%|OFF/g, ''))
                    : values.percentOff
        }

        const [sM, sD, sY] = values.startDate.split('/')
        const [eM, eD, eY] = values.endDate.split('/')

        this.type = values.type
        this.name = values.name
        this.startDate = values.startDate.includes('/') ? `${sY}-${zeroFill(sM)}-${zeroFill(sD)}` : values.startDate
        this.endDate = values.endDate.includes('/') ? `${eY}-${zeroFill(eM)}-${zeroFill(eD)}` : values.endDate
        this.salesText = values.salesText
    }
}

import React from 'react'
import Localization from "../../../../utilities/localization";

const Pagination = ({max, onPageChange, page, rows}) => {
    const isMobile = window.document.body.offsetWidth <= 567
    const pages = Math.ceil(rows / max)
    let arr, firstPage, lastPage, listLimit = isMobile ? 2 : 5, start = 1

    if (pages === 1) return null

    const Link = ({page: link, children, className}) => {
        const Active = () => (<a className={className} href={`javascript:void(0);`} onClick={() => onPageChange(link)}>{children}</a>)
        const Inactive = () => (<span className={['link', className].join(' ')}>{children}</span>)

        if (typeof link === "number") {
            return (link === page || link < 1 || link > pages) ? <Inactive/> : <Active/>
        }

        return <Inactive />
    }

    if (pages >= (listLimit * 2)) {
        if (page < listLimit) {
            lastPage = pages
        } else if (page > (pages - listLimit + 1)) {
            start = pages - listLimit + 1
            firstPage = 1
        } else {
            firstPage = 1
            lastPage = pages
            start = page - (isMobile ? 1 : 2)
        }
    } else {
        listLimit = pages
    }

    arr = Array.from({length: listLimit}, (_, i) => i+start)

    return (
            <ul className="pagination">
                <li><Link className={['first', (page > 1) ? 'active' : ''].join(' ')} page={1} /></li>
                <li><Link className={['prev', (page > 1) ? 'active' : ''].join(' ')} page={page - 1}>{Localization.print("PREV")}</Link></li>
                {!!firstPage && (
                        <>
                            {!lastPage && (<li className={'number'}><Link className={(page === firstPage ? 'active' : '')} page={firstPage}>{firstPage}</Link></li>)}
                            <li className={'number'}><Link>...</Link></li>
                        </>
                )}
                {arr.map(i => (<li key={i} className={'number'}><Link className={(page === i ? 'active' : '')} page={i}>{i}</Link></li>))}
                {!!lastPage && (
                        <>
                            <li className={'number'}><Link>...</Link></li>
                            {!firstPage && (<li className={'number'}><Link className={(page === lastPage ? 'active' : '')} page={lastPage}>{lastPage}</Link></li>)}
                        </>
                )}
                <li><Link className={['next', (page < pages) ? 'active' : ''].join(' ')} page={page + 1}>{Localization.print("NEXT")}</Link></li>
                <li><Link className={['last', (page < pages) ? 'active' : ''].join(' ')} page={pages} /></li>
            </ul>
    )
}

export default Pagination

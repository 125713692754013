import axios from 'axios'
import store from '../redux/store'
import {getOauthLoginURL} from "./auth";
import {isIframe} from "./functions";

const axiosInstance = axios.create({
    headers: {
        'Authorization': ''
    }
})

// Setting token for all service calls that use this axios instance
axiosInstance.interceptors.request.use(
        config => {
            let token = store.getState().token

            if (!token || !token.access_token) {
                const tokenStr = sessionStorage.getItem('token')
                token = JSON.parse(tokenStr)
            }

            if (token) {
                let accessToken = token.access_token
                config.headers.Authorization = `Bearer ${accessToken}`
            }

            if (config.method.toLowerCase() === 'post' || config.method.toLowerCase() === 'put') {
                config.headers.Accept = 'application/json'
                config.headers["Content-Type"] = 'application/json'
            }

            return config
        },
        error => {
            return Promise.reject(error)
        }
)

// Error handling for 401 - Unauthorized
axiosInstance.interceptors.response.use(
        response => response,
        async error => {
        
            if (error.response.status === 401) {
                sessionStorage.removeItem('token')
                if (isIframe()) {
                    try {
                        window.parentIFrame.sendMessage({message: 'login'}, '*')
                    } catch (e) {

                    }
                } else {
                    const oauthURL = getOauthLoginURL()
                    window.location.replace(oauthURL)
                }
            }
            return Promise.reject(error)
        }
)

export const getApiURL = (endPoint) => [
        window.env.GATEWAY_BASE_URL,
        window.env.API_PATH,
        endPoint
].join('')

export const axiosPublic = axios.create({
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
})

export default axiosInstance

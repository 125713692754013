import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Hidden, Visible, ScreenClassRender } from 'react-grid-system'
import { Link } from "react-router-dom";
import NearbyStores from './nearby-stores'
import '../findShop/findshop.scss'
import './shopdetails.scss'
import PredictiveSearch from "../../suite/googlePredictiveSearch";
import Map from "../homePage/map";
import {
    getCertificationTitle,
    getClosingHour,
    getCouponTitle,
    getGoogleDirectionsLink,
    getLanguageTitle, getLocalizedRegulatoryServicesArray,
    getLocalizedServicesPerformedArray,
    getLocalizedShopCertificationsArray,
    getLocalizedShopLanguagesArray, getLocalizedVehiclesServicesArray,
    getRegulatoryServiceTitle,
    getServicePerformedTitle,
    getShortTimeZone,
    getVehicleServicedTitle,
    isIframe,
    maskPhone,
    sortStringsCallback
} from "../../../utilities/functions";
import DaysHoursTable from "../../suite/daysHoursTable";
import {
    getShopMedia,
    searchShops,
    setCoupon,
    setOptions,
    setPlace,
    setShop,
    setShopAction
} from "../../../redux/actions/shops";
import Coupon from "../../suite/coupon";
import BlurPortal from "../../blurPortal";
import CouponPreviewModal from "./couponPreviewModal";
import {DisclaimerText} from "../findShop/disclaimerText";

import Printlogo from '../../../assets/logo-print.JPG';
import {dataLayerAZ} from "../../../utilities/utag";
import {sessionStorageKey} from "../../../utilities/constants";
import Localization from "../../../utilities/localization";
import clickTrack from "../../../utilities/azClicks";

class ShopDetails extends React.Component{

    constructor (props) {
        super(props)

        this.state = {
            coupon: null,
            currentSearch: null,
            showCouponPreview: false,
            goBack:0
        }
        //this.pageClick = this.pageClick.bind(this);
    }
    
    // pageClick(e)
    // {
    //     if(e.keyCode===13)
    //     {
    //         if(this.state.goBack===0){
    //         console.log("key")
    //         this.setState({goBack: 1})
    //         this._handleSearch();
    //         }
    //     }
    // }

    componentDidMount() {
        if (!this.props.shops.currentShop) this.props.history.replace('/az/findShop')
        const {getShopMedia, shops: {action, currentShop, place}} = this.props

        if (isIframe()) {
            try {
                window.parentIFrame.scrollTo(0,0)
            } catch (e) {

            }
        } else {
            window.scrollTo(0,0)
        }

        if (place && place.formatted_address) {
            this.setState({currentSearch: place.formatted_address})
        }

        if (currentShop) {
            getShopMedia(currentShop.id);
            dataLayerAZ('shopDetails', {
                pin: currentShop.id,
                shopName: currentShop.shopName,
            });
        }
        if (action && action === 'print') {
            if (window.matchMedia) {
                this.mediaQueryList = window.matchMedia('print')
                this.mediaQueryList.addEventListener('change', this._handleMediaPrintChange)
            }

            window.print()
        } else if (currentShop) {
            this._searchNearbyStores()
        }

        //document.addEventListener('keydown', this.pageClick, false);
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.getDocumentHeight();
        }
    }

    getDocumentHeight = () => {
        const documentHeight = document.body.offsetHeight;
        console.log("document Height on details",documentHeight)
        window.parent.postMessage(JSON.stringify( {'documentHeight': documentHeight} ), '*' )
    }

    // componentWillUnmount()
    // {
    //     document.removeEventListener('keydown', this.pageClick, false);
    // }

    NearbyStores = (screenClass) => {
        const {global: {locale}, shops: {profiles, currentShop}} = this.props
        const total = ["xl", "lg"].includes(screenClass) ? 4 :2
        const collection = profiles.filter(item => item.id !== currentShop.id)
        const shops = collection.length > total ? collection.slice(0, total) : collection

        return (
                <div className="nearby-list">
                    {shops.map((shop, i) => (<NearbyStores key={i} {...shop} onClick={this._handleNearbyItemSelected} locale={locale}/>))}
                </div>
        )
    }

    ServiceList = ({arr, titleFn}) => {
        const {global: {locale}} = this.props
        return (
                <ul className="services">
                    {arr.length === 0 && <li>{Localization.print("None")}</li>}
                    {arr.map(value => titleFn(value)).sort(sortStringsCallback('asc'))
                            .map((text, i) => (<li key={i}>{text}</li>))}
                </ul>
        )
    }

    _getDirectionsURL = () => {
        const {shops: {currentShop: {shopAddressObject: {geoLocation: {lat, lon}}}}} = this.props
        return getGoogleDirectionsLink(lat, lon)
    }

    _handleMediaPrintChange = mql => {
        if (!mql.matches) {
            sessionStorage.setItem(sessionStorageKey.SKIP, "1")
            this.props.history.replace('/az/findShop')
            this.props.setShopAction(null)
            this.mediaQueryList.removeEventListener('change', this._handleMediaPrintChange)
        }
    }

    _handleNearbyItemSelected = item => {
        const {getShopMedia, history, setShop} = this.props
        setShop(item)
        window.scrollTo(0,0)
        dataLayerAZ('nearbyShop', {
            'pin': item.id,
            'shopName': item.shopName,
        });
        history.push('/az/shopDetails')
        getShopMedia(item.id)
        this._searchNearbyStores()
    }

    _handleCouponPrint = () => {
        const {props: {global: {locale}, shops: {currentShop}}, state: {coupon}} = this
        const url = '/az/coupon' + (locale === 'es' ? '?v=mx' : '')

        this.setState({showCouponPreview: false})
        localStorage.setItem('currentShop', JSON.stringify(currentShop))
        localStorage.setItem('currentCoupon', JSON.stringify(coupon))
        clickTrack.shopDetailsClicks('printCoupon', {
            'pin': currentShop.id,
            'shopName': currentShop.shopName,
        });
        window.open(url, '_blank')
    }

    _handleDirections = () => {
        const {shops: {currentShop}} = this.props
        const url = this._getDirectionsURL()
        clickTrack.shopDetailsClicks('getDirections', {
            'pin': currentShop.id,
            'shopName': currentShop.shopName,
        });
        window.open(url, '_blank')
    }

    _handlePlaceSelected = place => {
        this.setState({place}, () => {
            this.props.setPlace(place)
            this.props.setOptions(null)
        })
        if(this.state.goBack===0){
            console.log("_handlePlaceSelected")
            this.setState({goBack: 1})
            this._handleSearch();
            }
       
    }

    _handlePrint = () => {
        const {shops: {currentShop}} = this.props
        clickTrack.shopDetailsClicks('print', {
            'pin': currentShop.id,
            'shopName': currentShop.shopName,
        });
        window.print()
    }

    _handleSearch = () => {
        sessionStorage.setItem('shopDetailsSearch', 'Search');
        console.log('>>>>>> Go back');
        this.props.history.goBack()
    }

    _searchNearbyStores = () => {
        const {shops: {currentShop, filters}} = this.props
        const searchFields = {
            "shopAddressObject.geoLocation": {
                "_geoDistance": {
                    "center": {
                        "lat": currentShop.shopAddressObject.geoLocation.lat,
                        "lon": currentShop.shopAddressObject.geoLocation.lon
                    },
                    "distance": 10,
                    "unit": "mi"
                }
            }
        }

        if (filters && filters.length > 0) {
            filters.forEach(filter => {
                switch (filter.section) {
                    case "certifications":
                        if (!searchFields["shopCertificationsArray"]) searchFields["shopCertificationsArray"] = {"_any": []}
                        searchFields["shopCertificationsArray"]["_any"].push(filter.value)
                        break
                    case "servicesPerformed":
                        if (!searchFields["servicesPerformedArray"]) searchFields["servicesPerformedArray"] = {"_any": []}
                        searchFields["servicesPerformedArray"]["_any"].push(filter.value)
                        break
                    case "shopType":
                        if (!searchFields["regulatoryServicesArray"]) searchFields["regulatoryServicesArray"] = {"_any": []}
                        searchFields["regulatoryServicesArray"]["_any"].push(filter.value)
                        break
                    case "vehicleServiced":
                        if (!searchFields["vehiclesServicesArray"]) searchFields["vehiclesServicesArray"] = {"_any": []}
                        searchFields["vehiclesServicesArray"]["_any"].push(filter.value)
                        break
                    case "language":
                        if (!searchFields["shopLanguagesArray"]) searchFields["shopLanguagesArray"] = {"_any": []}
                        searchFields["shopLanguagesArray"]["_any"].push(filter.value)
                        break
                    case "nationwide":
                        searchFields["isProvantageCustomerFlag"] = "true"
                        break
                    default:
                        break
                }
            })
        }

        this.props.searchShops({ resultsLinesPerPage: 5 }, searchFields, false)
    }

    _showCouponModal = (coupon) => {
        const {shops: {currentShop}} = this.props
        this.setState({showCouponPreview: true, coupon})
        clickTrack.shopDetailsClicks('viewCoupon', {
            'pin': currentShop.id,
            'shopName': currentShop.shopName,
        });
    }

    _backToSearchResults = () => {
        sessionStorage.setItem('shopDetailsSearch', 'Back to Search Results');
        this.props.history.goBack();
    }

    render(){
        if (!this.props.shops.currentShop) return null
        const {global: {locale}, shops: {currentShop: {shopAddressObject: address, shopContactObject: contact, media, ...currentShop}}} = this.props
        const {coupon, showCouponPreview, currentSearch} = this.state
        const _localizedServicesPerformedArray = getLocalizedServicesPerformedArray(currentShop.servicesPerformedArray)
        const _localizedShopCertificationsArray = getLocalizedShopCertificationsArray(currentShop.shopCertificationsArray)
        const _localizedShopLanguagesArray = getLocalizedShopLanguagesArray(currentShop.shopLanguagesArray)
        const _localizedRegulatoryServicesArray = getLocalizedRegulatoryServicesArray(currentShop.regulatoryServicesArray)
        const _localizedVehiclesServicesArray = getLocalizedVehiclesServicesArray(currentShop.vehiclesServicesArray)

        return  (
            <ScreenClassRender render={screenClass => (
                    <div className="findshop" id="shop-details">
                        <Row>
                            <Col sm={12} md={12}>
                                <p className="breadcrumb">
                                    <a href="https://autozone.com" target={'_parent'}>{Localization.print("Home")}</a> <span>/</span>&nbsp;
                                    <Link to={'/az/findShop'}>{Localization.print("Find a repair shop near you")}</Link>
                                    {/* <span>/</span>&nbsp; <a href="javascript:void(0);" className="active">{currentShop.shopName}</a> */}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12}>
                            <h1 className="hdr-title">
                                <span className={`title ${locale}`}>{Localization.print("FIND A REPAIR SHOP NEAR YOU")}</span>
                                <span className="print-logo"><img src={Printlogo} /></span>
                            </h1>
                        {(this.props.shops.place && this.props.shops.place.formatted_address) && (<span className="print-address" >
                            {Localization.print("Address")}: {this.props.shops.place.formatted_address}</span>)}
                                <p className="para">
                                    {locale === 'us'
                                            ? "We know there are some jobs you may want a professional mechanic to perform. AutoZone can help you with that, too. We'll gladly refer you to a qualified professional you can trust, through our Shop Referral Program. Because parts are just part of what we do."
                                            : "Sabemos que hay algunos trabajos que prefieres encargar a un taller mecánico. Con mucho gusto te referiremos a un profesional calificado, a través de nuestro Programa de Talleres Referidos. Porque las autopartes son sólo una parte de lo que hacemos."
                                    }
                                </p>
                            </Col>
                        </Row>
                        <div className="wrap" >
                            <div className="search">
                                <PredictiveSearch
                                        onPlaceSelected={this._handlePlaceSelected}
                                        placeholder={Localization.print("Enter Address or City and State or Zipcode")}
                                        value={currentSearch}
                                />
                                {/* <button type="submit" className="searchButton" onClick={this._handleSearch} /> */}
                                <button type="submit" className="locationButton" />
                            </div>
                            <div style={{flex: 1}} />
                            <div className="tools">
                                <button type="submit" className="printButton" onClick={this._handlePrint} />
                            </div>
                        </div>
                        <Row>
                            <Col sm={12} md={12}>
                                <Map
                                        isDetails
                                        lat={parseFloat(address.geoLocation.lat)}
                                        lng={parseFloat(address.geoLocation.lon)}
                                        markers={[{
                                            label: "",
                                            lat: parseFloat(address.geoLocation.lat),
                                            lng: parseFloat(address.geoLocation.lon),
                                            name: currentShop.shopName
                                        }]}
                                />
                                <h3 className="backtosearch">
                                    <a className="back-arrow" onClick={this._backToSearchResults}>
                                        {Localization.print("BACK TO SEARCH RESULTS")}
                                    </a>
                                </h3>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12} md={12}>
                                <Row gutterWidth={16}>
                                    <Col sm={6} md={6}>

                                        <div className="details left">
                                            <span className="print-header">{Localization.print("Store Name & Contact Information")} </span>
                                            <div id="address">
                                                <span className="title">{currentShop.shopName}</span>
                                                <span className="address-card">
                                                    <span className="address">{address.postalAddressLine1Text}  <br/>
                                                        {address.cityName}, {address.stateCode} {address.zipCode}
                                                    </span>
                                                    <span className="hours">{getClosingHour(currentShop.shopHoursObject.shopHoursArray)}</span>
                                                </span>
                                                <ul className={'shop-actions'}>
                                                    <li><a style={{cursor: 'pointer'}} onClick={this._handleDirections} rel="noopener noreferrer" target="_blank">{Localization.print("Get Directions")}</a></li>
                                                    <li><a href={`tel:${contact.mainPhoneNumber}`}>{maskPhone(contact.mainPhoneNumber, true)}</a></li>
                                                    {currentShop.shopUrlValue && (
                                                            <li><a href={currentShop.shopUrlValue} rel={"noopener noreferrer"} target={'_blank'}>
                                                                {currentShop.shopUrlValue.replace(/http:\/\/|https:\/\//, '')}
                                                            </a></li>
                                                    )}
                                                </ul>
                                            </div>
                                            <p className="tools" id="tools">
                                                <button type="submit" className="printButton" onClick={this._handlePrint} />
                                            </p>
                                        </div>
                                    </Col>
                                    {(media && media.shopPicture) && (
                                            <Col sm={6} md={6}>
                                                <div className="details right">
                                                    <img src={`data:image/jpeg;base64,${media.shopPicture.image}`} className="shop-image"/>
                                                </div>
                                            </Col>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12}>
                                <div className="shop-info">
                                    <Row nogutter>
                                        <Col sm={6} md={6}>
                                            <div>
                                                <span className="title">
                                                    {Localization.print("Store Hours")}&nbsp;
                                                    {locale !== 'es' && (
                                                            <strong>({currentShop.shopHoursObject.timeZoneCode && getShortTimeZone(currentShop.shopHoursObject.timeZoneCode)})</strong>
                                                    )}
                                                </span>
                                                <p id="info">
                                                    <span className="table">
                                                        <DaysHoursTable days={currentShop.shopHoursObject.shopHoursArray} />
                                                    </span>
                                                </p>
                                            </div>
                                            {media && media.coupons && media.coupons.length > 0 && (
                                                    <div className={'active-coupons'}>
                                                        <Hidden xs><div style={{height: 24}} /></Hidden>
                                                        <span className="title">{Localization.print("Active Coupons")}</span>
                                                        <Visible xs><div style={{height: 6}} /></Visible>
                                                        <Row nogutter>
                                                            {media.coupons.map((coupon, i) => (
                                                                    <Col lg={6} md={6} sm={6} className={'col'} key={i}>
                                                                        <button className={'coupon-btn'} onClick={this._showCouponModal.bind(this, coupon)}>
                                                                            <Coupon
                                                                                    disclaimerText={coupon.disclaimerText}
                                                                                    endDate={coupon.endDate}
                                                                                    salesText={coupon.salesText}
                                                                                    shopAddress={[
                                                                                        address.postalAddressLine1Text + ',',
                                                                                        address.cityName + ',',
                                                                                        address.stateCode,
                                                                                        address.zipCode
                                                                                    ].join(' ')}
                                                                                    shopContactEmail={contact.emailAddressValue}
                                                                                    shopName={currentShop.shopName}
                                                                                    shopPhoneNumber={maskPhone(contact.mainPhoneNumber)}
                                                                                    size={'xs'}
                                                                                    startDate={coupon.startDate}
                                                                                    title={getCouponTitle(coupon)}
                                                                            />
                                                                        </button>
                                                                    </Col>
                                                            ))}
                                                        </Row>
                                                        <Visible xs><div style={{height: 10}} /></Visible>
                                                    </div>
                                            )}
                                        </Col>
                                        <Col sm={6} md={3}>
                                            {_localizedServicesPerformedArray.length > 0 ?
                                                <div>
                                                    <span className="title">{Localization.print("SERVICE PERFORMED")}</span>
                                                    <this.ServiceList arr={_localizedServicesPerformedArray} titleFn={getServicePerformedTitle} />
                                                </div> : null
                                            }
                                            {_localizedShopCertificationsArray.length > 0 ?
                                                <div>
                                                    <span className="title">{Localization.print("SHOP CERTIFICATIONS & AFFILIATIONS")}</span>
                                                    <this.ServiceList arr={_localizedShopCertificationsArray} titleFn={getCertificationTitle} />
                                                </div> : null
                                            }
                                            <Visible sm xs>
                                              { ( _localizedShopLanguagesArray.length > 0 || _localizedRegulatoryServicesArray.length > 0 || _localizedVehiclesServicesArray.length > 0) ?
                                                    <React.Fragment>
                                                        {_localizedShopLanguagesArray.length > 0 && (
                                                                <div>
                                                                    <span className="title">{Localization.print("LANGUAGES SPOKEN")}</span>
                                                                    <this.ServiceList arr={_localizedShopLanguagesArray} titleFn={getLanguageTitle} />
                                                                </div>
                                                        )}
                                                        {_localizedRegulatoryServicesArray.length > 0 && (
                                                                <div>
                                                                    <span className="title">{Localization.print("REGULATORY SERVICES")}</span>
                                                                    <this.ServiceList arr={_localizedRegulatoryServicesArray} titleFn={getRegulatoryServiceTitle} />
                                                                </div>
                                                        )}
                                                        {_localizedVehiclesServicesArray.length > 0 && (
                                                                <div>
                                                                    <span className="title">{Localization.print("VEHICLES SERVICED")}</span>
                                                                    <this.ServiceList arr={_localizedVehiclesServicesArray} titleFn={getVehicleServicedTitle} />
                                                                </div>
                                                        )}
                                                    </React.Fragment> : null
                                              }
                                            </Visible>
                                        </Col>
                                        <Col sm={6} md={3}>
                                            <Hidden sm xs>
                                                { ( _localizedShopLanguagesArray.length > 0 || _localizedRegulatoryServicesArray.length > 0 || _localizedVehiclesServicesArray.length > 0) ?
                                                    <React.Fragment>
                                                        {_localizedShopLanguagesArray.length > 0 && (
                                                                <div>
                                                                    <span className="title">{Localization.print("LANGUAGES SPOKEN")}</span>
                                                                    <this.ServiceList arr={_localizedShopLanguagesArray} titleFn={getLanguageTitle} />
                                                                </div>
                                                        )}
                                                        {_localizedRegulatoryServicesArray.length > 0 && (
                                                                <div>
                                                                    <span className="title">{Localization.print("REGULATORY SERVICES")}</span>
                                                                    <this.ServiceList arr={_localizedRegulatoryServicesArray} titleFn={getRegulatoryServiceTitle} />
                                                                </div>
                                                        )}
                                                        {_localizedVehiclesServicesArray.length > 0 && (
                                                                <div>
                                                                    <span className="title">{Localization.print("VEHICLES SERVICED")}</span>
                                                                    <this.ServiceList arr={_localizedVehiclesServicesArray} titleFn={getVehicleServicedTitle} />
                                                                </div>
                                                        )}
                                                    </React.Fragment> : null
                                                }
                                            </Hidden>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12}>
                                <div className="nearby">
                                    <span className="title">{Localization.print("NEARBY STORES")}</span>
                                    {this.NearbyStores(screenClass)}
                                </div>
                            </Col>
                        </Row>

                        <DisclaimerText locale={locale}/>

                        {showCouponPreview && (
                                <BlurPortal>
                                    <CouponPreviewModal
                                            coupon={coupon}
                                            onClose={() => this.setState({showCouponPreview: false})}
                                            onPrint={this._handleCouponPrint}
                                            shop={this.props.shops.currentShop}
                                    />
                                </BlurPortal>
                        )}
                    </div>
            )}/>
        )
    }
}

export default connect(
        ({global, shops}) => ({global, shops}),
        {getShopMedia, setCoupon, setShop, searchShops, setShopAction, setPlace, setOptions}
)(ShopDetails)

import qs from 'querystring'
import http, {getApiURL} from '../../utilities/http'
import {getComponentsFromStrHour, ObjectWithoutKeys} from "../../utilities/functions";
import {TEALIUM} from "../../utilities/constants";
import store from '../store';

export const CHECK_ELIGIBILITY_ERROR = 'CHECK_ELIGIBILITY_ERROR'
export const CHECK_ELIGIBILITY_REQUEST = 'CHECK_ELIGIBILITY_REQUEST'
export const CHECK_ELIGIBILITY_SUCCESS = 'CHECK_ELIGIBILITY_SUCCESS'

export const GET_PROFILE_INFO_ERROR = 'GET_PROFILE_INFO_ERROR'
export const GET_PROFILE_INFO_REQUEST = 'GET_PROFILE_INFO_REQUEST'
export const GET_PROFILE_INFO_SUCCESS = 'GET_PROFILE_INFO_SUCCESS'

export const GET_PROFILE_STATUS_ERROR = 'GET_PROFILE_STATUS_ERROR'
export const GET_PROFILE_STATUS_REQUEST = 'GET_PROFILE_STATUS_REQUEST'
export const GET_PROFILE_STATUS_SUCCESS = 'GET_PROFILE_STATUS_SUCCESS'

export const SAVE_PROFILE_ERROR = 'SAVE_PROFILE_ERROR'
export const SAVE_PROFILE_REQUEST = 'SAVE_PROFILE_REQUEST'
export const SAVE_PROFILE_SUCCESS = 'SAVE_PROFILE_SUCCESS'

export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR'
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'

// Global variable to get image status for tealium
let imageStatus = null

// Helper function to create profile object with data from all steps
export const createProfileObject = (currentStep = null) => {
    const {
        profile: {data: profile},
        global,
        steps: {
            stepOne: {
                language: {items: languages},
                profileInfo,
                week
            },
            stepTwo: {
                affiliation: {items: affiliations},
                amenities,
                certifications
            },
            stepThree: {
                servicesPerformed: {items: servicesPerformed},
                shopType: {items: regulatoryServices},
                vehicleServiced: {items: vehiclesServiced}
            },
            stepFour: {
                coupons
            }
        }
    } = store.getState()

    const filterAndMap = (filterProp, mapProp) => (arr) => {
        return arr.filter(item => item[filterProp])
                .map(item => item[mapProp])
    }

    const getProfilePicture = () => {
        // New Image
        // { id: null, image: base64 }
        // No Changes
        // { id: String, image: base64 } | null
        // Delete Image
        // { id: String, image: null }
        const _getCleanBase64 = base64 => base64.split(',').slice(-1)[0]

        if (!profileInfo.shopPicture) {
            if (profile && profile.shopPicture && profile.shopPicture.id) {
                // The user is trying to remove the image
                //tealiumLink({[TEALIUM.profilePicture]: 'Remove Picture'})
                imageStatus = 'Remove Picture'
                return {
                    id: profile.shopPicture.id,
                    image: null
                }
            } else {
                // no image has been saved or uploaded
                return null
            }
        } else {
            if (profileInfo.croppedPicture) {
                // The user has edited the image
                if (profile && profile.shopPicture && profile.shopPicture.id) {
                    imageStatus = 'Update Picture'
                    //tealiumLink({[TEALIUM.profilePicture]: 'Update Picture'})
                } else {
                    imageStatus = 'Add Picture'
                    //tealiumLink({[TEALIUM.profilePicture]: 'Add Picture'})
                }

                return {
                    id: null,
                    image: _getCleanBase64(profileInfo.croppedPicture)
                }
            } else {
                if (profile && profile.shopPicture && profile.shopPicture.id) {
                    const _newImageString = profileInfo.shopPicture.substr(-100, 100)
                    const _oldImageString = profile.shopPicture.image.substr(-100, 100)
                    if (_newImageString !== _oldImageString) {
                        imageStatus = 'Update Picture'
                        //tealiumLink({[TEALIUM.profilePicture]: 'Update Picture'})
                        // New image selected
                        return {
                            id: null,
                            image: _getCleanBase64(profileInfo.shopPicture)
                        }
                    } else {
                        // Same image
                        return null
                    }
                } else {
                    // New image (no previous image present)
                    imageStatus = 'Add Picture'
                    //tealiumLink({[TEALIUM.profilePicture]: 'Add Picture'})
                    return {
                        id: null,
                        image: _getCleanBase64(profileInfo.shopPicture)
                    }
                }
            }
        }
    }
    
    return {
        commercialId: profileInfo.customerID || profile.commercialId,
        shopName: profileInfo.shopName,
        shopWebAddress: profileInfo.shopWebAddress,
        shopPicture: getProfilePicture(),
        hideShop: profileInfo.hideShop,
        countryCd: global.locale === 'us' ? 'USA' : 'MEX',
        contactInfo: {
            shopContactName: profileInfo.shopContactName,
            email: profileInfo.shopContactEmail,
            shopNumber: profileInfo.shopPhoneNumber,
            mobileNumber: profileInfo.mobilePhoneNumber,
            fax: profileInfo.fax
        },
        coupons: coupons.filter(item => !item.deleted).map(item => ObjectWithoutKeys(item, ['deleted'])),
        currentStep,
        address: {
            addressLine1: profileInfo.shopAddress,
            city: profileInfo.city,
            state: profileInfo.state,
            zip: profileInfo.zipCode
        },
        languages: (filterAndMap('checked', 'value')(languages)),
        shopHours: {
            // timeZone: (timeZone.find(item => item.selected) || {text: ""}).text.toUpperCase(),
            dayHours: week.filter(item => item.checked)
                    .map(item => ({
                        [item.text.toUpperCase()]: {
                            openHour: getComponentsFromStrHour(item.open),
                            closeHour: getComponentsFromStrHour(item.close)
                        }
                    }))
                    .reduce((obj, item, index) => ({...obj, ...item}), {})
        },
        shopCertifications: filterAndMap('checked', 'value')(certifications.items),
        shopRegulatoryServices: filterAndMap('checked', 'value')(regulatoryServices),
        shopMemberships: filterAndMap('checked', 'value')(affiliations),
        shopAmenities: filterAndMap('checked', 'value')(amenities.items),
        vehiclesServiced: filterAndMap('checked', 'value')(vehiclesServiced),
        servicesPerformed: filterAndMap('checked', 'value')(servicesPerformed),
        //shopType: shopTypes.find(item => item.id === profileInfo.shopType).value
    }
}

// Thunk Actions
export const checkEligibility = commercialCustomerId => async dispatch => {
    const url = getApiURL(window.env.API_ELIGIBILITY_ENDPOINT)
    const params = qs.stringify({commercialCustomerId})
    
    dispatch(checkEligibilityRequest())
    try {
        const {data} = await http.get(url + '?' + params)
        dispatch(checkEligibilitySuccess(data))
         
        sessionStorage.setItem(TEALIUM.eligible, (data.eligible ? "1" : "0"))
    } catch (e) {
        dispatch(checkEligibilityError(e))
    }
}

const checkEligibilityError = error => ({type: CHECK_ELIGIBILITY_ERROR, payload: error})
const checkEligibilityRequest = () => ({type: CHECK_ELIGIBILITY_REQUEST})
const checkEligibilitySuccess = data => ({type: CHECK_ELIGIBILITY_SUCCESS, payload: data})

export const getProfileInfo = (profileID) => async dispatch => {
    const url = getApiURL(window.env.API_PROFILES_ENDPOINT) + "/" + profileID
    dispatch(getProfileInfoRequest())
    try {
        const {data} = await http.get(url)
       const state = store.getState();
       data.locale = state.global.locale;
        dispatch(getProfileInfoSuccess(data))
    } catch (e) {
        dispatch(getProfileInfoError(e))
    }
}

const getProfileInfoError = error => ({type: GET_PROFILE_INFO_ERROR, payload: error})
const getProfileInfoRequest = () => ({type: GET_PROFILE_INFO_REQUEST})
const getProfileInfoSuccess = data => ({type: GET_PROFILE_INFO_SUCCESS, payload: data})

export const getProfileStatus = (profileID) => async dispatch => {
    const url = getApiURL(window.env.API_STATUS_ENDPOINT) + "?commercialCustomerId=" + profileID
    dispatch(getProfileStatusRequest())
    try {
        const {data} = await http.get(url)
        dispatch(getProfileStatusSuccess(data))

        const user = JSON.parse(sessionStorage.getItem('user'))
        if (user && user.hasOwnProperty('currentpin')) dispatch(getProfileInfo(user.currentpin))
    } catch (e) {
        dispatch(getProfileStatusError(e.response))
    }
}

const getProfileStatusError = error => ({type: GET_PROFILE_STATUS_ERROR, payload: error})
const getProfileStatusRequest = () => ({type: GET_PROFILE_STATUS_REQUEST})
const getProfileStatusSuccess = data => ({type: GET_PROFILE_STATUS_SUCCESS, payload: data})

export const saveProfile = (currentStep) => async dispatch => {
    const createProfileRequest = createProfileObject(currentStep)
    const url = getApiURL(window.env.API_PROFILES_ENDPOINT)
    dispatch(saveProfileRequest())

    try {
        const response = await http.post(url, createProfileRequest)
        if (currentStep === 'COMPLETE') {
            const tealiumDetails = {[TEALIUM.shopForm]: 'Signup Complete'}
            if (imageStatus) tealiumDetails[TEALIUM.profilePicture] = imageStatus
            //tealiumLink(tealiumDetails)
        }
        dispatch(saveProfileSuccess(response))
        return response
    } catch (e) {
        dispatch(saveProfileError(e))
        throw e
    }
}

const saveProfileError = error => ({type: SAVE_PROFILE_ERROR, payload: error})
const saveProfileRequest = () => ({type: SAVE_PROFILE_REQUEST})
const saveProfileSuccess = data => ({type: SAVE_PROFILE_SUCCESS, payload: data})

//export const updateProfile = (currentStep, getInfo = true) => async dispatch => {
//    const profileRequestObject = createProfileObject(currentStep)
//    const url = getApiURL(window.env.API_PROFILES_ENDPOINT)
//    dispatch(updateProfileRequest())
//    try {
//        const response = await http.put(url, profileRequestObject)
//        const user = JSON.parse(sessionStorage.getItem('user'))
//        const tealiumDetails = {}

//        if (currentStep === 'COMPLETE' || !currentStep) {
//            tealiumDetails[TEALIUM.shopForm] = !currentStep ? 'Update Profile' : 'Signup Complete'
//            if (imageStatus) tealiumDetails[TEALIUM.profilePicture] = imageStatus
//            tealiumLink(tealiumDetails)
//        }

//        dispatch(updateProfileSuccess(response))
//        if (user && user.hasOwnProperty('currentpin') && getInfo) dispatch(getProfileInfo(user.currentpin))
//        return response
//    } catch (e) {
//        dispatch(updateProfileError(e))
//        throw e
//    }
//}

export const updateProfile = (currentStep, getInfo = true) => async dispatch => {
 
    const user = JSON.parse(sessionStorage.getItem('user'))

    

        const profileRequestObject = createProfileObject(currentStep)
        const url = getApiURL(window.env.API_PROFILES_ENDPOINT)
        dispatch(updateProfileRequest())
        try {

            const response = await http.put(url, profileRequestObject)
       
            if (currentStep === 'COUPON') {
                dispatch(getProfileInfo(user.currentpin))
            }

            // if (currentStep === 'COMPLETE' || !currentStep) {
            //     tealiumDetails[TEALIUM.shopForm] = !currentStep ? 'Update Profile' : 'Signup Complete'
            //     if (imageStatus) tealiumDetails[TEALIUM.profilePicture] = imageStatus
            //     tealiumLink(tealiumDetails)
            // }
            if (currentStep === 'COMPLETE' || !currentStep) {
                let tempValue = !currentStep ? 'Update Profile' : 'Signup Complete'
                sessionStorage.setItem(TEALIUM.singup_status, tempValue)
                if (imageStatus) {
                    sessionStorage.setItem(TEALIUM.shop_status, imageStatus)
                }
                console.log('No tealiumLink trigger')
            }

            dispatch(updateProfileSuccess(response))
            if (user && user.hasOwnProperty('currentpin') && getInfo) dispatch(getProfileInfo(user.currentpin))
            return response
        
        } catch (e) {
            dispatch(updateProfileError(e))
            throw e
        }
    
}

const updateProfileError = error => ({type: UPDATE_PROFILE_ERROR, payload: error})
const updateProfileRequest = () => ({type: UPDATE_PROFILE_REQUEST})
const updateProfileSuccess = data => ({type: UPDATE_PROFILE_SUCCESS, payload: data})

import React from 'react'
import './closeButton.scss'

export default function CloseButton ({white, onClick, disabled}) {
    const className = ["close-button", (white ? 'white' : '')].join(' ')

    if (disabled) return <i className={className}/>

    return <button
            className={className}
            onClick={onClick}
    />
}
import React from 'react'
import {Col, Row, ScreenClassRender} from 'react-grid-system'
import BodyHeader from "../body-header"
import Box from "../../suite/box"
import "./accountPage.scss"
import InfoProfile from "../StepOne/InfoProfile";
import ButtonAutoZone from "../../suite/button-autozone";
import colors from "../../../utilities/colors";
import {connect} from "react-redux";
import {getProfileInfo} from "../../../redux/actions/profile";
import {setComponent} from "../../../redux/actions/global";
import Coupon from "../../suite/coupon";
import {getCouponTitle, maskPhone} from "../../../utilities/functions";
import {couponTypes, systemErrors} from "../../../utilities/constants";
import ErrorMessage from "../../suite/error-message"
import Localization from "../../../utilities/localization";

class AccountPage extends React.Component {
    componentDidMount() {
        this.getProfileInfo()
        let name = 'Account'
        this.props.setComponent(name)
    }

    getProfileInfo () {
        const {profile: {loading}} = this.props
        const user = JSON.parse(sessionStorage.getItem('user'))
        if (user && user.hasOwnProperty('currentpin') && !loading) this.props.getProfileInfo(user.currentpin)
    }

    /**
     * Returns Profile button width according to device size
     * @param screenClass
     * @returns {null|number}
     */
    getButtonWidth (screenClass) {
        switch (screenClass) {
            case "xl": return 168
            case "lg":
            case "md": return 168
            case "sm": return 254
            case "xs": return 254
            default: return null
        }
    }

    /**
     * Redirects user to page with forms to edit
     * all account values
     */
    goToEditAccount = () => {
       
        const {history} = this.props
        history.push('/EditAccount')
    }

    


    /**
     * Render Active Coupons
     */
    _renderCoupons(){
        const {profile: {data: profile}, steps: {stepFour: {coupons}}} = this.props
        return(
            <>
                {(coupons) && (
                    <fieldset className="coupons">
                        <legend>{Localization.print("Active Coupons")}</legend>
                        <Row nogutter>
                            {coupons.filter(coupon => !coupon.deleted).map((coupon, i) => (
                                    <Col lg={3} md={4} sm={6} key={i} className={'coupon-col'}>
                                        <h3>{coupon.name}</h3>
                                        <div className="coupon">
                                            <Coupon
                                                    disclaimerText={coupon.disclaimerText}
                                                    endDate={coupon.endDate}
                                                    isFree={coupon.type === couponTypes.free}
                                                    salesText={coupon.salesText}
                                                    shopAddress={[
                                                            profile.address.addressLine1 + ',',
                                                            profile.address.city + ',',
                                                            profile.address.state,
                                                            profile.address.zip
                                                    ].join(' ')}
                                                    shopContactEmail={profile.contactInfo.email}
                                                    shopName={profile.shopName}
                                                    shopPhoneNumber={profile.contactInfo.shopNumber ? maskPhone(profile.contactInfo.shopNumber) : ''}
                                                    size={'xs'}
                                                    startDate={coupon.startDate}
                                                    title={getCouponTitle(coupon)}
                                            />
                                        </div>
                                    </Col>
                            ))}
                        </Row>

                    </fieldset>
            )}</>
        )
    }

    render () {
        const {profile: {data: profile}, steps: {systemError, stepFour: {coupons}}} = this.props
        const error = systemErrors.find(item => item.key === systemError)
        return (
                <ScreenClassRender render={screenClass => (

                        <div className={'AccountPage'}>
                            <div className="body-header">
                                <BodyHeader />
                            </div>
                            {!!systemError && (<ErrorMessage messageText={error.message} />)}
                            {!systemError && (<section className="box-group">
                                <Box title={Localization.print("Shop Information")} width="100%">
                                    <InfoProfile />

                                    {(coupons && coupons.filter(coupon => !coupon.deleted).length > 0)
                                            ? this._renderCoupons()
                                            : <div style={{height: 18}} />
                                    }

                                </Box>
                                
                                <div className="informationButtonContainer">
                                    <Row>
                                        <Col lg={4} offset={{ lg: 8 }} >
                                            <ButtonAutoZone
                                                    id={'update-profile'}
                                                    text={Localization.print("EDIT SHOP PROFILE")}
                                                    width={this.getButtonWidth(screenClass)}
                                                    height='28px'
                                                    borderColor={colors.autozoneOrangeColor}
                                                    backgroundColor='white'
                                                    color={colors.autozoneOrangeColor}
                                                    handler={this.goToEditAccount}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </section> )}
                        </div>
                )} />
        )
    }
}

export default connect(({profile, steps}) => ({profile, steps}), {getProfileInfo,setComponent})(AccountPage)

import React, {useEffect} from 'react'
import ReactDom from 'react-dom'
import './portal.scss'
import {scrollIframeToViewPort} from "../../utilities/functions";

const Portal = props => {

    // This is equivalent to componentDidMount
    useEffect(() => {
        scrollIframeToViewPort('#portal-container')
    }, [])

    return ReactDom.createPortal(
            <div className="portal">
                <div id={"portal-container"}>{props.children}</div>
            </div>, document.getElementById('portal-root')
    )
}

export default Portal;
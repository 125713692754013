import http from "../../utilities/http";
import * as auth from "../../utilities/auth";
import * as profile from "../../redux/actions/profile"
import store from "../../redux/store"
import {ObjectWithoutKeys} from "../../utilities/functions";

export const ADD_TOKEN = 'ADD_TOKEN'
export const REMOVE_TOKEN = 'REMOVE_TOKEN'

export const GET_TOKEN_INFO_ERROR = 'GET_TOKEN_INFO_ERROR'
export const GET_TOKEN_INFO_REQUEST = 'GET_TOKEN_INFO_REQUEST'
export const GET_TOKEN_INFO_SUCCESS = 'GET_TOKEN_INFO_SUCCESS'

export const REQUEST_TOKEN_ERROR = 'REQUEST_TOKEN_ERROR'
export const REQUEST_TOKEN_REQUEST = 'REQUEST_TOKEN_REQUEST'
export const REQUEST_TOKEN_SUCCESS = 'REQUEST_TOKEN_SUCCESS'

export const SHOW_TOKEN = 'SHOW_TOKEN'

// Static Actions
export function addToken(data) {
    return {
        type: ADD_TOKEN,
        payload: data
    }
}

export function removeToken() {
    return {
        type: REMOVE_TOKEN
    }
}

// Async Actions
export const getTokenInfo = () => async dispatch => {
    const profileURL = [window.env.GATEWAY_BASE_URL, '/auth/oauth/v2/tokeninfo'].join('')
    //const profileURL = 'https://dv-blazer1-dx61.autozone.com/rest/bean/autozone/pro/userprofiling/AZProfileAggregatedDataService/aggregatedProfileData'
    dispatch(getTokenInfoRequest())
    try {
        const {data} = await http.get(profileURL, {withCredentials: true})
        dispatch(getTokenInfoSuccess(data))
        sessionStorage.setItem('user', JSON.stringify(data.claims))
        //console.log('>>>>>>>Abhijit' + JSON.stringify(data))
        setTimeout(() => {
            const {steps: {stepOne: {profileInfo: {customerID}}}} = store.getState()
            // dispatch(profile.getProfileInfo(customerID))
            dispatch(profile.checkEligibility(customerID))
            dispatch(profile.getProfileStatus(customerID))
        }, 0)
    } catch (e) {
        dispatch(getTokenInfoError(e))
    }
}

const getTokenInfoError = error => ({type: GET_TOKEN_INFO_ERROR, payload: error})
const getTokenInfoRequest = () => ({type: GET_TOKEN_INFO_REQUEST})
const getTokenInfoSuccess = data => ({type: GET_TOKEN_INFO_SUCCESS, payload: data})

export const requestToken = (history = null) => async dispatch => {
    try {
        dispatch(requestTokenRequest())
        const response = await auth.requestToken()
        const {data} = response
        if (sessionStorage.requestingToken) sessionStorage.removeItem('requestingToken')
        if (data.access_token) {
            const _data = ObjectWithoutKeys(data, ['refresh_token'])
            sessionStorage.setItem('token', JSON.stringify(_data))
            const previousPath = sessionStorage.getItem('previousPath') || '';
            (history ? history : window.location).replace(`/${previousPath}`)
            dispatch(showToken(response))
        }
    } catch (e) {
        dispatch(requestTokenError(e))
    }
}

const requestTokenError = error => ({type: REQUEST_TOKEN_ERROR, payload: error})
const requestTokenRequest = () => ({type: REQUEST_TOKEN_REQUEST})
const requestTokenSuccess = data => ({type: REQUEST_TOKEN_SUCCESS, payload: data})

export const showToken = response => ({type: SHOW_TOKEN, payload: response})

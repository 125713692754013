import React from 'react'
import { Row, Col } from 'react-grid-system';
import {withRouter} from 'react-router-dom'
import './tnc.scss'
import Box from '../../suite/box'
import {isIframe} from "../../../utilities/functions";
import qs from "querystring";

let locale = 'us'

if (window.location.search) {
    const params = qs.parse(window.location.search.replace(/[\?|\/]/g, ''))
    if (undefined !== params.v && (params.v === 'mx' || params.v === 'es')) {
        locale = 'es'
    }
}

class Terms extends React.Component {
    render() {
        return  (
                <div id="termsnconditions">   
                    {locale === 'es' ? (
                        <Row gutterWidth={16}>
                            <Col sm={(!isIframe() ? 9 : 12)} md={(!isIframe() ? 9 : 12)}>
                            
                                <Box width='100%' backgroundColor='#6c6f70' color='#ffffff' title='Programa de Referidos de AutoZone Profesional'>                                
                                    <div id="terms">  
                                        <strong>DEFINICIONES.</strong>

                                        <p> Bienvenido al Programa de Referidos AutoZone (el "Programa"). El presente Acuerdo aplica para su participación en el Programa, sujeto a los siguientes términos y condiciones ("TCS"). El Programa está diseñado para referir a los clientes de las tiendas de retail de AutoZone a las cuentas de Clientes Afiliados Profesionales designados por AutoZone ("Cuentahabiente Profesional") y puede, a discreción exclusiva de AutoZone, desplegar información de contacto del Cuentahabiente Profesional en www.autozone.com.mx o cualquier otro sitio web controlado por AutoZone de México, S. de R.L. de C.V., o de sus filiales.</p>

                                        <strong>ACEPTACIÓN DE TCS.</strong>

                                        <p>La participación en el Programa está condicionada y sujeta a la aceptación de los TCS. Al suscribirse para participar en el Programa, se considerará como una aceptación del Cuentahabiente Profesional de los TCS. Los TCS pueden ser actualizados por AutoZone de vez en cuando sin previo aviso al Cuentahabiente Profesional. Cualquier modificación se verá reflejada en el formato de los términos y condiciones vigentes de los Representantes de Ventas de AutoZone Profesional. La participación continua de un Cuentahabiente Profesional en el Programa se considerará una aceptación de los TCS entonces vigentes.</p>

                                        <strong>DESCRIPCIÓN DEL SERVICIO. </strong>

                                        <p>El Programa es un servicio adicional puesto a disposición del Cuentahabiente Profesional. A menos que se indique expresamente lo contrario, cualquier nueva aplicación que aumente o mejore el Programa actual, incluyendo el lanzamiento de nuevos recursos, estará sujeta a los TCS.</p>

                                        <strong>OBLIGACIONES FINANCIERAS.</strong>

                                        <p>La participación de un Cuentahabiente Profesional en el Programa depende de que el Cuentahabiente Profesional mantenga el estado como cuenta de Clientes Profesionales Referidos de AutoZone. Con el fin de continuar su participación en el Programa, el Cuentahabiente Profesional debe mantener sus saldos de cuentas y los requisitos de compra actualizados y en buen estado. El incumplimiento de un Cuentahabiente Profesional para mantener su saldo de cuenta y requisitos de compra actualizados podrá, a criterio exclusivo de AutoZone, dar lugar a la suspensión inmediata de la participación en el Programa. Si los requisitos de Clientes Profesionales Referidos de AutoZone se cumplen nuevamente, el Cuentahabiente Profesional podrá reanudar su participación en el Programa, a criterio exclusivo de AutoZone.</p>

                                        <strong>INFORMACIÓN DE REGISTRO</strong>

                                        <p>En consideración del uso del Servicio, el Cuentahabiente Profesional se compromete a: (a) proveer información verdadera, precisa, actual y completa acerca del Cuentahabiente Profesional y de la compañía del Cuentahabiente Profesional como sea requerido en el formulario de registro del Servicio (siendo dicha información los "Datos de Registro"), y (b) conservar y actualizar los Datos de Registro para mantenerlos verdaderos, precisos, actualizados y completos. Si el Cuentahabiente Profesional proporciona información que sea falsa, inexacta, desactualizada o incompleta, o AutoZone tiene motivos razonables para sospechar que dicha información es falsa, inexacta, desactualizada o incompleta, AutoZone tiene el derecho de suspender o terminar la cuenta del Cuentahabiente Profesional y cancelar la participación del Cuentahabiente Profesional en el Programa. El Cuentahabiente Profesional también garantiza y declara que el Cuentahabiente Profesional tiene la autoridad para obligar y vincular al Cuentahabiente Profesional y a la empresa del Cuentahabiente Profesional a los TCS.</p>

                                        <strong>USO DEL CONTENIDO POR PARTE DE AUTOZONE; RENUNCIA</strong>

                                        <p>AutoZone no reclama la propiedad de ningún contenido que el Cuentahabiente Profesional proporcione a AutoZone para uso en el Programa. Al enviar contenido a AutoZone para su inclusión en el Programa, el Cuentahabiente Profesional otorga a AutoZone una licencia mundial, libre de regalías, no exclusiva e irrevocable para reproducir, modificar, adaptar y publicar el contenido con el único fin de mostrar, distribuir y promocionar al Cuentahabiente Profesional a través del Programa. Esta licencia es válida sólo durante el tiempo que el Cuentahabiente Profesional continúe teniendo una cuenta de Clientes Profesionales Referidos de AutoZone y concluirá en el momento en que termine o cambie dicho estado. El Cuentahabiente Profesional reconoce que AutoZone no revisa previamente el contenido, pero que AutoZone y quien este designe tendrán el derecho de remover cualquier contenido que viole estos TCS o que sea de dudosa reputación. Usted acepta que usted deberá evaluar y asumir todos los riesgos asociados con el uso de cualquier Contenido, incluyendo la confiabilidad en su exactitud, integridad o utilidad de dicho Contenido.</p>

                                        <strong>MODIFICACIONES DEL SERVICIO</strong>

                                        <p>AutoZone se reserva el derecho en cualquier momento y de vez en cuando, para modificar o interrumpir temporal o permanentemente el Programa (o cualquier parte del mismo) con o sin previo aviso. El Cuentahabiente Profesional acepta que AutoZone no será responsable frente al Cuentahabiente Profesional o frente a cualquier tercero por ninguna modificación, suspensión o interrupción, o terminación válida del Servicio.</p>

                                        <strong>DERECHO DE NO REVENDER.</strong>

                                        <p>El Cuentahabiente Profesional acepta no reproducir, duplicar, copiar, vender, revender o explotar cualquier parte del Programa, el uso o el acceso al Programa.</p>

                                        <strong>RENUNCIA A LA GARANTÍA</strong>

                                        <p>AUTOZONE NO GARANTIZA QUE (I) EL PROGRAMA CUMPLIRARÁ LOS REQUERIMIENTOS DEL CUENTAHABIENTE PROFESIONAL O DE CUALQUIER TERCERO, (II) EL PROGRAMA NO SERÁ INTERRUMPIDO, PUNTUAL, SEGURO, O LIBRE DE ERRORES, (III) LOS RESULTADOS QUE SE PUEDEN OBTENER DEL USO DEL PROGRAMA SEAN EXACTOS O CONFIABLES, O (IV) LA CALIDAD DE CUALESQUIER PRODUCTOS, SERVICIOS, INFORMACIÓN U OTRO MATERIAL COMPRADO U OBTENIDO POR EL CUENTAHABIENTE PROFESIONAL O DE CUALQUIER TERCERO A TRAVÉS DEL PROGRAMA CUMPLIRÁ CON LAS EXPECTATIVAS DEL CUENTAHABIENTE PROFESIONAL O DE CUALQUIER TERCERO. NINGÚN CONSEJO O INFORMACIÓN, YA SEA ORAL O ESCRITO, OBTENIDO POR EL CUENTAHABIENTE PROFESIONAL DE CUALQUIER TERCERO, DE AUTOZONE O POR MEDIO O DEL PROGRAMA NO CREARÁ NINGUNA GARANTÍA QUE NO ESTÉ EXPRESAMENTE ESTABLECIDA EN LOS TCS.</p>

                                        <strong>EXCLUSIONES Y ELECCIÓN DE LEY. </strong>

                                        <p>ALGUNAS JURISDICCIONES NO PERMITEN LA EXCLUSIÓN DE CIERTAS GARANTÍAS O LA LIMITACIÓN O EXCLUSIÓN DE RESPONSABILIDAD POR DAÑOS INCIDENTALES, DERIVADOS O CONSECUENCIALES. EN CONSECUENCIA ALGUNAS DE LAS LIMITACIONES DE LAS SECCIONES ANTERIORES PODRÍAN NO APLICARLE A USTED. NO OBSTANTE, EL CUENTAHABIENTE PROFESIONAL SE SOMETE A LA JURISDICCIÓN DEL ESTADO DE NUEVO LEON, MEXICO, Y ACEPTA A QUEDAR SUJETO POR DICHAS EXCLUSIONES. EL PRESENTE ACUERDO SE REGIRÁ E INTERPRETARÁ DE ACUERDO CON LAS LEYES DEL ESTADO DE NUEVO LEON, SIN DAR EFECTO A OTROS PRINCIPIOS LEGALES. LAS PARTES SE SOMETEN A LA JURISDICCIÓN DE LOS TRIBUNALES LOCALES, ESTATALES Y FEDERALES UBICADOS EN MONTERREY, NUEVO LEÓN. TODAS Y CADA UNA DE LAS CONTROVERSIAS O RECLAMACIONES SURGIDAS BAJO ESTE ACUERDO SE LLEVARÁN ANTE LOS TRIBUNALES LOCALES, ESTATALES O FEDERALES CORRESPONDIENTES UBICADOS EN MONTERREY, NUEVO LEÓN MEXICO.</p>

                                        <strong>APERCIBIMIENTO.</strong>

                                        <p>AutoZone no tiene obligación de notificar al Cuentahabiente Profesional de los cambios en el Programa. El Cuentahabiente Profesional acepta que cualquier notificación se pueda dar a través de correo electrónico o correo regular. En caso de avisos por correo regular, la entrega será efectiva cuando se reciba la notificación. En cuanto a las notificaciones por correo electrónico, la notificación será efectiva después de una confirmación de entrega a AutoZone. El Programa también puede proveer notificaciones de los cambios en los TCS u otros asuntos, mostrándole al Cuentahabiente Profesional avisos o enlaces de anuncios con carácter general en el Servicio.</p>

                                        <strong>INDEMNIZACIÓN. </strong>

                                        <p>a) El Cuentahabiente Profesional será responsable y deberá indemnizar y sacar en paz y a salvo a AutoZone de y contra todos los daños, reclamaciones o demandas que surjan, resulten o estén de cualquier forma relacionadas u ocasionadas por actos intencionales o negligentes del Cuentahabiente Profesional o de los empleados del Cuentahabiente Profesional, o de hechos violatorios o de no-hacer de cualquiera de las obligaciones del Cuentahabiente Profesional aquí descritas; </p>

                                        <p>(b) Adicionalmente, el Cuentahabiente Profesional acepta ser responsable y deberá indemnizar y mantener en paz y a salvo a AutoZone de y contra todos los daños, reclamaciones o demandas que surjan, resulten o estén de cualquier forma relacionados con: (i) Instalación incorrecta por parte del Cuentahabiente Profesional de cualquier parte o accesorio de terceros que busquen servicios del Cuentahabiente Profesional como resultado de una referencia del Programa; o (ii) cualquier violación de una garantía expresa realizada por el Cuentahabiente Profesional a terceros que buscan los servicios del Cuentahabiente Profesional como resultado de una referencia del Programa que exceda el alcance de las garantías expresas de los fabricantes.</p>

                                        <strong>ACUERDO COMPLETO.</strong>

                                        <p>Los TCS, la política de privacidad descrita en la página web www.autozone.com.mx, y otras condiciones de uso del Programa que puedan ser instituidas por AutoZone según lo previsto en estos TCS, constituyen el acuerdo completo entre el Cuentahabiente Profesional y AutoZone con respecto al Programa, y determinan la participación del Cuentahabiente Profesional en el Programa, y reemplazan cualquier acuerdo anterior entre el Cuentahabiente Profesional y AutoZone respecto al Programa, incluyendo, pero no limitado a cualquier versión previa de los TCS, y temas razonablemente relacionados con los mismos. Los Términos de Servicio relativos a otros servicios o productos de AutoZone se rigen por Términos de Servicio independientes.</p>

                                        <strong>RENUNCIA. </strong>

                                        <p>La falta de ejercicio por parte de AutoZone para hacer valer cualquier derecho o disposición de este o de cualquier otros TCS no constituirá una renuncia a tal derecho o disposición. La falta de AutoZone en cualquier momento para exigir el cumplimiento al Cuentahabiente Profesional de alguna de las disposiciones aplicable de los TCS no afectará en modo alguno el derecho de AutoZone para ejercitar tal disposición, ni se considerará que la renuncia por parte de AutoZone de cualquier violación a cualquier disposición de los TCS aplicables, se considerará una renuncia de violaciones de dicha disposición o de cualquier otra disposición.</p>

                                        <strong>DIVISIBILIDAD.</strong>

                                        <p>Si alguna disposición de este o cualquier otros TCS aplicables se considera inválida, ilegal o inejecutable, la validez, legalidad y aplicabilidad de las disposiciones restantes de ninguna manera será afectado o alterado. Cualquier disposición ilegal, inválida o no ejecutable en los TCS deberá ser modificada para ajustarse a la ley, regla, regulación, o normatividad con la que entra en conflicto.</p>

                                        <strong>RESPONSABILIDAD LIMITADA. </strong>

                                        <p>EL CUENTAHABIENTE PROFESIONAL ENTIENDE Y ACEPTA EXPRESAMENTE QUE AUTOZONE NO SERÁ RESPONSABLE POR DAÑOS DIRECTOS, INDIRECTOS, ESPECIALES, CONSECUENCIALES O EJEMPLARES, INCLUYENDO PERO NO LIMITADO A DAÑOS POR PÉRDIDA DE BENEFICIOS, BUENA FE, USO, DATOS U OTRAS PÉRDIDAS INTANGIBLES (INCLUSO SI AUTOZONE HA SIDO ADVERTIDO DE LA POSIBILIDAD DE TALES DAÑOS), COMO RESULTADO DE: (I) EL USO O LA IMPOSIBILIDAD DE USO DEL PROGRAMA; (II) EL COSTO DE ADQUISICIÓN DE BIENES Y SERVICIOS ADICIONALES COMO RESULTADO DE CUALQUIER BIEN, DATOS, INFORMACIÓN O SERVICIOS ADQUIRIDOS U OBTENIDOS, O MENSAJES RECIBIDOS O TRANSACCIONES REALIZADAS A TRAVÉS DEL SERVICIO; (III) ACCESO NO AUTORIZADO O ALTERACIÓN DE LOS CONTENIDOS DEL CUENTAHABIENTE PROFESIONAL, TRANSMISIONES O DATOS; (IV) DECLARACIONES O CONDUCTAS DE LOS TERCEROS QUE FIGUREN EN EL PROGRAMA; O (V) DE CUALQUIER OTRO ASUNTO RELACIONADO CON EL SERVICIO.</p>

                                        <strong>LIMITACION DE PLAZO DE RECLAMO.</strong>

                                        <p>El Cuentahabiente Profesional acepta que independientemente de cualquier estatuto o ley que establezca lo contrario, cualquier reclamación o causa de acción que surja de o esté relacionada con el uso del Servicio o de los TCS deberá ser presentada dentro de un (1) año después que dicho reclamo o causa de acción, o quedará sin efecto alguno. Los títulos de las secciones en los TCS son únicamente para conveniencia y no tienen ningún efecto legal o contractual.</p>

                                        <strong>RESPONSABILIDAD DE INFORMAR DEL CUENTAHABIENTE PROFESIONAL.</strong>

                                        <p>El Cuentahabiente Profesional acepta reportar a AutoZone cualquier violación de los TCS de la que tenga conocimiento, ya sea por correo electrónico a la dirección servicioacliente@autozone.com, fax, o mediante las formas aplicables o por correo certificado de Mexico al domicilio Avenida Fundadores 1001, Colonia Alfareros, Monterrey, Nuevo León, México, 64753.</p>

                                        <p>© 2019.AutoZone de Mexico, S. de R.L. de C.V. Todos los derechos reservados.</p>
                                </div>
                            </Box>
                        </Col>
                    </Row>
                    ) : (              
                    <Row gutterWidth={16}>
                        <Col sm={(!isIframe() ? 9 : 12)} md={(!isIframe() ? 9 : 12)}>                        
                            <Box width='100%' backgroundColor='#6c6f70' color='#ffffff' title='AutoZone Commercial Shop Referral'>                                
                                <div id="terms">   
                                    <strong>DEFINITIONS</strong>

                                    <p>Welcome to the AutoZone Referral Program (the “Program”). This Agreement applies to your participation in the Program, subject to these following Terms and Conditions (“TCS”). The Program is designed to refer AutoZone’s retail store and online customers to AutoZone’s Preferred Commercial accounts (“Accounts”) and may, at AutoZone’s sole discretion, display Account contact information on AutoZone.com or any other web site controlled by AutoZone, Inc. or its affiliates.</p>

                                    <strong>ACCEPTANCE OF TCS.</strong>

                                    <p>Participation in the Program is conditioned on acceptance of the TCS and subject to the TCS. By signing up to participate in the Program, an Account shall be deemed as an acceptance of the TCS. The TCS may be updated by AutoZone from time to time without notice to Accounts. Any modifications will be reflected in the current terms and conditions available form AutoZone’s Commercial Sales Representatives. An Account’s continued participation in the Program shall be deemed an acceptance of the then current TCS.</p>

                                    <strong>DESCRIPTION OF SERVICE.</strong>

                                    <p>The Program is an additional service made available to the Accounts. Unless explicitly stated otherwise, any new features that augment or enhance the current Program, including the release of new resources, shall be subject to the TCS.</p>

                                    <strong>FINANCIAL OBLIGATIONS.</strong>

                                    <p>An Account’s participation in the Program is conditional on the Account maintaining status as a Preferred AutoZone Commercial account. In order to continue participation in the Program, an Account must keep its account balances and purchase requirements up to date and in good standing. Failure of an Account to keep its account balance and purchase requirements current will, at AutoZone’s sole option, result in the immediate suspension from participation in the Program. If the AutoZone Preferred account requirements are met again, the Account may resume participation in the Program, at AutoZone’s sole discretion.</p>

                                    <strong>REGISTRATION INFORMATION.</strong>

                                    <p>In consideration of use of the Service, Account agrees to: (a) provide true, accurate, current and complete information about Account and Account’s company as prompted by the Service’s registration form (such information being the “Registration Data”) and (b) maintain and promptly update the Registration Data to keep it true, accurate, current and complete. If Account provides any information that is untrue, inaccurate, not current or incomplete, or AutoZone has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, AutoZone has the right to suspend or terminate Account’s AutoZone commercial account and cancel Account’s participation in the program. Account also warrants and represents that the Account has the authority to enter into and to bind the Account and Account’s company to the TCS.</p>

                                    <strong>AUTOZONE USE OF CONTENT; DISCLAIMER.</strong>

                                    <p>AutoZone does not claim ownership of any content any Account provides to AutoZone for use in the Program. By submitting content to AutoZone for inclusion in the Program, you grant AutoZone the world-wide, royalty-free, and non-exclusive, non-revocable license to reproduce, modify, adapt and publish the content solely for the purpose of displaying, distributing and promoting Account through the Program. This license exists only for as long as you continue to be an AutoZone Preferred Commercial account and shall be terminated at the time your Preferred Account status is terminated. Account acknowledges that AutoZone does not pre-screen content, but that AutoZone and its designees shall have the right (but not the obligation) in their sole discretion to refuse or remove any content that is provided by Account for use in the Program. Without limiting the foregoing, AutoZone and its designees shall have the right to remove any content that violates these TCS or is otherwise objectionable. You agree that you must evaluate, and bear all risks associated with, the use of any Content, including any reliance on the accuracy, completeness, or usefulness of such Content.</p>

                                    <strong>MODIFICATIONS OF SERVICE.</strong>

                                    <p>AutoZone reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Program (or any part thereof) with or without notice. Account agrees that AutoZone shall not be liable to Account or to any third party for any modification, suspension or discontinuance, or valid termination of the Service.</p>

                                    <strong>NO RIGHT TO RESELL.</strong>

                                    <p>Account agrees not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Program, use of the Program, or access to the Program.</p>

                                    <strong>WARRANTY DISCLAIMER.</strong>

                                    <p>AUTOZONE MAKES NO WARRANTY THAT (i) THE PROGRAM WILL MEET ACCOUNT’S OR ANY OTHER PARTY’S REQUIREMENTS, (ii) THE PROGRAM WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE PROGRAM WILL BE ACCURATE OR RELIABLE, OR (iv) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY ACCOUNT OR ANY OTHER PARTY THROUGH THE PROGRAM WILL MEET ACCOUNT’S OR ANY OTHER PARTY’S EXPECTATIONS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY ACCOUNT OF ANY OTHER PARTY FROM AUTOZONE OR THROUGH OR FROM THE PROGRAM SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TCS.</p>

                                    <strong>EXCLUSIONS AND CHOICE OF LAW.</strong>

                                    <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS OF SECTIONS ABOVE MAY NOT APPLY TO YOU. NEVERTHELESS, ACCOUNT SUBMITS TO THE JURISDICTION OF THE UNITED STATES AND TENNESSEE AND AGREES TO BE BOUND BY SUCH EXCLUSIONS. THIS AGREEMENT SHALL BE GOVERNED BY AND SHALL BE CONSTRUED IN ACCORDANCE WITH THE LAWS OF THE STATE OF TENNESSEE WITHOUT GIVING EFFECT TO PRINCIPLES OF LAW THEREOF. THE PARTIES HEREBY CONSENT TO THE JURISDICTION OF THE LOCAL, STATE AND FEDERAL COURTS LOCATED IN SHELBY COUNTY, TENNESSEE. ANY AND ALL DISPUTES OR CLAIMS ARISING UNDER THIS AGREEMENT SHALL BE BROUGHT IN THE APPROPRIATE LOCAL, STATE OR FEDERAL COURT LOCATED IN SHELBY COUNTY, TENNESSEE.</p>

                                    <strong>NOTICE.</strong>

                                    <p>AutoZone has no duty to notify Account to changes to the Program. Account agrees that any notices give may be given to Account via either email or regular mail. In case of regular mail notices, the delivery shall be effective when the notice is received. As to email notices, the notice shall be effective upon a delivery receipt delivered to AutoZone. The Program may also provide notices of changes to the TCS or other matters by displaying notices or links to notices to Account generally on the Service.</p>

                                    <strong>INDEMNIFICATION.</strong>

                                    <p>(a) Account shall be responsible for and shall indemnify and hold AutoZone harmless from and against all damages, claims or demands that may arise out of, result from, be in any way connected with or be occasioned by the negligent or intentional acts of Account or Account’s employees or Account’s breach or non-performance of any of its obligations hereunder.</p>

                                    <p>(b) Further, Account agrees to be responsible for and shall indemnify and hold AutoZone harmless from and against all damages, claims or demands that arise out of, result from, or are in any way connected with (i) Account’s improper installation of any parts or accessories for a third party who seeks services from the Account as a result of a referral from the Program; or (ii) any breach of an express warranty made by Account to any third party who seeks services from the Account as a result of a referral from the Program that exceeds the scope of the manufacturers’ express warranties.</p>

                                    <strong>COMPLETE AGREEMENT.</strong>

                                    <p>The TCS, privacy policy, and other terms of use of the Program as may be instituted by AutoZone as provided for in these TCS constitute the entire agreement between Account and AutoZone regarding the Program and govern Account’s participation in the Program, superceding any prior agreements between Account and AutoZone, including, but not limited to, any prior versions of the TCS, and the subject matter reasonably related thereto. Terms of Service relating to other AutoZone services or Products are governed by separate Terms of Service.</p>

                                    <strong>WAIVER.</strong>

                                    <p>The failure of AutoZone to exercise or enforce any right or provision of this or any other TCS shall not constitute a waiver of such right or provision. The failure of AutoZone at any time to require performance by Account of any provision of the applicable TCS shall in no way affect AutoZone’s right to enforce such provision, nor shall the waiver by AutoZone of any breach of any provision of the applicable TCS be taken or held to be a waiver of any further breach of the same provision or any other provision.</p>

                                    <strong>SEVERABILITY.</strong>

                                    <p>If any provision of this or any other applicable TCS is held to be invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions shall in no way be affected or impaired. Any illegal, invalid, or unenforceable provision in the TCS shall be modified to conform to the law, rule, regulation, or guideline with which it conflicts.</p>

                                    <strong>LIMITATION OF LIABILITY.</strong>

                                    <p>ACCOUNT EXPRESSLY UNDERSTANDS AND AGREES THAT AUTOZONE SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF AutoZone HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE INABILITY TO USE THE PROGRAM; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF ACCOUNT’S CONTENT, TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY CONTAINED IN THE PROGRAM; OR (v) ANY OTHER MATTER RELATING TO THE SERVICE.</p>

                                    <strong>CLAIM TIMEFRAME LIMITATION.</strong>

                                    <p>Account agrees that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Service or the TCS must be filed within one (1) year after such claim or cause of action arose or be forever barred. The section titles in the TCS are for convenience only and have no legal or contractual effect.</p>

                                    <strong>ACCOUNT RESPONSIBILITY TO REPORT.</strong>

                                    <p>Account agrees to report any known violations of the TCS to AutoZone either by email, facsimile, applicable forms or by US mail to AutoZone Department 9002, 123 S. Front Street, Memphis TN 38103.</p>

                                    <p>© 2006-2019. AutoZone, Inc. All Rights Reserved.</p>
                                                                                                                                                        
                                </div>
                            </Box>
                            
                        </Col>
                    </Row>
                    )}
                </div>
        )
    }
} 

export default withRouter(Terms)

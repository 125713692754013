import * as global from '../actions/global'

const defaultState = {
    isConnected: true,
    locale: 'us',
    showingModal: false,
    componentName:'',
}

export default function (state = defaultState, action) {
    switch (action.type) {
        case global.SET_NETWORK_STATE:
            return {
                ...state,
                isConnected: action.payload
            }

        case global.SET_MODAL_STATE:
            return {
                ...state,
                showingModal: action.payload
            }

        case global.SET_LOCALE:
            return {
                ...state,
                locale: action.payload
            }
        case global.SET_COMPONENT:
            return {
                ...state,
                componentName: action.payload
            }

        default:
            return state
    }
}

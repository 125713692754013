import React from 'react'
import PropTypes from "prop-types"
import ModalHeader from "../../../../suite/modalHeader"
import ModalFooter from "../../../../suite/modalFooter";
import colors from "../../../../../utilities/colors";
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.min.css'
import './imageEditor.scss'
import Localization from "../../../../../utilities/localization";

export default class ImageEditor extends React.Component {
    cropper = null
    cropSettings = {
        fillColor: '#fff',
        minHeight: 70,
        minWidth: 70
    }

    componentDidMount() {
        this.cache()
        this.events()
    }

    componentWillUnmount () {
        if (this.cropper) this.cropper.destroy()
        if (this.imageNode) {
            this.imageNode.removeEventListener('load', this.handleImageLoaded)
            this.imageNode.removeEventListener('ready', this.handleImageReady)
        }
    }

    /**
     * Saves the DOM elements needed for future usage
     */
    cache () {
        this.imageNode = document.querySelector('.editor-image')
        this.cropArea = this.imageNode.parentNode;
    }

    /**
     * Calls the subscription to events and the plugin initializer
     */
    events () {
        this.imageNode.addEventListener('load', this.handleImageLoaded)
        this.initCropper()
    }

    /**
     * Resize the crop area after obtaining the image resource
     * @param naturalHeight
     * @param naturalWidth
     */
    handleImageLoaded = ({target: {naturalHeight, naturalWidth}}) => {
        const containerHeight = this.cropArea.parentNode.clientHeight;
        const containerWidth = this.cropArea.parentNode.clientWidth;
        let height, width;
        // If image is vertical
        if (naturalHeight >= naturalWidth) {
            height = naturalHeight > containerHeight ? containerHeight : naturalHeight;
            width = (naturalWidth * height) / naturalHeight;
        }
        // If image is horizontal
        else {
            width = naturalWidth > containerWidth ? containerWidth : naturalWidth;
            height = (naturalHeight * width) / naturalWidth;
            // The resulting height exceeds the container height
            if (height > containerHeight) {
                height = naturalHeight > containerHeight ? containerHeight : naturalHeight;
                width = (naturalWidth * height) / naturalHeight;
            }
        }

        this.cropArea.style.height = height + 'px';
        this.cropArea.style.width = width + 'px';
    }

    handleImageReady = () => {
        const {croppedData} = this.props
        if (croppedData) {
            this.cropper.setCropBoxData(croppedData)
        }
    }

    /**
     * Handles confirmation from modal and creates the new image
     * cropping the selected area, then returns the new image to
     * parent component
     * */
    handleConfirm = () => {
        const {onConfirm} = this.props
        const picture = this.cropper.getCroppedCanvas(this.cropSettings).toDataURL('image/jpeg')
        const data = this.cropper.getCropBoxData();
        onConfirm && onConfirm(picture, data)
    }

    /**
     * Initializes cropper plugin
     * */
    initCropper = () => {
        // Initialize plugin
        this.cropper = new Cropper(this.imageNode, {
            aspectRatio: 1,
            background: true,
            center: true,
            guides: false,
            responsive: true,
            zoomable: false,
            minContainerHeight: 70,
            minContainerWidth: 70,
            minCropBoxHeight: 70,
            minCropBoxWidth: 70,
            minCanvasHeight: 70,
            minCanvasWidth: 70,
            preview: '.editor-thumbnail',
            viewMode: 1
        })

        this.imageNode.addEventListener('ready', this.handleImageReady);
    }

    render () {
        const {imageSource, onClose, onDelete} = this.props
        return (
                <div className="ImageEditor">
                    <div className="modal-body">
                        <ModalHeader handler={onClose} title={Localization.print("Edit Shop Picture")}/>
                        <div className="modal-content">
                            <div className="row">
                                <div className="editor-thumbnail">
                                    <img src={imageSource} alt={'Shop'}/>
                                </div>
                                <p>{Localization.print("Please select which part of the uploaded image you wish to use as the shop picture")}</p>
                            </div>
                            <hr/>
                            <div className="crop-container">
                                {/* Crop area component is necessary to limit the canvas area for the cropper plugin */}
                                <div className="crop-area">
                                    <img className={'editor-image'} src={imageSource} alt={'Canvas'} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalFooter
                            buttons={[
                                {text: Localization.print("DELETE"), handler: onDelete, color: colors.regularTextColor},
                                {text: Localization.print("CONFIRM"), handler: this.handleConfirm, color: colors.autozoneOrangeColor}
                            ]}
                    />
                </div>
        )
    }
}

ImageEditor.defaultProps = {
    onConfirm: () => {},
    onDelete: () => {}
}

ImageEditor.propTypes = {
    croppedData: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    onDelete: PropTypes.func
}

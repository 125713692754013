import axios from 'axios'
import qs from 'querystring'

export async function requestToken () {
    try {
        const {env} = window
        const tokenURL = [env.AUTHORIZE_URL, env.OAUTH_END_POINT, 'token'].join('')
        const tokenParams = {
            client_id: env.OAUTH_CLIENT_ID,
            grant_type: env.GW_GRANT_TYPE,
            redirect_uri: env.REACT_REDIRECT_URI,
            code: sessionStorage.getItem('code')
        }

        axios.defaults.headers.post["Content-Type"] = 'application/x-www-form-urlencoded'
        return await axios.post(tokenURL,qs.stringify(tokenParams))
    } catch (e) {
        return Promise.reject(e)
    }
}

export function getLoginURL () {
    const {env} = window
    return env.BASE_URL
}

export function getOauthLoginURL (responseType = 'token') {
    const {env} = window
    return [
        env.AUTHORIZE_URL,
        env.OAUTH_END_POINT,
        "authorize?",
        [
            `scope=${env.GW_SCOPE}`,
            `response_type=${responseType}`,
            `client_id=${env.OAUTH_CLIENT_ID}`,
            `redirect_uri=${env.REACT_REDIRECT_URI}`
        ].join('&')
    ].join('')
}
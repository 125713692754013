import * as cookie from '../actions/cookie'

export default function (state = null, action) {
    switch (action.type) {
        case cookie.ADD_COOKIE:
            return action.payload
        case cookie.REMOVE_COOKIE:
            return null
        default:
            return state
    }
}
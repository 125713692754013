import React from 'react'
import {connect} from 'react-redux'
import {Col, Row, ScreenClassRender} from "react-grid-system";
import StepWrapper from '../../body/StepWrapper'
import BodyHeader from '../body-header'
import './stepThree.scss'
import Box from '../../suite/box'
import CheckboxGroup from '../../suite/checkBoxGroup'
import {SERVICES_SECTION, systemErrors} from '../../../utilities/constants'
import {
    setThreeStepValue,
    setThreeSwitchAll,
    setThreeChangeOther,
    setThreeChangeRadiovalue
} from '../../../redux/actions'
import ErrorMessage from "../../suite/error-message";
import {isIframe, scrollIframeToViewPort} from "../../../utilities/functions";
import Localization from "../../../utilities/localization";
import {setComponent} from "../../../redux/actions/global";

class StepThree extends React.Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (isIframe()) {
            scrollIframeToViewPort('.Stepper')
        } else {
            window.scrollTo(0,0)
        }
        let name = 'Registration: Services Performed and Vehicles Serviced';
        this.props.setComponent(name);
    }

    handleSwitchAll(data) {
        this.props.setThreeSwitchAll(data)
    }

    handleSetValue(data) {
        this.props.setThreeStepValue(data)
    }

    handleChangeOther(data) {
        this.props.setThreeChangeOther(data)
    }

    render() {
        const {locale, systemError} = this.props
        const error = systemErrors.find(item => item.key === systemError)

        return (
                <ScreenClassRender render={screenClass => (
                    <React.Fragment>
                        <div className="body-header" >
                            <BodyHeader/>
                        </div>
                        {!!systemError && (<ErrorMessage messageText={error.message} />)}
                        {!systemError && (
                                <StepWrapper screenClass={screenClass}>
                                    <Box width='100%'
                                         backgroundColor='#6c6f70'
                                         color='#ffffff'
                                         title={Localization.print("Services Performed and vehicles serviced")}
                                         hint={`(${Localization.print("Check all that apply")})`}>
                                        <Row className={'boxes-horizontal stepThree'}>
                                            <Col md={6} className={'col'}>
                                                <Box backgroundColor='#e9e9e9' color='#3a3c3d' title={Localization.print("Services Performed")}>
                                                    <CheckboxGroup
                                                            section={SERVICES_SECTION.servicesPerformed}
                                                            data={this.props.stepData}
                                                            handleChangeOther={this.handleChangeOther.bind(this)}
                                                            handleSetValue={this.handleSetValue.bind(this)}
                                                            handleSwitchAll={this.handleSwitchAll.bind(this)}
                                                            locale={locale}
                                                            checkAllLegend={Localization.print("Check All")}
                                                    />
                                                </Box>
                                            </Col>

                                            <Col md={3} className={'col'} style={{
                                                paddingLeft: (["xl", "lg", "md"].includes(screenClass) ? 0 : 16),
                                                paddingTop: (["sm", "xs"].includes(screenClass) ? 16 : 0)
                                            }}>
                                                <Box backgroundColor='#e9e9e9' color='#3a3c3d' title={Localization.print("Regulatory Services")}>
                                                    <CheckboxGroup
                                                            data={this.props.stepData}
                                                            section={SERVICES_SECTION.shopType}
                                                            handleChangeOther={this.handleChangeOther.bind(this)}
                                                            handleSetValue={this.handleSetValue.bind(this)}
                                                            handleSwitchAll={this.handleSwitchAll.bind(this)}
                                                            locale={locale}
                                                            checkAllLegend={Localization.print("Check All")}
                                                    />
                                                </Box>
                                            </Col>

                                            <Col md={3} className={'col'} style={{
                                                paddingLeft: (["xl", "lg", "md"].includes(screenClass) ? 0 : 16),
                                                paddingTop: (["sm", "xs"].includes(screenClass) ? 16 : 0)
                                            }}>
                                                <Box backgroundColor='#e9e9e9' color='#3a3c3d' title={Localization.print("Vehicles Serviced")}>
                                                    <CheckboxGroup
                                                            section={SERVICES_SECTION.vehicleServiced}
                                                            data={this.props.stepData}
                                                            handleChangeOther={this.handleChangeOther.bind(this)}
                                                            handleSetValue={this.handleSetValue.bind(this)}
                                                            handleSwitchAll={this.handleSwitchAll.bind(this)}
                                                            locale={locale}
                                                            checkAllLegend={Localization.print("Check All")}
                                                    />
                                                </Box>
                                            </Col>

                                        </Row>

                                    </Box>
                                </StepWrapper>
                        )}
                    </React.Fragment>
                )}/>
        )
    }
}

const mapStateToProps = state => ({
    locale: state.global.locale,
    stepData: state.steps.stepThree,
    systemError: state.steps.systemError
})

const mapDispatchToProps = dispatch => {
    return {
        setThreeStepValue: data => dispatch(setThreeStepValue(data)),
        setThreeSwitchAll: data => dispatch(setThreeSwitchAll(data)),
        setThreeChangeOther: data => dispatch(setThreeChangeOther(data)),
        setThreeChangeRadiovalue: data => dispatch(setThreeChangeRadiovalue(data)),
        setComponent: name =>dispatch(setComponent(name))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepThree)


import React, {useState} from 'react'
import './date-range.scss';
//import './react-date-picker.scss';
//import DatePicker from "react-date-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class DateRange extends React.PureComponent {
// eslint-disable-next-line
    constructor(props) {
        super(props)
        this.state = {
            startActive: false,
            endActive: false,
            startDate: props.startDate || new Date(),
            endDate: props.endDate || new Date()
        }        
    }

    handleChangeStartDate = date => {
        this.setState({
          startDate: date,          
        }, () => this.props.onStartDateChange(date));
    };

    handleChangeEndDate = date => {
        this.setState({          
          endDate: date
        }, () => this.props.onEndDateChange(date));
    };
   
    
    render() {        
        
        const {error, startLabelText, endLabelText, mandatory, minDate, width, bottomMargin } = this.props
        return (
            <div className={`datecontainer ${(!!error ? 'error' : '')}`} style={{marginBottom: bottomMargin, width: width}}>
                <div className="wrapper">
                    <div className="picker-container">
                        <label className="placeholder">
                            <span>{mandatory ? '*':''}</span>
                            <span>{startLabelText}</span>
                        </label>
                        <DatePicker
                                className="startdate"
                                onChange={this.handleChangeStartDate}
                                selected={this.state.startDate}
                                showPopperArrow={false}
                                minDate={minDate}
                                popperPlacement="bottom-end"
                                popperModifiers={{
                                    flip: {
                                        behavior: ['bottom'] 
                                    },
                                    preventOverflow: {
                                        enabled: false 
                                    },
                                    hide: {
                                        enabled: false 
                                    }
                                }}
                        />
                    </div>

                    <div className="picker-container">
                        <label className="placeholder">
                            <span>{mandatory ? '*':''}</span>
                            <span>{endLabelText}</span>
                        </label>
                        <DatePicker
                                className="enddate"
                                onChange={this.handleChangeEndDate}
                                minDate={this.state.startDate}
                                selected={this.state.endDate}
                                showPopperArrow={false}
                                popperPlacement="bottom-end"
                                popperModifiers={{
                                    flip: {
                                        behavior: ['bottom'] 
                                    },
                                    preventOverflow: {
                                        enabled: false 
                                    },
                                    hide: {
                                        enabled: false 
                                    }
                                }}
                        />
                    </div>
                </div>

                {!!error && <span className="error-message">{error}</span>}
            </div>
        )
    }
}

import React from "react";
import './inputFileButton.scss'
import PropTypes from "prop-types";

export default class InputFileButton extends React.PureComponent {
    render() {
        const {backgroundColor, borderColor, color, height, name, text, width} = this.props

        return (
                <label className={'InputFileButton'} htmlFor={name}
                       style={{backgroundColor, borderColor, color, height, width}}>
                    {text}
                </label>
        )

    }
}

InputFileButton.propTypes = {
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
    ]),
    name: PropTypes.string.isRequired,
    text: PropTypes.string,
    width: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
    ])
}
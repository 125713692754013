import React from 'react'
import PropTypes from 'prop-types'
import {GoogleMap, withGoogleMap, Marker, InfoWindow} from 'react-google-maps'
import logo from '../../../../assets/Marker@3x.png'
import selectedPin from '../../../../assets/pin-selected@3x.png'
import './gmap.scss'
import colors from "../../../../utilities/colors"

class MapObject extends React.Component {
    constructor(props) {
        super(props)

        const defaultPos = {lat: 35.149532, lng: -90.048981,}

        this.state = {
            lat: defaultPos.lat,
            lng: defaultPos.lng,
            markers: props.markers || [],
            selectedMarker: null,
            center: defaultPos,
            zoom: 17
        }
    }

    static getDerivedStateFromProps(props, state) {
        let changed = false
        if ((props.lat !== state.lat || props.lng !== state.lng) && props.lat && props.lng) {
            state.lat = props.lat
            state.lng = props.lng
            changed = true
        }
        if (JSON.stringify(props.markers) !== JSON.stringify(state.markers) && undefined !== props.markers) {
            state.markers = props.markers
            if (state.rctGoogleMap && state.markers.length >= 1) {
                const bounds = new window.google.maps.LatLngBounds()
                state.markers.forEach(marker => {
                    const position = new window.google.maps.LatLng(marker.lat, marker.lng)
                    bounds.extend(position)
                })
                state.rctGoogleMap.fitBounds(bounds)
            } 
            //else if (state.markers.length === 1) {
            //    state.lat = state.markers[0].lat
            //    state.lng = state.markers[0].lng
            //    state.zoom = 10
            //}

            changed = true
        }

        return changed ? state : null
    }

    componentDidMount() {
        if (this.refs.rctGoogleMap) {
            this.setState({rctGoogleMap: this.refs.rctGoogleMap})
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.pan !== this.props.pan && this.props.pan) {
            this.panTo(this.props.pan)
        }
    }

    _getIconSize = () => {
        return window.outerWidth <= 567 ? [23, 30] : [30, 34]
    }

    _getIconSizeDetails = () => {
        return window.outerWidth <= 567 ? [23, 30] : [31, 40]
    }

    _getLabelOrigin = () => {
        return window.outerWidth <= 567 ? {x: 11, y: 13} : {x: 15, y: 15}
    }

    panTo = ({lat, lng}) => {
        const {rctGoogleMap} = this.state
        if (!rctGoogleMap) return
        rctGoogleMap.panTo({lat, lng})
    }

    _handleClick = label => {
        document.querySelector(`#itemResult_shop${label}`).click();
    }

    render() {
        const {lat, lng, markers, selectedMarker, zoom} = this.state
        const {isDetails} = this.props

        return <GoogleMap
                defaultZoom={zoom}
                defaultCenter={{lat, lng}}
                defaultOptions={{fullscreenControl: false}}
                ref={'rctGoogleMap'}>
            {markers.map((marker, index) => (
                    <Marker onClick={() => this.setState({selectedMarker: marker})}
                            key={index}
                            icon={{
                                url: (isDetails) ? selectedPin : logo,
                                scaledSize: (isDetails)
                                        ? new window.google.maps.Size(this._getIconSizeDetails()[0], this._getIconSizeDetails()[1])
                                        : new window.google.maps.Size(this._getIconSize()[0], this._getIconSize()[1]),
                                labelOrigin: this._getLabelOrigin()
                            }}
                            label={isDetails ? null : {
                                color: 'white',
                                fontFamily: 'AutoZoneCond-Medium',
                                fontSize: '14px',
                                text: marker.label
                            }}
                            position={{lat: marker.lat, lng: marker.lng}}
                            zIndex={100 + index}
                    />
            ))}

            {selectedMarker && (
                    <InfoWindow position={{lat: selectedMarker.lat, lng: selectedMarker.lng}}
                                onCloseClick={() => this.setState({selectedMarker: null})}>
                    {isDetails
                    ? <span>
                        {selectedMarker.name}
                    </span>
                    : <span 
                        onClick={this._handleClick.bind(this,selectedMarker.label)} 
                        style={{
                            fontSize:'14px',
                            textDecoration:'underline',
                            textTransform:'uppercase',
                            color:colors.autozoneOrangeColor,
                            cursor:'pointer'
                        }}
                    >
                        {selectedMarker.name}
                    </span>
                    }               
                    </InfoWindow>
            )}

        </GoogleMap>
    }
}

const WrappedMap = withGoogleMap(MapObject)

class Map extends React.Component {
    render() {
        return <div className="map">
            <WrappedMap
                    loadingElement={<div style={{height: `100%`}}/>}
                    containerElement={<div style={{height: `100%`}}/>}
                    mapElement={<div id="gmap" style={{height: `100%`}}/>}
                    {...this.props}
            />
        </div>
    }
}

Map.defaultProps = {
    isDetails: false
}

Map.propTypes = {
    lat: PropTypes.number,
    lng: PropTypes.number,
    markers: PropTypes.array,
    isDetails: PropTypes.bool
}

export default Map

import * as shops from '../actions/shops'

const defaultState = {
    action: null,
    currentCoupon: null,
    currentShop: null,
    error: null,
    filters: [],
    history: [],
    loading: false,
    location: null,
    options: null,
    place: null,
    profiles: []
}

export default function (state = defaultState, action) {
    let index

    switch (action.type) {
        case shops.GET_SHOP_MEDIA_ERROR:
        case shops.SEARCH_SHOPS_ERROR:
            return {
                ...state,
                error: action.payload,
                rows:0,
                loading: false
            }

        case shops.GET_SHOP_MEDIA_REQUEST:
        case shops.SEARCH_SHOPS_REQUEST:
            return {
                ...state,
                error: null,
                loading: true
            }

        case shops.CLEAR_SHOPS:
            return {
                ...state,
                error: null,
                rows: 0,
                profiles: []
            }

        case shops.GET_SHOP_MEDIA_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                currentShop: {
                    ...state.currentShop,
                    media: action.payload
                }
            }

        case shops.REMOVE_ALL_FILTERS:
            return {
                ...state,
                filters: []
            }

        case shops.REMOVE_FILTER:
            index = state.filters.findIndex(item => item.value === action.payload.value)
            return {
                ...state,
                filters: (index !== -1) ? [
                        ...state.filters.slice(0, index),
                        ...state.filters.slice(index + 1)
                ] : state.filters
            }

        case shops.SEARCH_SHOPS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                ...action.payload
            }

        case shops.SET_COUPON:
            return {
                ...state,
                currentCoupon: action.payload
            }

        case shops.SET_FILTER:
            index = state.filters.findIndex(item => item.value === action.payload.value)
            return {
                ...state,
                filters: (index === -1) ? [
                        ...state.filters,
                        action.payload
                ] : [
                        ...state.filters.slice(0, index),
                        ...state.filters.slice(index + 1)
                ]
            }

        case shops.SET_LOCATION:
            return {
                ...state,
                location: action.payload
            }

        case shops.SET_OPTIONS:
            return {
                ...state,
                options: action.payload
            }

        case shops.SET_PLACE:
            return {
                ...state,
                place: action.payload
            }

        case shops.SET_SHOP:
            return {
                ...state,
                currentShop: action.payload
            }

        case shops.SET_SHOP_ACTION:
            return {
                ...state,
                action: action.payload
            }

        default:
            return state
    }
}

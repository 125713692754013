import React from 'react';
import loadingAnimation from '../../../assets/loading.gif';

const Spinner = () => {
  return (
    <div>
      <img src={loadingAnimation} alt="loading spinner" />
    </div>
  );
};

export default Spinner;

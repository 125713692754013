import React from "react";
import PropTypes from "prop-types";
import './modalHeader.scss'
import Localization from "../../../utilities/localization";

const ModalHeader = ({handler, title}) => {
    return (
            <div className="ModalHeader">
                <span className="title">{title}</span>
                <span className="close" onClick={handler}>{Localization.print("CLOSE")} X</span>
            </div>
    )
}

ModalHeader.propTypes = {
    handler: PropTypes.func.isRequired,
    title: PropTypes.string
}

export default ModalHeader

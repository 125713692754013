import React from 'react'
import {connect} from 'react-redux'
import Checkbox from "../../../suite/checkBox";
import './filtersModal.scss'
import {removeAllFilters, removeFilter, setFilter} from "../../../../redux/actions/shops";
import FiltersList from "../filtersList";
import CloseButton from "../../../suite/closeButton";
import Localization from "../../../../utilities/localization";

function FilterOptionsList ({optionsArr, itemCallback, title}) {
    return (
            <div className="options-list">
                <h3 className="list-title">{title}</h3>
                <ul className="options">
                    {optionsArr.map((item, i) => (
                            <li className="option-item" key={i}>
                                <Checkbox isBig item={item} handler={itemCallback} />
                            </li>
                    ))}
                </ul>
            </div>
    )
}

function FiltersModal (props) {
    const {
        global: {locale},
        shops: {filters},
        steps: {
            stepOne: {language},
            stepTwo: {certifications},
            stepThree: {servicesPerformed, shopType: regulatoryServices, vehicleServiced}
        }
    } = props
    const selectedFilters = filters.map(item => item.value)

    function handleOptionSelected (item) {
        props.setFilter(item)
    }

    function handleRemoveAll () {
        props.removeAllFilters()
    }

    function handleRemoveItem (item) {
        props.removeFilter(item)
    }

    function mapSelected (item) {
        return {
            ...item,
            text: locale === 'es' ? item.es : item.text,
            checked: selectedFilters.includes(item.value)
        }
    }

    function filterByLanguage (item) {
        return (locale === 'es')
                ? (undefined !== item.es && null !== item.es)
                : (undefined !== item.text && null !== item.text)
    }

    function sort (a, b) {
        const valueA = a.text.toLowerCase()
        const valueB = b.text.toLowerCase()
        if (valueA < valueB) return -1
        if (valueA > valueB) return 1
        return 0
    }

    return (
            <div className="FiltersModal">
                <div className="modal-header">
                    <CloseButton onClick={props.onClose} white/>
                </div>
                <div className="content-header">
                    <section>
                        <h2 className="modal-title">
                            <strong>{Localization.print("Filter")}</strong> {Localization.print("Results")}
                            <div className="orange-line" />
                        </h2>
                        <button className="modal-cta" onClick={props.onConfirm}>{Localization.print("Apply Filters")}</button>
                    </section>
                    {filters.length > 0 && (
                            <section>
                                <FiltersList filters={filters} onItemRemove={handleRemoveItem} />

                                <button className="clear-button" onClick={handleRemoveAll}>
                                    <CloseButton disabled />
                                    <label>{Localization.print("Clear Filters")}</label>
                                </button>
                            </section>
                    )}
                </div>
                <div className="modal-content">
                    {locale !== 'es' && (
                            <React.Fragment>
                                <FilterOptionsList
                                        itemCallback={handleOptionSelected}
                                        optionsArr={[{
                                            checked: false,
                                            text: 'Nationwide Repair Warranty',
                                            value: 'NATREPWARR',
                                            section: "nationwide"
                                        }].map(mapSelected)}
                                        title={"Nationwide Repair Warranty"}
                                />
                                <hr/>
                            </React.Fragment>
                    )}
                    <FilterOptionsList
                            itemCallback={handleOptionSelected}
                            optionsArr={certifications.items.filter(filterByLanguage).map(mapSelected).sort(sort)}
                            title={Localization.print("Shop Certification & Affiliation")}
                    />
                    <hr/>
                    <FilterOptionsList
                            itemCallback={handleOptionSelected}
                            optionsArr={servicesPerformed.items.filter(filterByLanguage).map(mapSelected).sort(sort)}
                            title={Localization.print("Services Performed")}
                    />
                    <hr/>
                    <FilterOptionsList
                            itemCallback={handleOptionSelected}
                            optionsArr={regulatoryServices.items.filter(filterByLanguage).map(mapSelected).sort(sort)}
                            title={Localization.print("Regulatory Services")}
                    />
                    <hr/>
                    <FilterOptionsList
                            itemCallback={handleOptionSelected}
                            optionsArr={vehicleServiced.items.filter(filterByLanguage).map(mapSelected).sort(sort)}
                            title={Localization.print("Vehicles Serviced")}
                    />
                    <hr/>
                    <FilterOptionsList
                            itemCallback={handleOptionSelected}
                            optionsArr={language.items.filter(filterByLanguage).map(mapSelected).sort(sort)}
                            title={Localization.print("Language Spoken")}
                    />
                </div>
            </div>
    )
}

export default connect(
        ({global, shops, steps}) => ({global, shops, steps}),
        {removeAllFilters, removeFilter, setFilter}
)(FiltersModal)

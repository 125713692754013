import * as token from '../actions/token'
import * as profile from '../actions/profile'

export default function (state = {}, action) {
    switch (action.type) {
        case profile.CHECK_ELIGIBILITY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                eligibilityRequestLoaded: true
            }
        case profile.GET_PROFILE_INFO_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                profileInfoLoaded: true
            }
        case profile.GET_PROFILE_STATUS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                profileStatusError: true
            }
        case profile.UPDATE_PROFILE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case profile.CHECK_ELIGIBILITY_REQUEST:
        case profile.GET_PROFILE_INFO_REQUEST:
        case profile.GET_PROFILE_STATUS_REQUEST:
        case profile.UPDATE_PROFILE_REQUEST:
            return {
                ...state,
                error: null,
                loading: true
            }

        case profile.CHECK_ELIGIBILITY_SUCCESS:
            let copy = action.payload.requirements
            return {
                ...state,
                loading: false,
                eligibleValue: eligibleFunction(action.payload.requirements),
                user: {
                    ...(state.user || {}),
                    eligible: action.payload.eligible
                },
                eligibilityRequestLoaded: true
            }

        case profile.GET_PROFILE_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                profileInfoLoaded: true
            }

        case profile.GET_PROFILE_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                user: {
                    ...(state.user || {}),
                    currentStatus: action.payload || null
                },
                profileStatusLoaded: true
            }

        case token.GET_TOKEN_INFO_SUCCESS:
            return {
                ...state,
                user: action.payload.claims
            }

        case profile.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false
            }

        default:
            return state
    }

    function eligibleFunction(copy)
    {
        //console.log('>>>>>>>> Text'+ copy[0].eligibleRequirementType);
        let value = '0.0'
        if(copy.length > 0)
        {
            if(copy[0].eligibleRequirementType === 'Average Weekly Sales')
            {
                value = copy[0].eligibleValue
            }
        }
        return value;
    }
}
import React from 'react'

export default class SuccessPage extends React.Component {
    constructor (props) {
        super(props)
        // const dataUrl = window.location.hash.replace('#/', '')
        // const data = qs.parse(dataUrl)
        // if (data.access_token) {
        //     const previousPath = sessionStorage.getItem('previousPath') || 'StepOne'
        //     sessionStorage.setItem('token', JSON.stringify(data))
        //     window.location.replace(`/#/${previousPath}`)
        // }
        const {match: {params: {params}}} = props
        const [access_token, ...rest] = params.split('&')
        sessionStorage.setItem('token', JSON.stringify({access_token}))
        props.history.replace('/')
    }

    render () {
        return (
                <p>Coming Soon...</p>
        )
    }
}

import React from 'react'
import PropTypes from 'prop-types'
import './userprof.scss'
import Localization from "../../../utilities/localization";

export default class UserProfileComp extends React.PureComponent {
    handleClick = () => {
        this.props.onChange(this.props.active)
    }
   
    render() {
        const {locale} = this.props
        const url = 'https://www.autozone.com' + (locale === 'es' ? '.mx' : '')

        return (
                <div className="toggle-container">
                <div style={{display: 'flex', height: 28}}>
                        <button
                        className={'toggle-item first' + (this.props.active ? ' active' : '')}
                        onClick={ this.handleClick }>{Localization.print("ACTIVATE")}</button>

                        <button
                        className={'toggle-item second' + (!this.props.active ? ' active' : '')}
                        onClick={ this.handleClick }>{Localization.print("DEACTIVATE")}</button>
                    </div>
                    <span className={'toggle-legend'}>{Localization.print("Your shop profile on")} <a href={url} target="_blank" rel="noopener noreferrer">{Localization.print("AutoZone.com")}</a></span>
                </div>
        )
    }
}

UserProfileComp.defaultProps = {
    active: true,
    onChange: () => {}
}

UserProfileComp.propTypes = {
    active: PropTypes.bool,
    onChange: PropTypes.func
}

import React from 'react'
import PropTypes from 'prop-types'
import './inputFile.scss'
import colors from "../../../utilities/colors";
import Localization from "../../../utilities/localization";

export default class InputFile extends React.Component {
    reader = null

    constructor (props) {
        super(props)
        this.state = {
            active: false,
            src: props.value
        }
        this.inputFile = React.createRef()
        this.reader = new FileReader()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setState({src: this.props.value})
        }
    }

    getFileObject = () => this.inputFile.files[0]

    getFileString = () => this.state.src

    onDragEnter = (e) => this.setState({active: true})

    onDragLeave = (e) => this.setState({active: false})

    onDragOver = (e) => e.preventDefault()

    onDrop = (e) => {
        e.preventDefault()
        this.setState({active: false})
        this.onFileChange(e, e.dataTransfer.files[0])
    }

    onFileChange = (e, _file) => {
        const file = _file || e.target.files[0]
        const pattern = /image-*/
        const allowedExtensions = ['jpg','jpeg','png']
        const extension = file.name.split('.').slice(-1)[0]
        const megabyte = 1024 * 1024
        const maxFileSize = 5 * megabyte

        if (!file) return

        // Validate file format
        if (!file.type.match(pattern)) {
            alert('Invalid format')
            return
        }

        // Validate file extension
        if (!allowedExtensions.includes(extension.toLowerCase())) {
            alert('Invalid File Format')
            return
        }

        // Validate file size
        if (file.size > maxFileSize) {
            const message = `The file size(${Math.round((file.size / megabyte))}Mb) exceeds maximum size allowed ${Math.round(maxFileSize / megabyte)}Mb`
            alert(message)
            return
        }

        this.reader.onload = this.onImageLoaded
        this.reader.readAsDataURL(file)
    }

    onImageLoaded = (e) => {
        const {onChange} = this.props
        this.setState({src: this.reader.result}, () => {
            onChange && onChange(this.reader.result)
            this.inputFile.current.value = ""
        })
    }

    render () {
        const {className, disabled, error, index, mandatory, name, onPreview, title, width} = this.props
        const {active, src} = this.state

        return (
                <div className={`inputFile ${className || ''} ${error ? 'error' : ''}`}>

                    {!error && (
                            <div className={'label'} style={{backgroundColor: disabled ? '#e9e9e9' : ''}}>
                                &nbsp;
                                <span className={'label-text'}>{mandatory ? ' * ' : ''}</span>
                                <span className={'label-text'}>{title}</span>
                                &nbsp;
                            </div>
                    )}

                    {disabled && <div className={'input'} style={{width, backgroundColor: '#e9e9e9'}} />}
                    {!disabled && (
                            <div
                                    className={'input'}
                                    onDragEnter={this.onDragEnter}
                                    onDragLeave={this.onDragLeave}
                                    onDragOver={this.onDragOver}
                                    onDrop={this.onDrop}
                                    placeholder={title}
                                    style={{width, borderColor: (active ? 'green' : colors.grayColor)}}
                                    tabIndex={index}
                            >
                                {!src && Localization.print("No Image Selected")}
                                {!!src && (<img onClick={onPreview} src={src} alt="shop" />)}
                                <input accept={'image/*'} id={name} name={name} onChange={this.onFileChange} ref={this.inputFile} type={'file'}/>
                            </div>
                    )}
                    {!!error && <span className="error-message">{error}</span>}
                </div>
        )
    }
}

InputFile.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    index: PropTypes.number,
    mandatory: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onPreview: PropTypes.func,
    title: PropTypes.string,
    value: PropTypes.string
}

import React from 'react'
import ButtonAutoZone from "../../../suite/button-autozone"
import '../../StepWrapper/alertBox/alert.scss'
import Localization from "../../../../utilities/localization";

const SaveProfileAlert = (props) => (
        <div className="alert">

            <div className="header">
                <span className="title">{Localization.print("SAVE PROFILE")}</span>

            </div>

            <div className="content">
                <p>{Localization.print("We were unable to save all your profile information. We have captured as much information as possible, please try again later or call 866-853-6459 for assistance")}.</p>
            </div>

            <div className="footer">            
                <ButtonAutoZone
                        backgroundColor={'#f37f00'}
                        borderColor={'transparent'}
                        color={'#fff'}
                        handler={props.onOk}
                        height={38}
                        text={Localization.print("OK")}
                        width={136}
                />
            </div>

        </div>
)

export default SaveProfileAlert

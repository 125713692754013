import React from 'react'
import StepWrapper from '../../body/StepWrapper'
import {Col, Row, ScreenClassRender} from 'react-grid-system'
import BodyHeader from '../body-header'
import './stepTwo.scss'
import Box from '../../suite/box'
import CheckboxGroup from '../../suite/checkBoxGroup'
import {connect} from 'react-redux'
import {setTwoStepValue, setTwoSwitchAll, setTwoChangeOther} from '../../../redux/actions'
import {systemErrors} from "../../../utilities/constants";
import ErrorMessage from "../../suite/error-message";
import {isIframe, scrollIframeToViewPort} from "../../../utilities/functions";
import Localization from "../../../utilities/localization";
import {setComponent} from "../../../redux/actions/global";

class StepTwo extends React.Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (isIframe()) {
            scrollIframeToViewPort('.Stepper')
        } else {
            window.scrollTo(0,0)
        }
        let name = 'Registration: Shop Certification and Hours';
        this.props.setComponent(name);
    }

    handleSwitchAll(data) {
        this.props.setTwoSwitchAll(data)
    }

    handleSetValue(data) {
        this.props.setTwoStepValue(data)
    }

    handleChangeOther(data) {
        this.props.setTwoChangeOther(data)
    }

    render() {
        const {locale, systemError} = this.props
        const error = systemErrors.find(item => item.key === systemError)

        return (
                <ScreenClassRender render={screenClass => (
                        <React.Fragment>
                            <div className="body-header">
                                <BodyHeader/>
                            </div>
                            {!!systemError && (<ErrorMessage messageText={error.message} />)}
                            {!systemError && (
                                    <StepWrapper screenClass={screenClass}>
                                        <Box width='100%'
                                             backgroundColor='#6c6f70'
                                             color='#ffffff'
                                             title={Localization.print("shop certification and affiliation")}
                                             hint={`(${Localization.print("Check all that apply")})`}>
                                            <Row className={'boxes-horizontal stepTwo'}>
                                                <Col md={4} className={'col'}>
                                                    <Box backgroundColor='#e9e9e9' color='#3a3c3d'
                                                         title={`${Localization.print("Certification")} / ${Localization.print("Affiliation")}`}>
                                                        <CheckboxGroup
                                                                section="certifications"
                                                                data={this.props.stepData}
                                                                handleChangeOther={this.handleChangeOther.bind(this)}
                                                                handleSetValue={this.handleSetValue.bind(this)}
                                                                handleSwitchAll={this.handleSwitchAll.bind(this)}
                                                                locale={locale}
                                                                checkAllLegend={(locale === 'es') ? 'Seleccionar Todas' : 'Check All'}
                                                        />
                                                    </Box>
                                                </Col>
                                                {locale !== 'es' && (
                                                        <Col md={4} className={'col'} style={{
                                                            paddingLeft: (["xl", "lg", "md"].includes(screenClass) ? 0 : 16),
                                                            paddingTop: (["sm", "xs"].includes(screenClass) ? 16 : 0)
                                                        }}>
                                                            <Box backgroundColor='#e9e9e9' color='#3a3c3d' title='Affiliation'>
                                                                <CheckboxGroup
                                                                        section="affiliation"
                                                                        data={this.props.stepData}
                                                                        handleChangeOther={this.handleChangeOther.bind(this)}
                                                                        handleSetValue={this.handleSetValue.bind(this)}
                                                                        handleSwitchAll={this.handleSwitchAll.bind(this)}
                                                                />
                                                            </Box>
                                                        </Col>
                                                )}
                                                <Col md={4} className={'col'} style={{
                                                    paddingLeft: (["xl", "lg", "md"].includes(screenClass) ? 0 : 16),
                                                    paddingTop: (["sm", "xs"].includes(screenClass) ? 16 : 0)
                                                }}>
                                                    <Box backgroundColor='#e9e9e9' color='#3a3c3d' title={Localization.print("Shop Amenities")}>
                                                        <CheckboxGroup
                                                                section="amenities"
                                                                data={this.props.stepData}
                                                                handleChangeOther={this.handleChangeOther.bind(this)}
                                                                handleSetValue={this.handleSetValue.bind(this)}
                                                                handleSwitchAll={this.handleSwitchAll.bind(this)}
                                                                locale={locale}
                                                                checkAllLegend={(locale === 'es' ? 'Seleccionar Todos' : 'Check All')}
                                                        />
                                                    </Box>
                                                </Col>
                                            </Row>
                                        </Box>
                                    </StepWrapper>
                            )}
                        </React.Fragment>
                )}/>
        )
    }
}

const mapStateToProps = state => ({
    locale: state.global.locale,
    stepData: state.steps.stepTwo,
    systemError: state.steps.systemError
})

const mapDispatchToProps = dispatch => {
    return {
        setTwoStepValue: data => dispatch(setTwoStepValue(data)),
        setTwoSwitchAll: data => dispatch(setTwoSwitchAll(data)),
        setTwoChangeOther: data => dispatch(setTwoChangeOther(data)),
        setComponent: name =>dispatch(setComponent(name))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo)

import React from 'react'
import {Row, Col, ScreenClassRender} from 'react-grid-system';
import './editProfile.scss';
import {connect} from 'react-redux';
import Input from "../../../suite/input";
import DropDown from "../../../suite/dropDown";
import ButtonAutoZone from "../../../suite/button-autozone";
import colors from "../../../../utilities/colors";
import SimpleList from "../../../suite/dropDown/simpleList";
import {shopTypes, states, validationTypes} from "../../../../utilities/constants";
import {setOneClearError, setOneError, setProfileProperty} from "../../../../redux/actions";
import InputFile from "../../../suite/inputFile";
import InputFileButton from "../../../suite/inputFile/button";
import Portal from "../../../portal";
import ImageEditor from "./ImageEditor";
import ImageModal from "../InfoProfile/ImageModal";
import {isValid, 
    limitToLength, 
    limitToWordsAndLength, 
    maskPhone, 
    maskZipCode,
    getStateAbbreviationForDisplay
} from "../../../../utilities/functions";
import Localization from "../../../../utilities/localization";

class EditProfile extends React.Component {

    constructor (props) {
        super(props)

        this.state = {
            canEditImage: props.data.shopPicture.length > 0,
            showImageEditor: false,
            showImagePreview: false
        }

        this.shopTypeDropDown = React.createRef()
        this.stateDropDown = React.createRef()
    }
    
    /**
     * Handles all input fields validations and set errors
     * on redux object when necessary
     * @param input
     */
    handleInputValidation = input => {
        const {locale, setOneError, setOneClearError} = this.props
        let validInput = true
        let errorMessage, name, title, type, value

        try {
            name = input.getAttribute('name')
            title = input.getAttribute('title') || 'Input'
            type = input.getAttribute('type')
            value = input.value
            errorMessage = locale === 'es'
                    ? `Se requiere un${name === 'city' ? 'a' : ''} ${name === 'fax' ? 'Número de ' : ''}${title} valid${name === 'city' ? 'a' : 'o'}`
                    : `Valid ${title}${name === 'fax' ? ' Number' : ''} is required`
        } catch (e) {
            console.warn(e)
        }


        if (value.length === 0) {
            setOneClearError(false, name)
            return
        }

        if (type === 'text') {
            if (name === 'shopName' || name === 'shopAddress') validInput = !/[\.,0-9]{10,}/.test(value)
            else {
                validInput = (name === 'shopContactName' || name === 'city')
                        ? !/[\.,0-9]{5,}/.test(value) && isValid(validationTypes.text, value) && isValid(validationTypes.textOnly, value)
                        : !/[\.,0-9]{5,}/.test(value) && isValid(validationTypes.text, value)
            }
        } else if (type === 'email') {
            validInput = isValid(validationTypes.email, value)
        } else if (type === 'url') {
            validInput = isValid(validationTypes.url, value)
        } else if ((type === 'number' || type === 'tel') && name === 'zipCode') {
            validInput = isValid(validationTypes.number, value) && value.length === 5
        } else if (type === 'tel') {
            const _cleanPhone = value.replace(/-/g, '')
            validInput = isValid(validationTypes.number, _cleanPhone) && isValid(validationTypes.text, _cleanPhone) && _cleanPhone.length === 10
        }

        if (validInput) setOneClearError(false, name)
        else setOneError(name, errorMessage)
    }

    /**
     * Called every time the value of input file changes.
     * Sets the value for redux object and activates the
     * button that triggers the image editing modal.
     * @param shopPicture
     */
    handlePictureChanged = shopPicture => {
        const {setProfileProperty} = this.props
        setProfileProperty('shopPicture', shopPicture)
        setProfileProperty('croppedPicture', '')
        setProfileProperty('croppedData', null)
        this.setState({canEditImage: (shopPicture.length > 0)})
    }

    /**
     * Passed as callback when confirmation button in
     * the image editing modal is pressed.
     * Sets the new value in the redux object and closes
     * the modal.
     * @param croppedPicture: Object
     * @param data: Object
     */
    handlePictureCropped = (croppedPicture, data) => {
        this.setState({showImageEditor: false})
        this.props.setProfileProperty('croppedPicture', croppedPicture)
        this.props.setProfileProperty('croppedData', data)
    }

    /**
     * Passed as callback whe "delete" button in image editing
     * modal is pressed.
     * Removes the value from redux object and closes modal.
     */
    handlePictureDeleted = () => {
        const {setProfileProperty} = this.props
        setProfileProperty('shopPicture', '')
        setProfileProperty('croppedPicture', '')
        setProfileProperty('croppedData', null)
        this.setState({
            canEditImage: false,
            showImageEditor: false
        })
    }

    /**
     * Handle changes for Shop Type DropDown
     * @param id
     */
    handleShopTypeSelected = id => {
        const {setProfileProperty} = this.props
        setProfileProperty('shopType', id)
        this.shopTypeDropDown.current.close()
    }

    /**
     * Handle Changes for States DropDown
     * @param id
     */
    handleStateSelected = (id, close = true) => {
        const {setProfileProperty, locale} = this.props
        setProfileProperty('state', id);
        const stateAbbreviation = getStateAbbreviationForDisplay(id, locale)
        setProfileProperty('stateCodeForDisplay', stateAbbreviation);
        if (close) this.stateDropDown.current.close()
    }

    render() {
        const {locale, setProfileProperty, data: {shopType, ...data}, errors, requiredFields} = this.props
        const {canEditImage, showImageEditor, showImagePreview} = this.state

        return (
                <ScreenClassRender render={screenClass => (
                        <div className="editProfile">
                            <Row gutterWidth={16} className={'bottom-space'}>
                                <Col sm={12} md={4} className={'col'}>
                                    <Input
                                            disabled
                                            mandatory={requiredFields.includes('accountNumber')}
                                            title={Localization.print("Account Number #")}
                                            value={data.customerID}
                                    />

                                </Col>
                                <Col sm={12} md={4} className={'col'}>
                                    <Input
                                            error={errors['shopName']}
                                            inputType={"text"}
                                            mandatory={requiredFields.includes('shopName')}
                                            name={'shopName'}
                                            onBlur={this.handleInputValidation}
                                            onChange={shopName => setProfileProperty('shopName', limitToLength(shopName, 35))}
                                            title={Localization.print("Shop Name")}
                                            value={data.shopName}
                                    />

                                </Col>
                                <Col sm={12} md={4} className={'col'}>
                                    <Input
                                            error={errors['shopContactName']}
                                            inputType={"text"}
                                            mandatory={requiredFields.includes('shopContactName')}
                                            name={'shopContactName'}
                                            onBlur={this.handleInputValidation}
                                            onChange={shopContactName => setProfileProperty('shopContactName', limitToWordsAndLength(shopContactName, 2, 30))}
                                            title={locale === 'es' ? 'Nombre del contacto del Taller' : 'Shop Contact Name'}
                                            value={data.shopContactName}
                                    />

                                </Col>
                            </Row>
                            <Row className="bottom-space" gutterWidth={16}>
                                <Col sm={12} md={4} className={'col'}>
                                    <Input
                                            error={errors['shopAddress']}
                                            inputType={"autocomplete"}
                                            mandatory={requiredFields.includes('shopAddress')}
                                            name={'shopAddress'}
                                            onBlur={this.handleInputValidation}
                                            onChange={shopAddress => setProfileProperty('shopAddress', shopAddress)}
                                            title={Localization.print("Shop Address")}
                                            value={data.shopAddress}
                                    />
                                </Col>
                                <Col sm={12} md={4} className={'col'}>

                                    <Row gutterWidth={16}>
                                        <Col xs={7} md={6} className={'col'}>
                                            <Input
                                                    error={errors['city']}
                                                    inputType={"text"}
                                                    mandatory={requiredFields.includes('city')}
                                                    name={'city'}
                                                    onBlur={this.handleInputValidation}
                                                    onChange={city => setProfileProperty('city', limitToLength(city, 30))}
                                                    title={Localization.print("City")}
                                                    value={data.city}
                                            />


                                        </Col>
                                        <Col xs={5} md={6} className={'col state'}>
                                            <DropDown
                                                    error={errors['state']}
                                                    labelText={Localization.print("State")}
                                                    ref={this.stateDropDown}
                                                    selected={data.stateCodeForDisplay}
                                                    handler={this.handleStateSelected}
                                                    items={states(locale).map(item => ({ ...item, selected: (item.id === data.state) }))}
                                            >
                                                <SimpleList
                                                        handler={this.handleStateSelected}
                                                        items={states(locale).map(item => ({ ...item, selected: (item.id === data.state) }))}
                                                />
                                            </DropDown>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={12} md={4} className={'col'}>
                                    <Row  gutterWidth={16}>
                                        <Col sm={12} md={6} className={'col'}>
                                            <Input
                                                    error={errors['zipCode']}
                                                    inputType={'tel'}
                                                    mandatory={requiredFields.includes('zipCode')}
                                                    maxLength={5}
                                                    name={'zipCode'}
                                                    onBlur={this.handleInputValidation}
                                                    onChange={zipCode => setProfileProperty('zipCode', maskZipCode(zipCode))}
                                                    title={Localization.print("Zip Code")}
                                                    value={data.zipCode}
                                            />

                                        </Col>
                                        <Col sm={12} md={6} className={'col'}>

                                            <Input
                                                    error={errors['fax']}
                                                    inputType={'tel'}
                                                    mandatory={requiredFields.includes('fax')}
                                                    name={'fax'}
                                                    onBlur={this.handleInputValidation}
                                                    onChange={fax => setProfileProperty('fax', maskPhone(fax))}
                                                    title={'Fax'}
                                                    value={data.fax}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="bottom-space" gutterWidth={16} >
                                <Col sm={12} md={4} className={'col'}>
                                    <Input
                                            error={errors['shopContactEmail']}
                                            inputType={'email'}
                                            mandatory={requiredFields.includes('shopContactEmail')}
                                            name={'shopContactEmail'}
                                            onBlur={this.handleInputValidation}
                                            onChange={shopContactEmail => setProfileProperty('shopContactEmail', limitToLength(shopContactEmail, 250))}
                                            title={Localization.print("Shop Contact Email")}
                                            value={data.shopContactEmail}
                                    />
                                </Col>
                                <Col sm={12} md={4} className={'col'}>

                                    <Row  gutterWidth={16}>
                                        <Col sm={12} md={6} className={'col'}>
                                            <Input
                                                    error={errors['shopPhoneNumber']}
                                                    inputType={'tel'}
                                                    mandatory={requiredFields.includes('shopPhoneNumber')}
                                                    name={'shopPhoneNumber'}
                                                    onBlur={this.handleInputValidation}
                                                    onChange={shopPhoneNumber => setProfileProperty('shopPhoneNumber', maskPhone(shopPhoneNumber))}
                                                    title={locale === 'es' ? 'Teléfono del Taller' : 'Shop Phone'}
                                                    value={data.shopPhoneNumber}
                                            />

                                        </Col>

                                        <Col sm={12} md={6} className={'col'}>

                                            <Input
                                                    error={errors['mobilePhoneNumber']}
                                                    inputType={'tel'}
                                                    //mandatory={requiredFields.includes('mobilePhoneNumber')}
                                                    name={'mobilePhoneNumber'}
                                                    onBlur={this.handleInputValidation}
                                                    onChange={mobilePhoneNumber => setProfileProperty('mobilePhoneNumber', maskPhone(mobilePhoneNumber))}
                                                    title={locale === 'es' ? 'Celular del Taller' : 'Mobile Number'}
                                                    value={data.mobilePhoneNumber}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={12} md={4} className={'col'}>
                                    <Input
                                            error={errors['shopWebAddress']}
                                            inputType={'url'}
                                            name={'shopWebAddress'}
                                            onBlur={this.handleInputValidation}
                                            onChange={shopWebAddress => setProfileProperty('shopWebAddress', limitToLength(shopWebAddress, 128))}
                                            title={Localization.print("Shop Web Address")}
                                            value={data.shopWebAddress}
                                    />
                                </Col>
                            </Row>
                            <Row gutterWidth={16}>
                                {/*
                                <Col sm={12} md={4} className={'col'}>
                                    <DropDown
                                            labelText={'Shop Type'}
                                            ref={this.shopTypeDropDown}
                                            selected={_shopType}
                                            handler={this.handleShopTypeSelected}
                                            items={shopTypes.map(item => ({ ...item, selected: (item.id === shopType) }))}
                                    >
                                        <SimpleList
                                                handler={this.handleShopTypeSelected}
                                                items={shopTypes.map(item => ({ ...item, selected: (item.id === shopType) }))}
                                        />
                                    </DropDown>
                                </Col>
                                */}
                                <Col sm={12} md={4} className={'col'}>
                                    <InputFile
                                            error={errors['shopPicture']}
                                            mandatory={requiredFields.includes('shopPicture')}
                                            name={'shopPicture'}
                                            onChange={this.handlePictureChanged}
                                            onPreview={() => this.setState({showImagePreview: true})}
                                            title={Localization.print("Shop Picture")}
                                            value={data.croppedPicture || data.shopPicture}
                                    />
                                    <div />
                                </Col>
                                <Col sm={12} md={4} className={'col'}>
                                    <div
                                            className={`row ${!data.shopPicture ? 'center' : 'end'}`}
                                            style={{minHeight: ["sm", "xs"].includes(screenClass) ? 28 : 38}}>
                                        <InputFileButton
                                                backgroundColor={'white'}
                                                borderColor={colors.autozoneOrangeColor}
                                                color={colors.autozoneOrangeColor}
                                                name={'shopPicture'}
                                                text={Localization.print("BROWSE")}
                                                width={["lg"].includes(screenClass) ? 68 : 84}
                                        />
                                        <ButtonAutoZone
                                                backgroundColor={'white'}
                                                borderColor={colors.autozoneBlackColor}
                                                color={colors.autozoneBlackColor}
                                                opacity={canEditImage ? 1 : 0.3}
                                                handler={() => {
                                                    if (!canEditImage) return
                                                    this.setState(prevState => ({
                                                        showImageEditor: !prevState.showImageEditor
                                                    }))
                                                }}
                                                text={Localization.print("EDIT")}
                                                width={["lg"].includes(screenClass) ? 68 : 84}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            {showImageEditor && (
                                    <Portal>
                                        <ImageEditor
                                                croppedData={data.croppedData || null}
                                                imageSource={data.shopPicture}
                                                onClose={() => this.setState({showImageEditor: false})}
                                                onConfirm={this.handlePictureCropped}
                                                onDelete={this.handlePictureDeleted}
                                        />
                                    </Portal>
                            )}
                            {showImagePreview && (
                                    <Portal>
                                        <ImageModal
                                                onClose={() => this.setState({showImagePreview: false})}
                                                source={data.croppedPicture || data.shopPicture}
                                        />
                                    </Portal>
                            )}
                        </div>
                )}/>
        )

    }

}

const mapStateToProps = state => ({
    data: state.steps.stepOne.profileInfo,
    errors: state.steps.stepOne.errors,
    locale: state.global.locale,
    requiredFields: state.steps.stepOne.requiredFields
})

const mapDispatchToProps = ({setOneClearError, setOneError, setProfileProperty});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile)

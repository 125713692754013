import React from 'react'
import './filtersList.scss'
import CloseButton from "../../../suite/closeButton";

export default function FiltersList ({filters, onItemRemove}) {
    return (
            <ul className="filters-list">
                {filters.map((item, i) => (
                        <li className="filter-item" key={i}>
                            <label className="label">{item.text}</label>
                            <CloseButton onClick={() => onItemRemove(item)} />
                        </li>
                ))}
            </ul>
    )
}
import React from 'react'
import PropTypes from 'prop-types'
import {Col, Row, Visible} from 'react-grid-system'
import './checkboxGroup.scss'
import CheckBox from '../checkBox'
import ButtonAutoZone from "../button-autozone";
import colors from "../../../utilities/colors";


export default class CheckboxGroup extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            otherOpen: false,
            all: false
        }
    }

    componentDidMount() {
        const {data, section} = this.props
        const state = this.state

        if (data[section] && data[section].other && data[section].other !== '')
            state.otherOpen = true

        this.setState({state})
    }

    areAllSelected = () => {
        const {data, locale, section} = this.props
        if (!data[section]) return false

        const property = locale === 'es' ? 'es' : 'text'
        const selected = data[section] ? data[section].items.filter(item => item.checked) : []
        const localizedSelected = selected.filter(el => !!el[property])
        const localizedCollection = data[section].items.filter(el => !!el[property])
        return (localizedCollection.length === localizedSelected.length)
    }

    switchOpen() {
        this.setState(previous => ({otherOpen: !previous.otherOpen}))
    }

    switchAll() {
        //let all = !this.state.all
        let all = !this.areAllSelected();
        this.setState({all})
        let data = {value:all, locale:this.props.locale}
        this.props.handleSwitchAll({section: this.props.section, data})
    }

    changeValue(data) {
        this.props.handleSetValue(data)
    }

    changeOther(event) {
        this.props.handleChangeOther({section: this.props.section, value: event.target.value})
    }

    render() {

        const {checkAllLegend, data, section, other, mobileButtons, onCancel, onConfirm, locale} = this.props
        const {otherOpen} = this.state
        const property = locale === 'es' ? 'es' : 'text'
        let firstHalf = data[section] ? data[section].items.filter(item => (item.half === 1 && !!item[property])) : [];
        let secondHalf = data[section] ? data[section].items.filter(item => (item.half !== 1 && !!item[property])) : [];

        if (locale === 'es') {
            firstHalf = firstHalf.map(item => ({...item, text: item.es}))
            secondHalf = secondHalf.map(item => ({...item, text: item.es}))
        }

        if (firstHalf[0].index) {
            const sortProperty = locale === 'es' ? 'index_es' : 'index'
            firstHalf = firstHalf.sort((a,b) => (a[sortProperty] - b[sortProperty]))
            secondHalf = secondHalf.sort((a,b) => (a[sortProperty] - b[sortProperty]))
        }

        return (
                <div className="checkboxGroup">

                    <CheckBox item={{text: checkAllLegend, checked: this.areAllSelected()}} handler={this.switchAll.bind(this)}/>
                    <div className='divider'/>

                    <div className="contentCheckBox">
                        {firstHalf.length > 0 && (
                                <div className="half">
                                    {firstHalf.map((item, index) => (
                                            <CheckBox item={item} key={index} handler={this.changeValue.bind(this)}/>
                                    ))}

                                    {other &&
                                    <CheckBox item={{text: 'Other', checked: otherOpen}}
                                              handler={this.switchOpen.bind(this)}/>}
                                </div>
                        )}

                        {secondHalf.length > 0 && (
                                <div className="half">
                                    {secondHalf.map((item, index) => (
                                            <CheckBox item={item} key={index} handler={this.changeValue.bind(this)}/>
                                    ))}
                                </div>
                        )}
                    </div>

                    {this.state.otherOpen &&
                    <input value={data[section].other} onChange={(event) => this.changeOther(event)}/>}

                    {mobileButtons && (
                            <Visible xs>
                                <div className="group-footer">
                                    <Row gutterWidth={16}>
                                        <Col xs={6}>
                                            <ButtonAutoZone
                                                    backgroundColor={'#fff'}
                                                    borderColor={colors.autozoneBlackColor}
                                                    color={colors.autozoneBlackColor}
                                                    handler={() => {
                                                        onCancel && onCancel()
                                                    }}
                                                    height={28}
                                                    text={'CANCEL'}
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <ButtonAutoZone
                                                    backgroundColor={'#fff'}
                                                    borderColor={colors.autozoneOrangeColor}
                                                    color={colors.autozoneOrangeColor}
                                                    handler={() => {
                                                        onConfirm && onConfirm()
                                                    }}
                                                    height={28}
                                                    text={'OK'}/>
                                        </Col>
                                    </Row>
                                </div>
                            </Visible>
                    )}
                </div>
        )
    }

}

CheckboxGroup.propTypes = {
    checkAllLegend: PropTypes.string,
    locale: PropTypes.string
}

CheckboxGroup.defaultProps = {
    checkAllLegend: 'Check All',
    locale: 'us'
}
